import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
  externalLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover, &:focus, &:visited': {
        color: theme.palette.text.primary,
    },
  },

  link: {
    textDecoration: 'none',
    '&[href="/admin/help"]': {
      marginTop: '50px !important',
    },

    '&[href="/getting-started"]': {
      marginTop: '50px !important',
    },

    '&:hover, &:focus': {
      backgroundColor:'#D8D8D880',
      '& > .MuiSvgIcon-root': {
        display: 'inline-block',
      },
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'transparent',
    },
    cursor: 'pointer',
  },

  linkIcon: {
    marginRight: theme.spacing(1),
    color:
      theme.palette.type === 'dark'
        ? '#616168 !important'
        : theme.palette.text.secondary + '99',
    transition: theme.transitions.create('color'),
    display: 'flex',
    justifyContent: 'center',
  },
 
  linkText: {
    padding: 0,
    color:
      theme.palette.type === 'dark'
        ? '#D6D6D6 !important'
        : theme.palette.text.secondary + 'CC',
    transition: theme.transitions.create(['opacity', 'color']),
    fontSize: 14,
    fontFamily: 'Gotham Rounded !important',
  },
  
  linkTextHidden: {
    opacity: 0,
  },
  

}));
