
import { makeStyles } from '@mui/styles';

const styles = (theme) => ({

    container: {
      marginTop: '64px',
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column !important',
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflow: 'auto',

      [theme.breakpoints.down('sm')]: {
        width: 'calc(100%  - 16px)',
      },
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#FFFFFF',
      width: '100%',
      height: '40px',
    },

    loadMoreBarContent: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '12px',
      maxWidth: '559px',
      width: '100%',
      height: '42px',
      backgroundColor: '#FFFFFF',
      marginBottom: '24px',
      padding: '0px 20px',

      [theme.breakpoints.down('sm')]: {
        padding: '0px 10px',
      },
    },

    cardContent: {
      display: 'flex',
      flexDirection: 'column !important',
      marginTop: '16px',
      width: 'calc(100% - 32px)',
      marginBottom: '24px',

      [theme.breakpoints.down('sm')]: {
        width: 'calc(100%  - 16px)',
      },
    },

    activeStatus: {
      color: '#75BD43',
      fontSize: '12px !important',
    },

    inactiveStatus: {
      color: '#EA5758',
      fontSize: '12px !important',
    },

});

export default styles;
export const useStyles = makeStyles(styles);
