import React from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';

import { Typography } from '../wrappers';
import { useStyles } from './mainTitle.styles';

import {
    Grid,
} from '@mui/material';

function MainTitle (props) {
    const {
        icon,
        label,
        marginRight,
    } = props;

    const classes = useStyles({ marginRight });

    function renderIcon () {
        if (icon) {
            return (<div className={classes.icon}>
                {icon}
            </div>);
        }
        return (<React.Fragment></React.Fragment>);
    }
    return (
        <Grid
            className={classes.base}
        >
            { renderIcon() }
            <Grid className={classes.label}>
                <Typography
                    color='primary'
                    className={classes.labelText}
                >
                         {label}
                </Typography>
            </Grid>
            <div className={classes.spacer}> </div>
        </Grid>
    );  
}

MainTitle.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.any,
    marginRight: PropTypes.any,
};

MainTitle.defaultProps = {
    label: 'Profile',
    icon: null,
    marginRight: '24px',
};

/*
 { icon && (
                
            )}
*/
export default MainTitle;