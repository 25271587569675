import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';

export function addInAvalaibleSchedules ({ subscription, schedule }) {
    let schedules = this.availableSchedules[subscription.id];

    if (isEmpty(schedules)) {
        if (this.availableSchedulesExpanded[subscription.id]) {
            schedules = [];
        } else {
            return;
        }
    }

    this.availableSchedules[subscription.id] = [
        ...schedules,
        schedule,
    ];

    this.observed.availableSchedules = uuidv4();
}
