/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import {
    IconButton,
} from '@mui/material';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Menu as MenuIcon,
//  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';

// import { useTheme } from '@mui/styles';

import {
 //   useLayoutState,
    useLayoutDispatch,
    openSidebar,
} from '../../../context/LayoutContext';

// styles
import useStyles from './header.styles';

function BackMenuButton (props) {
      // global
    // const layoutState = useLayoutState();
    const layoutDispatch = useLayoutDispatch();
    const classes = useStyles();
    // const [isSmall, setSmall] = useState(false);
    // const theme = useTheme();

    useEffect(function () {
        window.addEventListener('resize', handleWindowWidthChange);
        handleWindowWidthChange();

        return function cleanup () {
          window.removeEventListener('resize', handleWindowWidthChange);
        };
    }, []);

    function handleWindowWidthChange () {
       /*
        const windowWidth = window.innerWidth;
        const breakpointWidth = theme.breakpoints.values.md;
        const isSmallScreen = windowWidth < breakpointWidth;
        setSmall(isSmallScreen);
        */
    }

    return (
        <IconButton
            color="inherit"
            onClick={() => openSidebar(layoutDispatch)}
            className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
            )}
      >
        <MenuIcon
          classes={{
            root: classNames(classes.headerIcon, classes.headerIconCollapse),
          }}
        />
      </IconButton>
    );
}

BackMenuButton.propTypes = {
    open: PropTypes.bool,
};

BackMenuButton.default = {
    open: false,
};

export default BackMenuButton;

/*

     return (
        <IconButton
            color="inherit"
            onClick={() => openSidebar(layoutDispatch)}
            className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
            )}
      >
        {(!layoutState.isSidebarOpened && isSmall) ||
        (layoutState.isSidebarOpened && !isSmall)
? (
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        )
: (
          <MenuIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        )}
      </IconButton>
    );

*/