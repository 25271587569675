import { v4 as uuidv4 } from 'uuid';

export function expandSubscriptionCard (subscriptionId) {
    this.subscriptionsExpanded[subscriptionId] = true;
    this.observed.subscriptionsExpanded = uuidv4(); // Clicked manage button
}

export function resetSubscriptionsExpanded () {
    this.subscriptionsExpanded = {};
}
