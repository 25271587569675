import isEmpty from 'lodash/isEmpty'
import dateUtil from '../../../../commons/utils/date';
function selectActiveInactive (data) {
    const active = [];
    const inactive = [];

    const devicesHash = {};
   

    for (let i = 0; i < data.length; i++) {
        const subscription = data[i];
        if (subscription.isActive) {
            active.push(subscription);
        } else {
            inactive.push(subscription);
        }

        if (subscription?.applications && subscription?.applications.length > 0) {
            const appInfo = subscription?.applications[0];
            const key = `${appInfo.device}-${appInfo.os}-${appInfo.application}`;
            if (!devicesHash[key]) {
                devicesHash[key] = {
                    ...appInfo,
                    ...subscription.location,
                    date: dateUtil.toDateString(subscription.createdDate),
                };
            }
        }
    }

    let devices = [];
    
    if (!isEmpty(devicesHash)) {
        devices = Object.values(devicesHash);
    }

    return {
        active,
        inactive,
        devices,
    };
}

export default selectActiveInactive;