
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
} from '@mui/material';

import {
    Typography,
} from '../components/wrappers';


import { useStyles } from './subscriptionItem.styles';

function DeviceInfoField (props) {
    const {
        label,
        value,
    } = props;

    const classes = useStyles({});

    return (
        <Grid className={classes.deviceInfoField}>

            <Typography className={classes.deviceInfoLabel} > 
                {label}
            </Typography>

            <Typography className={classes.deviceInfoValue} > 
                {value}
            </Typography>

        </Grid>
    );
}

function renderReasonDescription (reasons) {
    let reasonCollection = [];
    for(let i = 0; i < reasons.length; i++) {
        const reasonCaps = capitalizeFirstLetter(reasons[i].reason);
        reasonCollection.push(reasonCaps);
    }

    reasonCollection = [...new Set(reasonCollection)]; 
    const description = reasonCollection.join(', ');
    console.log('renderReasonDescription', description);
    return description;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function DeviceInfo (props) {
    const {
        expand,
        marginTop,
        marginBottom,
        showTitle,
        deviceData,
        variant,
    } = props;

    const classes = useStyles({
        expand,
        marginTop,
        marginBottom,
        variant,
    });

    if (!expand) {
        return (<React.Fragment></React.Fragment>);
    }
    if (variant === 'small') {

        return (<Grid  className={classes.deviceInfo} >
             <DeviceInfoField
                label={`${deviceData.device} ${deviceData.os}`}
                value={deviceData.date}
            />
        </Grid>);
    }

    return (<Grid
        className={classes.deviceInfo}
    >
        { showTitle && (
            <Typography className={classes.deviceInfoTitle}>
                Subscription Details
            </Typography>
        )}

        <DeviceInfoField
            label='Device'
            value={deviceData.device}
        />

        <DeviceInfoField
            label='Calendar Type'
            value={deviceData.application}
        />

        <DeviceInfoField
            label='Timezone'
            value={deviceData.timezone}
        />

        <DeviceInfoField
            label={'Connected'}
            value={deviceData.createdDate}
        />

        <DeviceInfoField
            label={'Last Sync'}
            value={`${deviceData.timeFromNow}`}
        />

        {(deviceData?.reasons && !deviceData.isActive) &&
            <DeviceInfoField
                label='Reason'
                // value={deviceData?.reasons[0].reason}
                value={renderReasonDescription(deviceData?.reasons)}
            />}

        {!deviceData.isActive &&
            <DeviceInfoField
                label='Subscription Id'
                value={deviceData.subscriptionId}
            />}

    </Grid>);
}

DeviceInfo.propTypes = {
    marginTop: PropTypes.any,
    marginBottom: PropTypes.any,
    expand: PropTypes.bool,
    showTitle: PropTypes.bool,
    deviceData: PropTypes.any,
    variant: PropTypes.any,
};

DeviceInfo.defaultProps = {
    marginTop: 0,
    marginBottom: 0,
    expand: false,
    deviceData: {},
    showTitle: false,
    variant: '',
};

export default DeviceInfo;