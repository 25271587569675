import React, { useEffect, useState } from 'react';
import Dialog from '../dialog/Dialog';

function useConfirmationDialog (props) {
    const {
        onClose = () => {},
        onConfirm = () => {},
        open: defaultOpen = false,
        title: defaultTitle,
        message: defaultMessage,
        confirmLabel = 'Yes',
        cancelLabel = 'Cancel',
        showCancelButton = true,
        showConfirmButton = true,
        dialogContentRenderer = (null),
        showWaitMessage = false,
        showRefreshMessage = false,
    } = props;

    const [open, setOpen] = useState(defaultOpen);
    const [title, setTitle] = useState(defaultTitle);
    const [message, setMessage] = useState(defaultMessage);

    useEffect(() => {
        setTitle(defaultTitle);
    }, [defaultTitle]);

    useEffect(() => {
        setMessage(defaultMessage);
    }, [defaultMessage]);

    function renderDialog () {
        if (!open) {
            return (<React.Fragment></React.Fragment>);
        }
        return (
            <Dialog
                open={open}
                showCancelButton={showCancelButton}
                showConfirmButton={showConfirmButton}
                cancelLabel={cancelLabel}
                confirmLabel={confirmLabel}

                onConfirm={() => {
                    if (typeof onConfirm === 'function') {
                        onConfirm();
                        setOpen(false);
                    }
                }}

                onCancel={() => {
                    if (typeof onClose === 'function') {
                        onClose();
                    }
                    setOpen(false);
                }}

                closeModal={() => {
                    if (typeof onClose === 'function') {
                        onClose();
                        setOpen(false);
                    }
                }}
                message={title}
                dialogContextText={message}
                shouldRenderDefaultContent={typeof dialogContentRenderer === 'function' ? false : true }
                dialogContentRenderer={dialogContentRenderer}
                showWaitMessage={showWaitMessage}
                showRefreshMessage={showRefreshMessage}
            >
                
            </Dialog>
        );
    }

    function openDialog ({ newTitle = null, newMessage = null, open = true }) {
        if (newTitle) {
            setTitle(newTitle);
        }

        if (newMessage) {
            setMessage(newMessage);
        }
        setOpen(open);
    };

    return {
        renderDialog,
        openDialog,
    };
}

export default useConfirmationDialog;
