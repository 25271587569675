/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";

function useShowDots (props) {

    const {
        subscriptions,
    } = props;

    const [showDots, setShowDots] = useState(true);
    // const [sliderSettings,] = useState({...defaultSliderSettings});
  
    const isLarge = useMediaQuery('(min-width: 1024px)');
    const isSmall = useMediaQuery('(max-width: 360px)');
    const isMediumDown = useMediaQuery('(max-width: 480px)');
    const isMediumUp = useMediaQuery('(min-width: 480px)');
    
    useEffect(() => {
        shouldRenderDots();
    }, [subscriptions, isLarge, isSmall, isMediumDown, isMediumUp ]);

    function shouldRenderDots () {
        if (subscriptions && subscriptions.length <= 0) {
          setShowDots(false);
          return;
        }
    
        if (isSmall &&  subscriptions.length > 1) {
          setShowDots(true);
          return;
        }
    
        if (isMediumDown && subscriptions.length > 2) {
          setShowDots(true);
          return;
        }
     
    
        if (isMediumUp && subscriptions.length > 3) {
          setShowDots(true);
          return;
        }
    
        if (isLarge && subscriptions.length > 3) {
          setShowDots(true);
          return;
        }
        setShowDots(false);
    }

    return {
        shouldRenderDots,
        showDots,
    };
}

export default useShowDots;
