/*
 * this util enables a detach updater to callback dots and message update,
 */
export function startProgressUpdater (options = {}) {
    const {
        store = null, // ussually the store (eg. subscription store)
        messageInterval = 3,
        key = 'progress-updater-key',
        messageUpdateCallback = () => {},
        dotsUpdateCallback = () => {},
        intervalStep = 500,
    } = options;

    // console.log('startLoadCounter() target -> ', target);

    function validateProgressUpdate () {
        window[key].elapsed += intervalStep;
        const base = messageInterval * 1000;
        if ( window[key].elapsed % base === 0) {
            if (typeof messageUpdateCallback === 'function') {
                messageUpdateCallback(store);
            }    
        }
        
        if (typeof dotsUpdateCallback === 'function') {
            dotsUpdateCallback(store);
        }
    }
    
    window[key] = {
        elapsed: 0,
        intervalId: setInterval(validateProgressUpdate, intervalStep),
    };
}

export function stopProgressUpdater (key) {
    window[key].elapsed = 0;
    clearInterval(window[key].intervalId);
}

export function getDotsByCount (count) {
    let dots = ''
    for (let i = 1; i <= count; i++) {
        dots += '.';
    }
    return dots;
}

export const preloadingMessages = [
    'Loading',
    'Please wait',
    'Almost done',
];
