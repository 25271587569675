import { getDomainEndpoints } from '.';
import URLS from './urls';
const baseEndpoint = getDomainEndpoints();

const EndpointSources = {
    remote: () => ({
        oauth: `${getBaseURL()}${URLS.OAUTH}`,
        profile: `${getBaseURL()}${URLS.GET_PROFILE}`,
        subscriptions: `${getBaseURL()}${URLS.GET_SUBSCRIPTIONS}`,
        activeSubscriptions:  `${getBaseURL()}${URLS.GET_ACTIVE_SUBSCRIPTIONS}`,
        inactiveSubscriptions:  `${getBaseURL()}${URLS.GET_INACTIVE_SUBSCRIPTIONS}`,
    }),

    local: () => ({
        oauth: `${baseEndpoint.local}${URLS.OAUTH}`,
        profile: `${baseEndpoint.local}${URLS.GET_PROFILE}`,
        subscriptions: `${baseEndpoint.local}${URLS.GET_SUBSCRIPTIONS}`,
        activeSubscriptions: `${baseEndpoint.local}${URLS.GET_ACTIVE_SUBSCRIPTIONS}`,
        inactiveSubscriptions: `${baseEndpoint.local}${URLS.GET_INACTIVE_SUBSCRIPTIONS}`,
    }),
};

export function getBaseURL() {
    console.log('getBaseURL():', baseEndpoint[window.env], );
    if (baseEndpoint[window.env]) {
        return baseEndpoint[window.env];
    }
    return baseEndpoint.test;
}

export function endpoints() {
    // baseEndpoint = getDomainEndpoints() ... / make endpoint dynamic in the future..
    console.log('Base Endpoint -> ', baseEndpoint);
    console.log('endpoints() environment ->', window.env);
    if (window.env !== undefined && window.env !== 'local') {
        return EndpointSources.remote();
    }
    return EndpointSources.local();
}
