
import { makeStyles } from '@mui/styles';

const styles = (theme) => ({

    container: {
      marginTop: '64px',
      height: '100vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'column !important',
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflow: 'auto',

      [theme.breakpoints.down('sm')]: {
        width: 'calc(100%  - 32px)',
      },
      
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#FFFFFF',
      width: '100%',
      height: '40px',
    },

    fields: {
      display: 'flex',
      flexDirection: 'column !important',
      marginTop: '16px',
      width: 'calc(100% - 16px)',
      paddingBottom: '24px',
    },

    devices: {
      display: 'flex',
      flexDirection: 'column !important',
      alignItems: 'center',
      width: 'calc(100%)',
    },

    

});

export default styles;
export const useStyles = makeStyles(styles);
