import { makeStyles } from '@mui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

  defaultContent: {
    display: 'flex',
    width: 'calc(100% - 16px)',
    flexDirection: 'column !important',
    alignItems: 'center',
    marginTop: '24px',
    marginBottom: '14px',
  },

  dialogActions: {
    justifyContent: 'flex-end',
    padding: '8px',
  },

  message: {
    marginTop: '10px !important',
    fontWeight: 'bold !important',
    fontSize: '20px !important',
  },

  subMessage: {
    marginTop: '8px !important',
    textAlign: 'center',
    fontSize: '16px !important',
  },

  messageSmall: {
    fontWeight: 'bold !important',
    fontSize: '18px !important',
    textAlign: 'center !important',
  },

  subMessageSmall: {
    marginTop: '8px !important',
    textAlign: 'center',
    fontSize: '12px !important',
  },

  subtitleBox: {
    width: '80%',
  },

  subtitle: {
    fontSize: '10px',
    textAlign: 'center',
  },

  buttons: {
    display: 'flex',
    flexDirection: 'column !important',
    alignItems: 'center',
    marginBottom: '24px',
  },

  // button: {
  //   marginTop: '16px !important',
  // },
  
  textInput: {
    width: 'calc(100% - 16px)',
    marginTop: '16px !important',
  },

}));
