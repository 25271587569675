import axios from 'axios';
import isEmpty from 'lodash/isEmpty'

export function get(
    endpoint,
    options = { token: '', traceId: '', headers: {}, action: '' }
) {
    const { token, headers, traceId } = options;

    async function execRequest(resolve, reject) {
        const reqHeaders = {
            authorization: 'Bearer ' + token,
            'x-trace-id': traceId,
            ...headers,
        };

        if (isEmpty(traceId)) {
            delete reqHeaders['x-trace-id'];
        }

        if (isEmpty(token)) {
            delete reqHeaders.authorization;
        }

        axios
            .get(endpoint, { headers: reqHeaders })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                resolve(error);
            });
    }

    return new Promise(execRequest);
}

export default get;
