import React from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';

import { Typography } from '../wrappers';
import { useStyles } from './cardField.styles';

import {
    Grid,
} from '@mui/material';

function CardField (props) {
    const {
        label,
        value,
    } = props;

    const classes = useStyles();

    return (
        <Grid
            className={classes.base}
        >
            <Grid className={classes.label}>
                <Typography
                    color='primary'
                    className={classes.labelText}
                >
                         {label}
                </Typography>
            </Grid>

            <Grid className={classes.value}>
                <Typography
                    color='primary'
                    className={classes.valueText}
                >
                         {value}
                </Typography>
            </Grid>
        </Grid>
    );  
}

CardField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    
};

CardField.defaultProps = {
   label: 'Field name',
   value: '',
};

export default CardField;