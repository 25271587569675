/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
    useSchedulesStore,
    useSubscriptionsStore,
} from '../index';

function useSubscriptions (props) {
    const {
        openDeleteSuccessDialog = () => {},
        openAddScheduleDialog = () => {},
    } = props;


    const subscriptionsStore = useSubscriptionsStore();
    const schedulesStore = useSchedulesStore();

    const [subscriptions, setSubscriptions] = useState([]);
    const [inactiveSubscriptions, setInactiveSubscriptions] = useState([]);
    const [recommendations, setRecommendations] = useState([]);

    const [loadProgress, setLoadProgress] = useState({
        subscriptions: true,
        active: false,
        inactive: false,
    });
    const [pageInfo, setPageInfo] = useState({});

    useEffect(() => {
        if (subscriptionsStore.recommendations) {
            setRecommendations(subscriptionsStore.recommendations);
        }
    }, [subscriptionsStore.observed.recommendations]);

    useEffect(() => {
        if (typeof openDeleteSuccessDialog === 'function' && !isEmpty(schedulesStore.deleteList)) {
            const deletedScheduleList = schedulesStore.deleteList;
            const deletedSchedule = deletedScheduleList[deletedScheduleList.length - 1];
            openDeleteSuccessDialog(deletedSchedule);
        }
    }, [schedulesStore.observed.deleteList]);

    useEffect(() => {
        // console.log('useScheduleItem.useEffect.addList', schedulesStore.addList)
        if (typeof openAddScheduleDialog === 'function' && !isEmpty(schedulesStore.addList)) {
            openAddScheduleDialog();
        }
    }, [schedulesStore.observed.addList]);

    useEffect(() => {
        updateSubscriptions();
    }, [
        subscriptionsStore.observed.subscriptions,
        subscriptionsStore.observed.availableSchedules,
        schedulesStore.observed.deleteList,
        schedulesStore.observed.addList
    ]);

    useEffect(() => {
        setLoadProgress(subscriptionsStore.loadProgress);
    }, [subscriptionsStore.observed.loadProgress]);

    useEffect(() => {
        updateSubscriptions();
        setLoadProgress(subscriptionsStore.loadProgress);
    }, []);

    useEffect(() => {
        setPageInfo(subscriptionsStore.pageInfo);
    }, [subscriptionsStore.observed.subscriptions]);

    function updateSubscriptions () {
        if(subscriptionsStore.subscriptions) {
            const newSubscriptions = subscriptionsStore.subscriptions;
            setSubscriptions([...newSubscriptions]);
            setInactiveSubscriptions(subscriptionsStore.inactiveSubscriptions);
        } else {
            // subscriptionsStore.getDefaultSubscriptions(false);
        }
    }
 
    function getMoreSubscriptions () {
        subscriptionsStore.nextPage();
    }

    return {
        subscriptions,
        recommendations,
        inactiveSubscriptions,
        getMoreSubscriptions,
        loadProgress,
        pageInfo,
    };
}

export default useSubscriptions;