
import { makeStyles } from '@mui/styles';

const styles = (theme) => ({

  base: ({ marginTop }) => {
    return {
        display: 'flex',
        flexDirection: 'column !important',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textDecoration: 'none',
        borderRadius: '4px',
        width: '100%',
        // minHeight: moreCardMinHeight,
        overflow: 'hidden',
        border: '1px solid #E1E1E1',
        backgroundColor: '#FFFFFF',
        marginTop,
    };
  },

  moreCardBase: ({ marginTop, moreCardMinHeight }) => {
   
    return {
      display: 'flex',
      flexDirection: 'column !important',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textDecoration: 'none',
      borderRadius: '4px',
      width: '95%',
      minHeight: moreCardMinHeight,
      overflow: 'hidden',
      border: '1px solid #E1E1E1',
      backgroundColor: '#FFFFFF',
      marginTop,
    };
  },

  loadingText: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '90px',
    fontSize: '12px !important',
    fontFamily: 'Gotham Rounded !important',
  },


  noScheduleText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    fontSize: '12px !important',
    fontFamily: 'Gotham Rounded !important',
  },

  header: {
      display: 'flex',
      width: '100%',
      height: '50px',
      borderBottom: '1px solid #F1F1F1',
      alignItems: 'center',
  },

  contentBase: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
  },

  content: {
      display: 'flex',
      justifyContent: 'center',
      width: 'calc(100% - 16px)',
  },

  icon: {
      marginLeft: '16px',
  },

  avatar: {
    width: '32px !important',
    height: '32px !important',
  },

  title: {
    marginLeft: '12px !important',
  },

  moreFrom: {
    display: 'flex',
    alignItems: 'center',

    fontWeight: '500 !important',
    marginLeft: '24px !important',
    marginTop: '12px !important',

    color: '#000000DE !important',
    width: '100%',
    height: '48px',

  },

  unsubscribed: {
    display: 'flex',
    flexDirection: 'column !important',
    width: '100%',
    marginBottom: '24px',
  },

  subscribedSchedules: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column !important',
    width: '100%',
  },

  availableSchedules: {
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    flexDirection: 'column !important',
    width: '100%',
    paddingBottom: '12px',
    maxHeight: '600px',

    /* Hide scrollbar */
    '&::-webkit-scrollbar': {
      // display: 'none', // for Chrome, Safari and Opera
    },

    '&-ms-overflow-style:': {
      display: 'none', // for IE
    },

    scrollbarWidth: 'none', // for Firefox 
  },

  unbsubcribeButtonSpace: {
    display: 'flex',
    width: '100%',
    height: '56px',
    justifyContent: 'center',

    borderTop: '1px solid #00000033',
  },

  unsubscribeButton: {
    textTransform: 'capitalize !important',
    color : '#EA5758 !important',
    textDecoration: 'underline !important',

    '&:hover': {
      color : '#FF0000 !important',
    },
  },

  deviceInfo: ({ marginTop, marginBottom, variant = '' }) => {

    function getBorder () {
      if (variant === 'small') {
        return '0px none';
      }
      return '1px solid #F7F7F7';
    }
    function getMarginTop () {
      if (marginTop) {
        return marginTop;
      }
      return 0;
    }

    function getMarginBottom () {
      if (marginBottom) {
        return marginBottom;
      }
      return 0;
    }

    return {
      display: 'flex',
      flexDirection: 'column !important',
      justifyContent: 'flex-start',
      width: 'calc(100% - 16px)',
      borderRadius: '6px',
      border: getBorder(),
      padding: '8px',
      marginBottom: getMarginBottom(),
      marginTop: getMarginTop(),
    };
  },

  deviceInfoTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px !important',
    fontWeight: 'bold !important',
    marginBottom: '8px !important',
    color: '#737373',
     
  },

  deviceInfoField: {
    display: 'flex',
    justifyContent: 'flex-start',
  },

  deviceInfoLabel: {
    display: 'flex',
    alignItems: 'center',
    width: '120px !important',
    fontSize: '12px !important',
    fontWeight: 'bold !important',
    marginRight: '16px',
  },

  deviceInfoValue: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'calc(100% - 80px) !important',
    fontSize: '12px !important',
  },

  loadUnsubscribeMessage: {
    paddingLeft: '15px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '140px',
  }

});

export default styles;
export const useStyles = makeStyles(styles);
