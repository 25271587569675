// import isEmpty from 'lodash/isEmpty'
import { v4 as uuidv4 } from 'uuid';
import {
    preloadImages,
    commonImagesList,
} from '../../../../commons/utils/imagePreloader';

function finalizeSessionInit (props) {
    const {
        params,
        authSession,
    } = props;
 
    if (this.isOauth(params)) {
        this.persistOauthInfo({ session: authSession, params });
    }

    const newSession = this.persistToken({ params });
    // always persist session from params if params is available, else use session loaded from sessionStorage
    if (newSession) {
        //use the session from the params
        this.session = newSession;
        document.managedSession = newSession;
    } else {
        // used the session from sessionStorage
        this.session = authSession;
        document.managedSession = authSession;
    }

    document.appInitPhase = 'INIT COMPLETE';
    this.redirectToHome(params);
    preloadImages(commonImagesList());
    console.log('EnvStore.finalizeSessionInit() Session -> ', document.managedSession);
    this.observed.initialized = uuidv4();
}

export function redirectToHome (params) {
    const path = this.page.path;
    this.page.path.indexOf('/login');
    if (path.indexOf('/profile') !== -1 ||
        path.indexOf('/subscriptions') !== -1) {
            return;
    }
    this.navigate('/home');
}


export default finalizeSessionInit;
