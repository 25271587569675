import { v4 as uuidv4 } from 'uuid';
import {
    removeScheduleAPI,
} from './api';

import { getStore } from '../../factory';

export async function deleteSchedule (subscription, schedule) {
    const session = this.env.session;
    const traceId = this.env.traceId;

    this.env.startLoadCounter({ target: 'appProgress' });
    // console.log('deleteSchedule.action.subscription -> ', subscription);
    console.log('action.deleteSchedule. -> ', schedule);

    this.loadingList[schedule.scheduleId] = true;
    this.observed.loadingList = uuidv4();

    const bodyObj = {
        'calendarIds': [schedule.scheduleId],
    }

    const res = await removeScheduleAPI ({
        body: { ...bodyObj},
        store: this,
        token: session.token,
        traceId,
        subscriptionId: subscription.id,
    });

    this.env.stopLoadCounter();

    delete this.loadingList[schedule.scheduleId];
    this.observed.loadingList = uuidv4();

    if (res?.error) {
        return;
    };
    
    /*
    * Note: This function is to move the deleted schedule to the availableScheudles list.
    * Reason: This action is done within the state change in order to avoid making too many getSubscriptions call
    */
    const subscriptionsStore = getStore('subscriptions');
    console.log('deleteSchedule() -> ', subscriptionsStore);
    subscriptionsStore.deleteInSubscribedSchedules({
        subscription,
        schedule,
    });

    subscriptionsStore.addInAvalaibleSchedules({
        subscription,
        schedule,
    });

    this.deleteList.push(schedule);
    this.observed.deleteList = uuidv4();
}

export function removeItemFromDeletelist () {
    this.deleteList.pop();
    console.log('action.removeItemFromDeletelist. -> ', this.deleteList);
}
