
import { makeStyles } from '@mui/styles';

const styles = (theme) => ({
 
    checkGroup: {
     
      '&.MuiFormControlLabel-root': {
        display: 'flex',
        marginLeft: 0,
        marginRight: 0,
        marginTop: '12px',
        
      },

    },

    checkbox: {
        '&.MuiButtonBase-root': {
            padding: '0px !important',
        },

        // with space means child style
        '& .MuiSvgIcon-root': {
            color: '#536DFF',
        },
    },

    checklabel: {
      fontSize: '12px !important',
      marginLeft: '8px !important',

      [theme.breakpoints.up('sm')]: {
        fontSize: '14px !important',
      },
    },
});

export default styles;
export const useStyles = makeStyles(styles);
