import React from 'react';
import { observer } from 'mobx-react';

import {
  Grid,
} from '@mui/material';

import useStyles from './header.styles';
import CommonImages from '../../../commons/images';
import BackMenuButton from './BackMenuButton';
 
function Header () {

  const classes = useStyles();
   
  return (
    <div  className={classes.appBar}>
        <BackMenuButton />
        <Grid
          className={classes.barBody}
        >
              <img
                className={classes.logo}
                alt='MyEcal'
                src={CommonImages.MME_DARK}
              />

              <img
                className={classes.logoWord}
                alt='MyEcal'
                src={CommonImages.MME_SHORT_WORD}
              />

        </Grid>
    </div>
   
  );
}

export default observer(Header);
