/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react';

import {
    Grid,
} from '@mui/material';

import { useStyles } from './subscriptions.styles';

import {
    Circle as StatusIcon,
} from '@mui/icons-material';

import CommonImages from '../../commons/images';
import { updateSubscriptionsData } from './util.js';

import Card from '../components/card';
import MainTitle from '../components/mainTitle';
import MoreBar from '../components/moreBar';
import SubscriptionItem from './subscriptionItem';

import useSubscriptions from '../../store/models/subscriptions/useSubscriptions';
import useSchedules from '../../store/models/schedules/useSchedules';
import useSubscriptionsDialogs from './useSubscriptionsDialogs';

function Subscriptions (props) {
    const {
        renderDialogs,
        openAddScheduleDialog,
        openRemoveScheduleDialog,
        openDeleteSuccessDialog,
        openUnsubscribeDialog,
        openUnsubscribeSuccessDialog,
    } = useSubscriptionsDialogs();
    
    const {
        subscriptions,
        inactiveSubscriptions,
        getMoreSubscriptions,
        loadProgress,
        pageInfo,
    } = useSubscriptions({
        openDeleteSuccessDialog,
        openAddScheduleDialog,
    });

    const classes = useStyles();

    const subscriptionScheduleData = useSchedules({
        openUnsubscribeSuccessDialog,
    });

    function renderSubscriptions () {

        if (isEmpty(subscriptions) || subscriptions.length <= 0) {
            return (<React.Fragment></React.Fragment>);
        }

        return subscriptions.map((item, index) => {
            item = updateSubscriptionsData(item);
            
            return (<SubscriptionItem
                    key={`subscription-${index}`}
                    subscription={item}
                    showUnsubscribe={true}
                    marginTop={index === 0 ? '0px' : '16px'}
                    // openAddScheduleDialog={openAddScheduleDialog}
                    openRemoveScheduleDialog={openRemoveScheduleDialog}
                    // openDeleteSuccessDialog={openDeleteSuccessDialog}
                    openUnsubscribeDialog={openUnsubscribeDialog}
                    openUnsubscribeSuccessDialog={openUnsubscribeSuccessDialog}
                    subscriptionScheduleData={subscriptionScheduleData}
                />)
        });
    }

    function renderInactive () {
        if (isEmpty(inactiveSubscriptions) || inactiveSubscriptions.length <= 0) {
            return (<React.Fragment></React.Fragment>);
        }

        return inactiveSubscriptions.map((item, index) => {
            item = updateSubscriptionsData(item);
            return (<SubscriptionItem
                    key={`subscription-${index}`}
                    subscription={item}
                    marginTop={index === 0 ? '0px' : '16px'}

                    subscriptionScheduleData={subscriptionScheduleData}
                />)
        });
    }

    function renderLoadMoreBar () {
        if (isEmpty(subscriptions) && isEmpty(inactiveSubscriptions)) {
            return (<React.Fragment></React.Fragment>);
        }

        return (
                <Grid className={classes.loadMoreBarContent}>
                    <MoreBar
                        pageInfo={pageInfo}
                        label="Load more"
                        marginTop={'16px'}
                        onClick={() => getMoreSubscriptions()}
                        isLoadingProgress={loadProgress.subscriptions}
                        loadingMessage={loadProgress.progressLabel}
                    />
                </Grid>
        );
    }

    function renderActiveCard () {
        if (isEmpty(subscriptions) || subscriptions.length <= 0) {
            return (<React.Fragment></React.Fragment>);
        }

        return (
            <Card
                id={'active-list'}
                title={'Active'}
                marginBottom={'32px'}
                icon={(<StatusIcon 
                    className={classes.activeStatus}
                />)}
                showHeaderButton={false}
            >
                <Grid className={classes.cardContent}>
                    { renderSubscriptions() }
                </Grid>
            </Card>
        );
    }

    function renderInactiveCard () {
        if (isEmpty(inactiveSubscriptions) || inactiveSubscriptions.length <= 0) {
            return (<React.Fragment></React.Fragment>);
        }

        return (
            <Card
                id={'inactive-list'}
                title={'Inactive'}
                marginBottom={'32px'}
                icon={(<StatusIcon 
                    className={classes.inactiveStatus}
                />)}
                showHeaderButton={false}
            >
                <Grid className={classes.cardContent}>
                    { renderInactive() }
                </Grid>
            
            </Card>
        );
    }

    return (
        <React.Fragment>
            <Grid
                id="content"
                className={classes.container}
            >
                <MainTitle
                    label="My Subscriptions"
                    icon={(<img alt='My Subscriptions' src={CommonImages.ECAL_CALENDAR_CIRCLE} width='24px' />)}
                    marginRight={'20px'}
                />
                { renderLoadMoreBar() }
                { renderActiveCard() }
                { renderInactiveCard()}
            </Grid>

            { renderDialogs() }
        </React.Fragment>
    );
}

export default observer(Subscriptions);