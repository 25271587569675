import { v4 as uuid } from 'uuid';
 
export function showProgress (message) {
    if (message) {
        this.progressMessage = message;
        this.observed.progressMessage = uuid();
    }
    this.progressOpen = true;
    this.observed.progressOpen = uuid();
}

export function closeProgress () {
    const objCtx = this;

    setTimeout(() => {
        objCtx.progressOpen = false;
        objCtx.observed.progressOpen = uuid();
    }, 1000);
}

export function showAppProgress () {
    this.appProgressOpen = true;
    this.observed.appProgressOpen = uuid();
}

export function closeAppProgress () {
    this.appProgressOpen = false;
    this.observed.appProgressOpen = uuid();
}

// export function updateTimezone (tz) {
//     this.timezone = tz;
// }

const actions = {
    showProgress,
    closeProgress,
    
    showAppProgress,
    closeAppProgress,
    
    // updateTimezone,
};

export default actions;
