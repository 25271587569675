import axios from 'axios';

export async function gqlGet (
    endpoint,
    query,
    options = { token: '', traceId: '', headers: {}, action: '' }
) {
    const { token, headers, traceId } = options;
    
    async function execRequest(resolve, reject) {
        const reqHeaders = {
            authorization: 'Bearer ' + token,
            'x-trace-id': traceId,
            ...headers,
        };

        axios
            .get(endpoint, { headers: reqHeaders })
            .then(function (response) {
                

                resolve(response);
            })
            .catch(function (error) {
               
                resolve(error);
            });
    }

    return new Promise(execRequest);
}

export default gqlGet;
