import {
    Button as ButtonBase,
} from '@mui/material';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useStyles } from './button.styles';


function Button (props) {

    const {
        children,
        color,
        className,
        style,
        sizeVariant,
        textDecoration,
        ...others
    } = props;
   
    const classes = useStyles({
      color,
      sizeVariant,
      textDecoration,
    });

    return (
      <ButtonBase
        classes={{
          contained: classes.contained,
          root: classes.root,
          outlined: classes.outlined,
          text: classes.text,
        }}
        {...others}
    
        className={classnames(
          {
            [classes.select]: props.select,
          },
          className
        )}
        style={{ ...style }}
      >
        {children}
      </ButtonBase>
    );
}


Button.propTypes = {
    sizeVariant: PropTypes.string,
    textDecoration: PropTypes.string,
};

Button.defaultProps = {
    sizeVariant: 'small',
    textDecoration: 'none',
};

export default Button;

