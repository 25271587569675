
import { makeStyles } from '@mui/styles';

function getColor (color, theme, brightness = 'main') {
    if (color && theme.palette[color] &&
        theme.palette[color][brightness]) {
      return theme.palette[color][brightness];
    }
}

function getSizeVariant(variant = 'small') {
    if (variant === 'freeflow') {
        return {
            width: '100%',
            height: '100%',
        };
    }

    if (variant === 'medium') {
        return {
            width: '88px',
            height: '33px',
        };
    }
    return {
        width: '66px',
        height: '25px',
    };
}


//  Dark Variant = 0C0644, Light Variant 536DFF
export const useStyles = makeStyles(theme => ({
    root: ({ color }) => {
        return {
            color: getColor(color, theme),
        };
    },

    contained: ({ color = '#536DFF', sizeVariant }) => {
        return  {
            borderRadius: '65px !important',
            backgroundColor: `${color} !important`, // getColor(color, theme),
           
            fontSize: '12px !important',
            textTransform: 'none !important',
            boxShadow: theme.customShadows.widget,
            color: '#FFFFFF', 

            '&:hover': {
                backgroundColor: getColor(color, theme, 'light'),
                boxShadow: theme.customShadows.widgetWide,
            },

            "&:disabled": {
                backgroundColor: '#F1F1F1 !important',
            },

            "& [data-type='whhite']": {
                backgroundColor: '#FFFFFF !important',
            },

            '&:active': {
                boxShadow: theme.customShadows.widgetWide,
            },

            ...getSizeVariant(sizeVariant),
        };
     },

    outlined: ({ color = '#536DFF', borderColor = '#536DFF', fontSize = '12px !important' }) => {
        return {
            fontSize: fontSize,
            textTransform: 'none !important',
            color: color,
            borderColor: borderColor,
        };
    },

    text: ({ sizeVariant, color = '#010101', textDecoration = 'none'}) => {
        return {
            color: `${color} !important`,
            textTransform: 'none !important',
            textDecoration: `${textDecoration} !important`,
            ...getSizeVariant(sizeVariant),
        };
    },

    select: () => {
        return {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        };
    },
    
}));