import CommonImages from "../images";

export function commonImagesList () {
    const imgs = [];
    for (let i in CommonImages) {
        const img = CommonImages[i];
        imgs.push(img);
    }
    return imgs;
}

export function loadingImageList () {
    return [
        CommonImages.ECAL_PRELOADER,
        CommonImages.COVER_PHOTO,
        CommonImages.GOOGLE_LOGO,
        CommonImages.OUTLOOK_LOGO,
        CommonImages.MME_DARK,
        CommonImages.MME_SHORT_WORD,
        CommonImages.MY_ECAL_LOGO,
    ];
}


export function preloadImages (images) {
    var imgIndex = 0;
    var preloader = document.createElement('div');
    preloader.style = "display: none";
    document.body.appendChild(preloader);
    function endPreloading () {
        document.body.removeChild(preloader);
    }
 
    function doPreload () {
        if (imgIndex >= images.length) {
            endPreloading();
            return;
        }
        loadImage();
    }

    function next () {
        imgIndex++;
        doPreload();
    }

    function loadImage () {
        var img = document.createElement('img');
        img.onload = function () {
            preloader.removeChild(img);
            next();
        }
        img.src = images[imgIndex];
        preloader.appendChild(img);
    }

    doPreload();
}