import React from 'react';
import {
    IconButton,
 } from '@mui/material';

import {
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon,
} from '@mui/icons-material';

import { useStyles } from './subscriptionSlider.styles';

export function CarouselNextArrow(props) {
    const { onClick } = props;
    const classes = useStyles();
    return (
        <div
            className={classes.iconContainer}
            data-ecal-id="nextArrow"
            >
            <IconButton
                className={classes.arrowButton}
                onClick={() => { 
                    // console.log('props', props);
                    if(onClick && typeof onClick === 'function') {
                        onClick();
                    }
                }}
            >
                <NavigateNextIcon color={'#FFFFFF'} />
            </IconButton>
        </div>
    );
  }
  
  export function CarouselPrevArrow(props) {
    const { onClick } = props;
    const classes = useStyles();
    return (
        <div
            className={classes.iconContainer}
            data-ecal-id="prevArrow"
           >
            <IconButton
                className={classes.arrowButton}
                onClick={() => {
                    // console.log('props', props);
                    if(onClick && typeof onClick === 'function') {
                        onClick();
                    }
                }}
            >
                <NavigateBeforeIcon color={'#FFFFFF'} />
            </IconButton>
        </div>
    );
  }
