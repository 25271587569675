import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import useStyles from './styles';
import { Typography } from '../wrappers';

const COPYRIGHT_TEXT = `© 2010-${new Date().getFullYear()} | ECAL | HyperKu Pte Ltd | All rights reserved.`;

function Footer (props) {
  const {
    bgColor,
    fontColor,
  } = props;

  const classes = useStyles({ bgColor, fontColor });

  return (
    <footer className={classes.body}>
      <Typography variant={'body2'} color="light">
        { COPYRIGHT_TEXT }
      </Typography>
    </footer>
  );
}

Footer.propTypes = {
  bgColor: PropTypes.string,
  fontColor: PropTypes.string,
};

Footer.defaultProps = {
  bgColor: '#0c0644',
  fontColor: '#ffffff',
};

export default observer(Footer);
