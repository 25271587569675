/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
    useSchedulesStore,
    useEnvStore,
} from '../../store/models/index';

function useScheduleItem (props) {
    const {
        schedule,
        subscription,
        // openAddScheduleDialog,
        openRemoveScheduleDialog,
        // openDeleteSuccessDialog,
    } = props;

    const schedulesStore = useSchedulesStore();
    const envStore = useEnvStore();
    
    const [loadingList, setLoadingList] = useState({});
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [checked, setChecked] = useState(true);

    useEffect(() => {
        setLoadingList(schedulesStore.loadingList);
    }, [schedulesStore.observed.loadingList]);

    useEffect(() => {
        if(typeof loadingList !== 'undefined' && loadingList[schedule?.scheduleId]) {
            setLoadingMessage(envStore.loadMessage);
            setChecked(true);
        }
    }, [envStore.observed.loadMessage]);

    async function onAddSchedule (subscription, schedule) {
        await schedulesStore.addSchedule(subscription, schedule);
    }

    useEffect(() => {
        if(!isEmpty(schedulesStore.cancelList) && schedulesStore.cancelList[schedule?.scheduleId]) {
            setChecked(true);
            schedulesStore.removeItemFromCancelList(schedule);
        }
    }, [schedulesStore.observed.cancelList]);

    // delete schedule action
    const handleChange = () => {
        setChecked(!checked);
        if (typeof openRemoveScheduleDialog === 'function' && checked) {
            openRemoveScheduleDialog(subscription, schedule);
        }
    };

    // add schedule action
    const handleClick = () => {
        if (typeof onAddSchedule === 'function') {
            onAddSchedule(subscription, schedule);
        }
    };

    return {
        loadingList,
        loadingMessage,
        checked,

        handleChange,
        handleClick,
    };
}

export default useScheduleItem;