import getDeviceInfo from "../../../../commons/deviceInfo";
// import delay from "../../../../commons/utils/delay";
// called in envStore.initAPP();
export async function getClientInfo (params) {
    // await delay(10000);
    const deviceInfo = await getDeviceInfo(); 

    this.clientInfo = {
        ...params,
        deviceInfo,
    };

    console.log('getClientInfo() : ',  this.clientInfo);
}

export default getClientInfo;
