import createSubscription from './createSubscription';
import getSubscriptions from './getSubscriptions';
import getDefaultSubscriptions from './getDefaultSubscriptions';
import selectActiveInactive from './selectActiveInactive';
import {
    unsubscribe,
    removeItemFromUnsubscribeList,
    expandSubscriptions,
    closeSubscriptions,
} from './unsubscribe';
import {
    expandSubscriptionCard,
    resetSubscriptionsExpanded,
 } from './expandSubscriptions';

import getRecommendations from './getRecommendations';
import getAvailableSchedules,  {
    expandAvailableSchedules,
    closeAvailableSchedules,
} from './getAvailableSchedules';

import { addInAvalaibleSchedules } from './addInAvailableSchedules';
import { addInSubscribedSchedules } from './addInSubscribedSchedules';
import { deleteInAvalaibleSchedules } from './deleteInAvailableSchedules';
import { deleteInSubscribedSchedules } from './deleteInSubscribedSchedules';

import { addInInactiveSubscriptions } from './addInInactiveSubscriptions';
import { deleteInActiveSubscriptions } from './deleteInActiveSubscriptions';
import {
    nextPage,
} from './nextPage';

import {
    startSubscriptionProgress,
    stopSubscriptionProgress,
} from './progressUpdater';

export const actions = {
    createSubscription,
    getSubscriptions,
    getDefaultSubscriptions,
    unsubscribe,
    removeItemFromUnsubscribeList,
    expandSubscriptions,
    closeSubscriptions,
    getRecommendations,
    selectActiveInactive,
    expandSubscriptionCard,
    resetSubscriptionsExpanded,
    getAvailableSchedules,
    expandAvailableSchedules,
    closeAvailableSchedules,
    addInAvalaibleSchedules,
    addInSubscribedSchedules,
    deleteInAvalaibleSchedules,
    deleteInSubscribedSchedules,

    addInInactiveSubscriptions,
    deleteInActiveSubscriptions,
    
    nextPage,
    startSubscriptionProgress,
    stopSubscriptionProgress,
};

export default actions;
