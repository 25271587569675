import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import {
  Grid,
} from '@mui/material';

import {
  Button,
} from '../components/wrappers';
import Footer from '../components/footer';

import { useStyles } from './login.styles';
import CommonImages from '../../commons/images';
import useProfile from '../../store/models/profile/useProfile';

function Login (props) {
  const classes = useStyles({});
   
  const {
    googleOauth,
    outlookOauth,
    type,
  } = useProfile();

  useEffect(() => {
    console.log('login is loaded..');
  }, []);

  // may become componentized...
  function renderBrandCover () {
    return (
      <div className={classes.brandCoverSpace}>
        <img
          alt="logo"
          className={classes.brandCoverImage} 
          src={CommonImages.COVER_PHOTO}
        />
      </div>
    ); 
  }

  function renderGoogleButton () {
    if (type === 'outlook') {
      return (<React.Fragment></React.Fragment>);
    }

    return (<Button
      size="large"
      variant='contained'
      className={classes.oauthButton}
      onClick={() => {
        googleOauth();
      }}
    >
      <img src={CommonImages.GOOGLE_LOGO} alt="google" className={classes.googleLogo} />
      &nbsp;Sign in with Google
    </Button>);
  }

  function renderOutlookButton () {
    if (type === 'google') {
      return (<React.Fragment></React.Fragment>);
    }

    return (<Button
      size="large"
      variant='contained'
      className={classes.oauthButton}
      onClick={() => {
        outlookOauth();
      }}
    >
      <img src={CommonImages.OUTLOOK_LOGO} alt="outlook" className={classes.googleLogo} />
      &nbsp;Sign in with Outlook
    </Button>);
  }
 
  function renderUI () {
     // <div id='logoImage' className={classes.brandLogoImage}></div>
    return(
      <div className={classes.formContainer}>
        <div className={classes.form}>

          <Grid item container className={classes.brandLogoContainer}>
            
              <img
                className={classes.logo}
                alt='MyEcal'
                src={CommonImages.MME_DARK}
              />

              <img
                className={classes.logoWord}
                alt='MyEcal'
                src={CommonImages.MME_SHORT_WORD}
              />

          </Grid>

          { renderGoogleButton() }
          { renderOutlookButton() }

        </div>
        <Grid className={classes.loginFooter}>
            <Footer 
              bgColor={'trasparent'}
              fontColor={'#0c0644'}
            />
          </Grid>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <div className={classes.spacer}> </div>
        { renderBrandCover() }
        { renderUI() }
      </Grid>
    </React.Fragment>
  );
}

export default observer(Login);
