import isEmpty from 'lodash/isEmpty'
import {
   APIErrors,
} from '../../models/errors/actions/ApiErrors';

export function shouldDispatchGenericError (res, store) {
    if (res?.status === 200){
        return false;
    }
    const env = store.env;
    const errorsStore = env.errorsStore;

    if (shouldHandleAsStatusError(res,store)) {
        return true;
    }
    
    if (!isEmpty(res?.code)) {
        if (env && errorsStore) {
            const errorData = {
                ...res.response.data.errors[0],
                code: res.code,
                traceId: env.traceId,
            };
            // console.log('shouldDispatchGenericError.errorData', errorData);
            errorsStore.dispatch(errorData);
        }
        return true;
    }

    return false;
}


const errorsStatusMap = {
    error404: APIErrors.Error404,
    error500: APIErrors.Error500,
};
 
function shouldHandleAsStatusError (res, store ) {
    const env = store.env;
    const errorsStore = env.errorsStore;
    // console.log('shouldHandleAsStatusError() Error: ', res);

    if (isEmpty(res.response)) {
        return false;
    }
    // this experssion will produce error[ErrorStatusNumber], like error404, error500 , to extend it just add it in errorStatusMap
    const error = errorsStatusMap[`error${res.response.status}`]; 
    // When there's a match in erroStatusMap, distpatch it and return true.
    if (error) {
        errorsStore.dispatch(error);
        return true;
    }
    return false;
}