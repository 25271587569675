import { makeStyles } from '@mui/styles';
// import tinycolor from 'tinycolor2';

export default makeStyles(theme => ({

  dialogBase: (prop) => {
     
    return {
      borderRadius: '12px !important',

      [theme.breakpoints.down('sm')]: {
        
        margin: '16px !important',

      },
    };
  },


  dialogContainer: (prop) => {
    const {
      maxWidth = '337px',
    } = prop;
  
    return {
      display: 'flex',
      flexDirection: 'column !important',
      minWidth: '337px',
      maxWidth: maxWidth,
      minHeight: '240px',
      alignItems: 'center',
      justifyContent: 'center',

      padding: theme.spacing(1),

      [theme.breakpoints.down('sm')]: {
        minWidth: 'calc(100% - 16px)',
        margin: '0px',
        padding: '8px',
      },
    };
  },

  defaultContent: {
    display: 'flex',
    flexDirection: 'column !important',
    alignItems: 'center',
    marginTop: '24px',
    marginBottom: '24px',
  },

  dialogActions: {
    justifyContent: 'flex-end',
    padding: '8px',
  },

  message: {
    fontWeight: 'bold !important',
    fontSize: '20px !important',
  },

  subMessage: {
    marginTop: '8px !important',
    textAlign: 'center',
    fontSize: '16px !important',
  },

  subtitleBox: {
    width: '80%',
  },

  subtitle: {
    fontSize: '10px',
    textAlign: 'center',
  },

  buttons: {
    display: 'flex',
    flexDirection: 'column !important',
    alignItems: 'center',
    marginBottom: '24px',
  },

  confirmButton: {
    marginTop: '16px !important',
    fontWeight: '325 !important',
    lineHeight: '20px !important',    
    fontSize: '16px !important',
  },

  cancelButton: {
    marginTop: '4px !important',
    fontWeight: '325 !important',
    lineHeight: '20px !important',
    fontSize: '14px !important',
  },

  traceId: {
    textAlign: 'center',
    fontSize: '11px !important',
    marginBottom: '3px',

    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '11px !important',
    // },
  },

  smallMessage: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '11px !important',
    marginBottom: '5px',
    marginTop: '-7px',
    fontFamily: 'Gotham Rounded !important',
  },

  syncImageMessage: {
    width: '19px',
    marginTop: '-3px',
  },

  contentIconBox: {
    minWidth: '24px',
    minHeight: '24px',
  },

}));
