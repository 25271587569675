/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField as Input,
  Typography,
} from '@mui/material';

import {
  Inbox as InboxIcon,
  ExpandMore as ExpandIcon,
} from '@mui/icons-material';

import { Link } from 'react-router-dom';
import classnames from 'classnames';

// styles
import useStyles from './sidebarLink.styles';

// components
import Dot from '../dots';
import { Button, Badge } from '../wrappers';

export default function SidebarLink (paramProps) {
  
  const {
    link,
    icon,
    label,
    children,
    location,
    isSidebarOpened,
    nested,
    type,
    toggleDrawer,
    click,
    closeAll,
    shouldClose = () => {},
    open: sidebarOpen,
    ...props
  } = paramProps;

  // local
  const [isOpen, setIsOpen] = useState(sidebarOpen || false);
  // Add Section Popover state
  const [anchorEl, setAnchorEl] = React.useState(null);
  // Chat Modal state

  const chatButtonRef = React.useRef(null);
  const isSticky = chatButtonRef?.current?.scrollHeight > chatButtonRef?.current?.clientHeight;

  useEffect(() => {
    if (shouldClose({ link, label, location })) {
      setIsOpen(false);
    }
  }, [closeAll]);
  // Login page onClick
  function onLogin () {
    localStorage.removeItem('token');
    window.location.reload();
  }

  onLogin.clickName = 'onLogin';

  const classes = useStyles(isOpen);

  const isLinkActive =
    link && (location.pathname === link || location.pathname.includes(link));

  if (type === 'title') {
 return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );
}

  if (type === 'divider') return <Divider className={classes.divider} />;

  if (type === 'margin') return <section style={{ marginTop: 240 }} />;

  // Add Section Popover

  const open = Boolean(anchorEl);
  const id = open ? 'add-section-popover' : undefined;

  function addSectionClick (event) {
    setAnchorEl(event.currentTarget);
  }

  addSectionClick.clickName = 'addSectionClick';

  const addSectionClose = () => {
    setAnchorEl(null);
  };
 
  function sideBarIsOpen () {
    return isOpen && isSidebarOpened;
  }

 
  if (link && link.includes('http')) {
    return (
      <ListItem
        data-intercom-target={`sidebar-link-${label}`}
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <a
          className={classes.externalLink} href={link}
        >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {nested ? <Dot color={isLinkActive && 'primary'} /> : icon}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
        </a>
      </ListItem>
    );
  }

  if (!children) {
    return (
      <>
        <ListItem
          data-intercom-target={`sidebar-link-${label}`}
          onClick={e => {
            if (typeof props.clickOverride === 'function') {
              props.clickOverride({
                link,
                label,
              });
            }

            if (typeof props.onLinkItemClickOverride === 'function') {
              props.onLinkItemClickOverride({
                link,
                label,
              });
            }
            if (click) {
              return click(e, addSectionClick, onLogin);
            }
            return toggleDrawer(e);
          }}
          onKeyPress={toggleDrawer}
          button
          component={link && Link}
          to={link}
          ref={chatButtonRef}
          style={props.className && {
            position: isSticky ? 'sticky' : 'absolute',
          }}
          className={classnames(classes.link, props.className, {
            [classes.chatTrigger]: props.className,
          })}
          classes={{
            root: classnames(classes.link, {
              [classes.linkActive]: isLinkActive && !nested,
              [classes.linkNested]: nested,
            }),
          }}
          disableRipple
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
            style={{ margin: nested && -11 }}
          >
            {nested ? <Dot color={isLinkActive && 'primary'} /> : icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={label}
          />
        </ListItem>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={addSectionClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{ paper: classes.popover }}
        >
          <Box m={3} display="flex" flexDirection="column">
            <Typography>Add section</Typography>
            <Input
              placeholder="Section Name"
              classes={{ root: classes.input }}
            />
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                color="secondary"
                variant="contained"
                className={classes.noBoxShadow}
              >
                Add
              </Button>
              <Button
                classes={{ label: classes.buttonLabel }}
                onClick={addSectionClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Popover>
      </>
    );
  }

  return (
    <>
      {props.badge
    ? (
        <ListItem
          button
          component={link && Link}
          onClick={toggleCollapse}
          className={classnames(classes.link, {
            [classes.linkActive]: isLinkActive,
            [classes.nestedMenu]: type === 'nested',
          })}
          to={link}
          disableRipple
          data-intercom-target={`sidebar-link-${label}`}
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {icon || <InboxIcon />}
          </ListItemIcon>
          <Badge badgeContent={props.badge} color={props.badgeColor}>
            <ListItemText
              classes={{
                primary: classnames(classes.linkText, {
                  [classes.linkTextActive]: isLinkActive,
                  [classes.linkTextHidden]: !isSidebarOpened,
                }),
              }}
              primary={label}
            />
          </Badge>
          <ExpandIcon
            className={classnames(
              {
                [classes.expand]: isOpen,
                [classes.linkTextHidden]: !isSidebarOpened,
              },
              classes.expandWrapper
            )}
          />
        </ListItem>
      )

    : (
        <ListItem
          button
          component={link && Link}
          onClick={toggleCollapse}
          className={classnames(classes.link, {
            [classes.linkActive]: isLinkActive,
            [classes.nestedMenu]: type === 'nested',
          })}
          to={link}
          disableRipple
          data-intercom-target={`sidebar-link-${label}`}
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {icon || <InboxIcon />}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={label}
          />
          <ExpandIcon
            className={classnames(
              {
                [classes.expand]: isOpen,
                [classes.linkTextHidden]: !isSidebarOpened,
              },
              classes.expandWrapper
            )}
          />
        </ListItem>
      )}

      {children && (
        <Collapse
          // in={isOpen && isSidebarOpened}
          in={sideBarIsOpen()}
          timeout="auto"
          unmountOnExit
          className={classnames(classes.nestedList, {
            [classes.nestedMenuItem]: type === 'nested',
          })}
        >
          <List component="div" disablePadding>
            {children.map(childrenLink => (
              <SidebarLink
                key={(childrenLink && childrenLink.link) || childrenLink.label}
                location={location}
                isSidebarOpened={isSidebarOpened}
                classes={classes}
                toggleDrawer={toggleDrawer}
                nested
                clickOverride={props.clickOverride}
                onLinkItemClickOverride={props.onLinkItemClickOverride}
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
 
  function toggleCollapse (e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
