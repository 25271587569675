/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react';

import { Grid } from '@mui/material';

import { useStyles } from './home.styles';

import CommonImages from '../../commons/images';
import { Typography } from '../components/wrappers';
import Card from '../components/card';
import ProfileThumbnail from '../components/profileThumbnail';
import SubscriptionSlider from '../components/subscriptionSlider';

import useProfile from '../../store/models/profile/useProfile';
import useSubscriptions from '../../store/models/subscriptions/useSubscriptions';

function Home (props) {
    const classes = useStyles();

    const {
        profile,
    } = useProfile();

    const {
        loadProgress,
        subscriptions,
        recommendations,
    } = useSubscriptions({});

    const navigate = useNavigate();

    const updatable = true;

    function renderSubscriptions () {

        if(loadProgress.subscriptions) {
            return (
                <Grid className={classes.subscriptionsText}>
                    <Typography className={classes.loadingText}>
                        { loadProgress.progressLabel }
                    </Typography>
                </Grid>
            );
        }

        if (!loadProgress.subscriptions && isEmpty(subscriptions)) {
            return (
                <Grid className={classes.subscriptionsText}>
                    <Typography>
                        You have no active subscriptions.
                    </Typography>
                </Grid>
            );
        }

        return (
            <SubscriptionSlider 
                showManage={true}
                showSync={false}
                transparentBox={false}
                subscriptions={subscriptions}
            />
        );
    }

    function renderRecommendations () {
        if (!recommendations || recommendations.length <= 0) {
            return (<React.Fragment></React.Fragment>);
        }

        return (
            <Grid
                id={`recommendations-slider`}
                className={classes.recommendations}
            >
                <Typography>
                    Discover more by adding these popular publishers!
                </Typography>
                <SubscriptionSlider 
                    showManage={false}
                    showSync={true}
                    transparentBox={true}
                    subscriptions={recommendations}
                />
            </Grid>);
    }

    function clickSliderTitle () {
        navigate('/subscriptions'); 
    }

    return (
        <React.Fragment>
            <Grid
                id="content"
                className={classes.container}
            >
                <ProfileThumbnail
                    profileInfo={profile}
                    updatable={updatable}
                />

                <Card
                    id={'subscriptions-slider'}
                    title={'My Subscriptions'}
                    icon={(<img
                                className={classes.sliderLogo}
                                alt='My Calendars' 
                                src={CommonImages.ECAL_CALENDAR_CIRCLE}
                            />)}
                    minContentHeight={'280px'}
                    onClickTitle={clickSliderTitle}
                >
                    { renderSubscriptions() }
                </Card>
                { renderRecommendations() }
            </Grid>           
        </React.Fragment>
    );
}

export default observer(Home);