
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { observer } from 'mobx-react';
import { Grid } from '@mui/material';

import Sidebar from '../components/sidebar';
import structures from '../components/sidebarStructures/'
import Header from '../components/header';
import Footer from '../components/footer';

import { useStyles } from './dashboard.styles';
import { useEnvStore } from '../../store/models/env/index';
import useErrorsDialog from './useErrorsDialog';
import Loading from '../loading';

function Dashboard (props) {
   
    const env = useEnvStore();

    const [showContent, setShowContent] = useState(false);

    const {
        renderErrorDialog,
    } = useErrorsDialog({});

    const [structure,] = useState(structures.defaultStructure({}));
    const classes = useStyles({ showContent });

    useEffect(() => {
        setShowContent(env.observed.initialized);
    }, [env.observed.initialized]);

    function renderSidebar () {
        return ( <Sidebar
            structure={structure}
            onLogOut={() => {
                env.logout();
            }}
        />);
    }

    function renderFooter () {
        return (<Footer />);
    }

    function render () {
        if (!showContent) {
            return (<React.Fragment>
                <Loading />
                { renderErrorDialog() }
            </React.Fragment>);
        }

        return (
            <React.Fragment>
                <Grid container className={classes.container}>
                    <Header />
                    { renderSidebar() }
                    <Grid className={classes.outletParent}>
                        <Outlet className={classes.outlet}/>
                        {renderFooter() }
                    </Grid>
                    
                </Grid>
    
                { renderErrorDialog() }
            </React.Fragment>
        );
    }

    return render();
}

export default observer(Dashboard);
