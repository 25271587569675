// styles
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    base: {
        display: 'flex',
        width: '100%',
        minHeight: '28px',
        marginBottom: '6px',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 8px',
    },

    label: {
        // marginLeft: '16px !important',
    },

    labelText: {
        fontWeight: 'bold !important',
        fontSize: '14px !important',
    },

    value: {
        // marginRight: '16px !important',
    },

    valueText: {
        fontSize: '14px !important',
        fontWeight: 'normal !important',
        color: '#A1A1A1 !important',
    },

    spacer: ({marginRight}) => {
        return {
            marginRight: marginRight,
        };
    },
}));