import React from 'react';

import CommonImages from '../../../commons/images';

function getDefaultStructure () {
 
    return [
        {
            id: 1,
            label: 'Dashboard',
            link: '/home',
            icon: <img src={CommonImages.DASHBOARD} alt='profile' />,
        },

        {
            id: 2,
            label: 'Profile',
            link: '/profile',
            icon: <img src={CommonImages.PROFILE_DARK} alt='profile' />,
        },
        {
            id: 3,
            label: 'Subscriptions',
            link: '/subscriptions',
            icon: <img src={CommonImages.ECAL_CALENDAR_CIRCLE} alt='profile' width='24px' />,
        },
    
    ];
}

export default getDefaultStructure;
