import { v4 as uuidv4 } from 'uuid';

import {
    getDotsByCount,
    startProgressUpdater,
    stopProgressUpdater,
    preloadingMessages,
} from '../../../../commons/utils/counter';

export function startInitProgress () {
    startProgressUpdater({
        key: 'mmeInitializeProgress',
        store: this,
        messageInterval: 3, // 3 seconds message will be updated every 3 seconds
        messageUpdateCallback: updateInitProgressMessage, // A callback that will be called to update the message
        dotsUpdateCallback: updateInitProgressDots, // a callback that will be called to update the dots
    });
    this.loadProgress.init = true;
    this.observed.loadProgress = uuidv4();
}

export function stopInitProgress () {
    stopProgressUpdater('mmeInitializeProgress');
    this.loadProgress.init = false;
    this.observed.loadProgress = uuidv4();
}

/*
 * store is envStore
 */

function updateInitProgressMessage (store) {
    store.loadProgress.messageStep += 1;
    if ( store.loadProgress.messageStep >= 3) {
        store.loadProgress.messageStep = 0;
    }
    store.loadProgress.message = preloadingMessages[store.loadProgress.messageStep];
    store.loadProgress.progressLabel = store.loadProgress.message + store.loadProgress.dots;
    store.observed.loadProgress = uuidv4();
}

function updateInitProgressDots (store) {
    store.loadProgress.dotsCount += 1;
    if ( store.loadProgress.dotsCount > 3) {
        store.loadProgress.dotsCount = 0;
    }
    store.loadProgress.dots = getDotsByCount(store.loadProgress.dotsCount);
    store.loadProgress.progressLabel = store.loadProgress.message + store.loadProgress.dots;
    store.observed.loadProgress = uuidv4();
}

 