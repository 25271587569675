/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import classNames from 'classnames';

import {
  Drawer, 
  IconButton, 
  List,
} from '@mui/material';

import { 
  Close as ArrowBackIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';

import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
    openSidebar,
} from '../../../context/LayoutContext';

import CommonImages from '../../../commons/images';
import SidebarLink from '../sidebarLink';
import Link from './link';

import { useStyles } from './sidebar.styles';
 
function Sidebar (props) {

  const classes = useStyles({});
  const {
    structure,
    clickOverride,
    closeAll = false,
    shouldClose = () => {},
    scrollParent,
    onLogOut = () => {},
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [width, setWidth] = useState(window.innerWidth);
  const theme = useTheme();

  useEffect(() => {
    if (width <= 768) {
      handleSideNavToggle()
    } else if (width > 768) {
      handleSideNavToggleLargeDesktop();
    }
  }, [width]);

  const handleSideNavToggle = () => {
    if (isSidebarOpened) {
      setPermanent(false);
      toggleSidebar(layoutDispatch);
    }
  };

  const handleSideNavToggleLargeDesktop = () => {
    setPermanent(true);
    openSidebar(layoutDispatch);
  };

  const toggleDrawer = value => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (value && !isPermanent) toggleSidebar(layoutDispatch);
  };

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup () {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  }, []);

  function shouldSidebarOpen (link) {
    /*
    if (link?.open) {
      return link?.open;
    }
     return !isPermanent ? !isSidebarOpened : isSidebarOpened;
    */
    return isSidebarOpened;
  }

  function handleWindowWidthChange () {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;
    setWidth(window.innerWidth);

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      // setPermanent(true);
    }
  }

  function isOpen () {
    //return !isPermanent ? !isSidebarOpened : isSidebarOpened;
    return isSidebarOpened;
  }

  function getVariant () {
    return isPermanent ? 'permanent' : 'temporary';
    // return 'temporary'
  }

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  
  return (
    <div>
        <Drawer
          variant={getVariant()}
          className={classNames(classes.drawer, {
              [classes.drawerOpen]: !isPermanent ? !isSidebarOpened : isSidebarOpened,
              [classes.drawerClose]: !isPermanent ? isSidebarOpened : !isSidebarOpened,
          })}
  
          classes={{
              paper: classNames({
              [classes.drawerOpen]: !isPermanent
                  ? !isSidebarOpened
                  : isSidebarOpened,
              [classes.drawerClose]: !isPermanent
                  ? isSidebarOpened
                  : !isSidebarOpened,
              }),
          }}
  
          open={isOpen()}
          onClose={toggleDrawer(true)}
        >
          <div className={classes.logoSpace}>
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                <ArrowBackIcon
                    classes={{
                    root: classNames(classes.headerIcon, classes.headerIconCollapse),
                    }}
                />
                </IconButton>
            </div>

            <div
              id={`home-logo`}
              className={classes.logoContent}
              onClick={() => { navigate('/home'); }}
            >
              <img
                className={classes.logo}
                alt='MyEcal'
                src={CommonImages.MME_DARK}
              />

              <img
                className={classes.logoWord}
                alt='MyEcal'
                src={CommonImages.MME_SHORT_WORD}
              />
            </div>
          </div>
          
          <List
              className={classes.sidebarList}
              classes={{ padding: classes.padding }}
              ref={scrollParent}
          >
              {structure.map(link => (
              <SidebarLink
                  clickOverride={clickOverride}
                  key={link.id}
                  location={location}
                  isSidebarOpened={shouldSidebarOpen(link)}
                  {...link}
                  toggleDrawer={toggleDrawer(true)}
                  closeAll={closeAll}
                  shouldClose={shouldClose}
              />
              ))}
          </List>

          <div className={classes.strokeLine}>
            <img
              src={CommonImages.STROKE_LINE}
              alt='Stroke'
            />
          </div>

          <div>
            <Link
              label={'Support'}
              icon={
                <img
                  src={CommonImages.SUPPORT_LIGHT}
                  alt='Support'
                />
              }
              link={''}
              onClickLink={() => {
                console.log('Support');
                openInNewTab("https://support.ecal.com/");
              }}
            />
          </div>

          <div>
            <Link
              label={'Logout'}
              icon={<LogoutIcon />}
              link={'/logout'}
              onClickLink={() => {
                console.log('log out...');
                onLogOut();
              }}
            />
          </div>
        </Drawer>
    </div>
  );
}

export default Sidebar;
