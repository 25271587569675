
const baseURL = {
    // local: 'http://localhost:3082',
    local: 'http://192.168.1.2',
    prod: 'https://svc.ecal.com',  
    stage: 'https://stage-svc.ecal.com',  
    test: 'https://test-svc.ecal.com',
};

export default baseURL;
