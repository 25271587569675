import { v4 as uuidv4 } from 'uuid';
import EnvStore from './env';
import ErrorsStore from './errors';
import LanguageStore from './language';
import SchedulesStore from './schedules';
import SubscriptionsStore from './subscriptions';
import ProfileStore from './profile';

export { useEnvStore } from './env';

export { useLanguageStore } from './language';
export { useSchedulesStore } from './schedules';
export { useSubscriptionsStore } from './subscriptions';
export { useProfileStore } from './profile';
export { useErrorsStore } from './errors';
document.appInitPhase = 'INITIALIZING STORES AND ENV VARS';
window.env =  process.env.REACT_APP_ENV_VAR; // process.env.REACT_APP_ENV_VAR;  
window.SUBDOMAIN_API = process.env.REACT_APP_SUBDOMAIN_API;

if (window.env === 'prod') {
    console.log = () => {};
}

//Setup Environment
const uniqueTraceId = uuidv4();
const envStore = new EnvStore();
envStore.Environment = window.env;
envStore.traceId = uniqueTraceId;
window.traceId = uniqueTraceId;

// setup other stores
const errorsStore = new ErrorsStore();
 
const languageStore = new LanguageStore();
const schedulesStore = new SchedulesStore();
const subscriptionsStore = new SubscriptionsStore();
const profileStore = new ProfileStore();

// schedulesStore.subscriptionsStore = subscriptionsStore;

envStore.mountStores({
    errorsStore,
    languageStore,
    schedulesStore,
    subscriptionsStore,
    profileStore,
});

// to detach call envStore.unmountStores();

window.envStore = envStore;

const models = {
    envStore,
    errorsStore,
    languageStore,
    schedulesStore,
    subscriptionsStore,
    profileStore,
};

document.appInitPhase = 'STORE INIT COMPLETE';

export default models;
