/* eslint-disable react-hooks/exhaustive-deps */

import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';

/*
 * What is the difference between this and useSchedule?
 * useSchedule has data that will be repeated, 
 * This one will only relay those data, those data won't be reference here
 * We're only using those data to influence internal state of subscription items
 * We have this separate hook because, this hook relies on the Subscription ID
 * What we did is we DECOUPLED, schedules data state with item states
 */

function useSubscriptionItemState (props) {
    const {
        subscription,
        subscriptionScheduleData,
    } = props;

    const {
        availableSchedules,
        availableSchedulesExpanded,
        subscriptionsExpanded,
        availableScheduleProgress,
        unsubscribeProgress,
        availableSchedulesLoaded,
    } = subscriptionScheduleData;

    const [moreCardMinHeight, setMoreCardMinHeight] = useState('52px');


    useEffect(() => {
        if (availableSchedulesExpanded[subscription.id]) {
            setMoreCardMinHeight('104px');
        } else {
            setMoreCardMinHeight('52px');
        }
    }, [availableSchedulesExpanded, availableSchedulesExpanded[subscription.id] ]);

    function isAvailableScheduleExpanded () {
        if (isEmpty(availableSchedulesExpanded)) {
            return false;
        }
        return availableSchedulesExpanded[subscription.id];
    }

    function isSubscriptionsExpanded () {
        if (isEmpty(subscriptionsExpanded)) {
            return false;
        }
        return subscriptionsExpanded[subscription.id];
    }
     
    // Note on Best Practice: When conditionals reached 3 conditions, it might be good to separate it as function 
   
    function shouldRenderFallbackText () {
        if (isEmpty(availableSchedules[subscription.id])) {
            return true;
        }
        return false;
    }

    function shouldRenderLoadingText () {
        if (!isLoadedFromAPI() || isAvailableScheduleInProgress() ||
            !availableSchedules[subscription.id] ||
            availableSchedules[subscription.id] === undefined 
            ) {
            return true;
        }
        return false;
    }

    function isUnsubscribeInProgress () {
        if (unsubscribeProgress[subscription.id]) {
            return true;
        }
        return false;
    }

    function isAvailableScheduleInProgress () {
        if (availableScheduleProgress[subscription.id]) {
            return true;
        }
        return false;
    }

    function isLoadedFromAPI () {
        if (availableSchedulesLoaded[subscription.id]) {
            return true;
        }
        return false;
    }

    return  {
        shouldRenderFallbackText,
        shouldRenderLoadingText,
        isAvailableScheduleExpanded,
        isSubscriptionsExpanded,
        isUnsubscribeInProgress,
        isAvailableScheduleInProgress,
        moreCardMinHeight,
    }
}

export default useSubscriptionItemState;