import React from "react";
import PropTypes from "prop-types";
import isEmpty from 'lodash/isEmpty';
import { Typography } from "../wrappers";
import { useStyles } from "./profileThumbnail.styles";

import { Grid, Avatar, Badge, IconButton } from "@mui/material";
import {
    BorderColorOutlined as EditIcon,
} from '@mui/icons-material';

import CommonImages from '../../../commons/images';

function stringAvatar(name) {
    const firstNameInitials = name.split(' ')[0][0] ? name.split(' ')[0][0] : '';
    const lastNameInitials = name.split(' ')[1][0] ? name.split(' ')[1][0] : '';

    return {
      children: `${firstNameInitials}${lastNameInitials}`,
    };
  }

function ProfileThumbnail(props) {
    const { 
        profileInfo,
        updatable,
    } = props;

  const classes = useStyles();

  function renderProfilePicture () {

    if (profileInfo.pictureURL) {
        return (
            <Avatar
                alt="Profile Picture"
                src={profileInfo.pictureURL}
                className={classes.avatarPicture}
            />
        )
    }

    if (isEmpty(profileInfo.firstName) &&
        isEmpty(profileInfo.lastName)) {
        return (
            <Avatar
                alt="ECAL Logo"
                src={CommonImages.MME_DARK}
                className={classes.avatarPicture}
            />
        )
    }

    const name = profileInfo.firstName + ' ' + profileInfo.lastName;

    return (
        <Avatar
            className={classes.avatarNameInitial}
            {...stringAvatar(name)} 
        />
    );
  }

  return (
    <Grid className={classes.base}>
        <Grid className={classes.profileInfoContainer}>
            <Grid>
                <div className={classes.profileInfoPicture}> 
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        invisible={updatable}
                        badgeContent={
                            <IconButton className={classes.editIcon}>
                                <EditIcon />
                            </IconButton>
                        }
                    >
                        { renderProfilePicture() }
                    </Badge>
                </div>
            </Grid>

            <div>
                <Typography variant='h5' weight='medium' color='primary' size='large' align='center'>
                    {profileInfo.firstName + ' ' + profileInfo.lastName}
                </Typography>
            </div>

            <div>
                <Typography variant='body1' color='primary' align='center'>
                    {profileInfo.email}
                </Typography>
            </div>

        </Grid>
    </Grid>
  );
}

ProfileThumbnail.propTypes = {
  profileInfo: PropTypes.object,
  updatable: PropTypes.bool,
};

ProfileThumbnail.defaultProps = {
  profileInfo: null,
  updatable: false,
};

export default ProfileThumbnail;
