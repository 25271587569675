
import { makeStyles } from '@mui/styles';

const styles = (theme) => ({

    container: {
      marginTop: '64px',
      height: '100vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'column !important',
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflow: 'auto',

      [theme.breakpoints.down('sm')]: {
        width: 'calc(100%  - 32px)',
      },
      
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#FFFFFF',
      width: '100%',
      height: '40px',
    },

    fields: {
      display: 'flex',
      flexDirection: 'column !important',
      marginTop: '24px',
      width: 'calc(100% - 24px)',
      paddingBottom: '32px',
    },

    devices: {
      display: 'flex',
      flexDirection: 'column !important',
      alignItems: 'center',
      width: 'calc(100%)',
    },

    
    rowSpace: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '16px',
    },

    field: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      marginTop: '16px',
      marginBottom: '16px',
    },

    textInput: {
      width: '100%',
     
    },

    message: {
      '&.MuiTypography-root':{
        fontSize: '12px',

        [theme.breakpoints.up('sm')]: {
          fontSize: '14px',
        },
      } 
    },

    instruction: {
      '&.MuiTypography-root':{
        fontSize: '12px',
        marginTop: '16px',

        [theme.breakpoints.up('sm')]: {
          fontSize: '14px',
        },
      },
    },

});

export default styles;
export const useStyles = makeStyles(styles);
