/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
    Grid,
    Avatar,
} from '@mui/material';

import {
    SupervisedUserCircle as DefaultIcon,
} from '@mui/icons-material';

import Title from './subscriptionTitle';
import ScheduleItem from './scheduleItem';
import UnsubscribedButton from './UnsubscribedButton';
import DeviceInfo from './deviceInfo';
import {
    updateSchedulesData,
    getAttributeId,
} from './util';

import { useStyles } from './subscriptionItem.styles';
import useSubscriptionItemState from './useSubscriptionItemState';

function SubscriptionItem (props) {
    const {
        subscription,
        marginTop,
        showUnsubscribe,
        // openAddScheduleDialog,
        openRemoveScheduleDialog,
        // openDeleteSuccessDialog,
        openUnsubscribeDialog,
        subscriptionScheduleData,
    } = props;

    const {
        loadMoreSchedulesMessage,
        loadUnsubscribeMessage,
        fetchAvailableSchedules,
        expandCollapseAvailableSchedule,
        expandCollapseSubscription,
        availableSchedules,
    } = subscriptionScheduleData;

    // Best Practice: When functions and conditionals grew more than 3 use a separate hook to manage the states
    const {
        shouldRenderFallbackText,
        shouldRenderLoadingText,
        isAvailableScheduleExpanded,
        isSubscriptionsExpanded,
        isUnsubscribeInProgress,
        moreCardMinHeight,
    }  = useSubscriptionItemState ({
        subscription,
        subscriptionScheduleData,
    });

    const classes = useStyles({
        moreCardMinHeight,
        expand: isSubscriptionsExpanded(),
        marginTop,
        isMoreCardExpanded: isAvailableScheduleExpanded(),
    });
     
    function renderAvailableSchedules () {
        if (!isAvailableScheduleExpanded()) {
            return (<React.Fragment></React.Fragment>);
        }

        if (shouldRenderLoadingText()) {
            return (<React.Fragment>
                <Grid className={classes.loadingText}>{ loadMoreSchedulesMessage }</Grid>
            </React.Fragment>);
        }

        if (shouldRenderFallbackText()) {
            return (<React.Fragment>
                <span className={classes.noScheduleText}>No more schedules available</span>
            </React.Fragment>);
        }

        return renderExpandedAvailableSchedules();
    }

    function renderExpandedAvailableSchedules () {
        const schedules = updateSchedulesData(availableSchedules[subscription.id]);

        return (<Grid className={classes.availableSchedules}>
            {
                schedules.map((schedule, index) => {
                    return (<ScheduleItem
                        key={`active-schedule-${index}`}
                        schedule={schedule}
                        subscription={subscription}
                        toggled={true}
                        showAddButton={true}
                        // openAddScheduleDialog={openAddScheduleDialog}
                    />);
                })
            }
        </Grid>);
    }

    function renderMore () {
        if (!showUnsubscribe || !isSubscriptionsExpanded()) {
            return (<React.Fragment> </React.Fragment>);
        }
    
        return (
            <Grid
                id={`more-card-${getAttributeId(subscription.publisher?.name)}`}
                className={classes.moreCardBase}
            >
                <Title
                    label={`More from ${subscription.publisher?.name}`}
                    icon={null}
                    expand={isAvailableScheduleExpanded()}
                    onExpand={(newExpandValue) => {

                        expandCollapseAvailableSchedule(subscription.id);
                        if (newExpandValue) {
                            fetchAvailableSchedules(subscription?.id);
                        }
                    }}
                />
                { renderAvailableSchedules() }
            </Grid>
        );
    }

    function renderIcon () {
        if (subscription.publisher && subscription.publisher.logo) {
            return ( <Avatar
                alt="Subscription Logo"
                src={subscription.publisher.logo}
                className={classes.avatar}
            />);
        }
        return (
            <DefaultIcon />
        );
    }
 
    function renderSchedules () {
        if (!isSubscriptionsExpanded() || subscription.subscribedSchedules.length <= 0) {
            return (<React.Fragment></React.Fragment>);
        }

        return (<Grid className={classes.subscribedSchedules}>
            {
                subscription.subscribedSchedules.map((schedule, index) => {
                    return (<ScheduleItem
                        key={`schedule-${index}`}
                        schedule={schedule}
                        subscription={subscription}
                        toggleDisabled={false}
                        checked={true}
                        openRemoveScheduleDialog={openRemoveScheduleDialog}
                        // openDeleteSuccessDialog={openDeleteSuccessDialog}
                    />);
                })
            }
        </Grid>);
    }

    return (
        <React.Fragment>
            <Grid
                id={`subscription-${getAttributeId(subscription.publisher?.name)}`}
                className={classes.base}
            >
                <Title
                    label={`${subscription.publisher?.name}`}
                    icon={renderIcon()}
                    marginRight={'20px'}
                    expand={isSubscriptionsExpanded()}
                    onExpand={(newExpandValue) => {
                        expandCollapseSubscription(subscription.id);
                    }}
                />

                { subscription.isActive && renderSchedules() }
                { subscription.isActive && renderMore() }

                <DeviceInfo
                    showTitle={true}
                    showUnsubscribe={true}
                    expand={isSubscriptionsExpanded()}
                    marginTop={'0px'}
                    deviceData={subscription.device}
                    marginBottom={'16px'}
                />

                { subscription.isActive && <UnsubscribedButton
                    showUnsubscribe={showUnsubscribe}
                    expand={isSubscriptionsExpanded()}
                    marginTop={marginTop}
                    onUnsubcribe={() => { openUnsubscribeDialog(subscription); }}
                    subscription={subscription}
                    isLoadingUnsubscribe={isUnsubscribeInProgress()}
                    loadUnsubscribeMessage={loadUnsubscribeMessage}
                /> }
 
            </Grid> 
          
        </React.Fragment>
    );
}

SubscriptionItem.propTypes = {
    subscription: PropTypes.any,
    marginTop: PropTypes.any,
    showUnsubscribe: PropTypes.bool,
    onUnsubscribe: PropTypes.func,
    // openAddScheduleDialog: PropTypes.func,
    openRemoveScheduleDialog: PropTypes.func,
    // openDeleteSuccessDialog: PropTypes.func,
    openUnsubscribeDialog: PropTypes.func,
    subscriptionScheduleData: PropTypes.any,
};

SubscriptionItem.defaultProps = {
    subscription: {},
    marginTop: 0,
    showUnsubscribe: false,
    onUnsubscribe: () => {},
    // openAddScheduleDialog: () => {},
    openRemoveScheduleDialog: () => {},
    // openDeleteSuccessDialog: () => {},
    openUnsubscribeDialog: () => {},
    subscriptionScheduleData: {},
};

export default observer(SubscriptionItem);
