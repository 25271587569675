import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({

  body: ({ bgColor, fontColor }) => {

    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      backgroundColor: bgColor || '#0c0644',
      color: fontColor || theme.palette.primary.contrastText,
      width: '100%',
      padding: '20px 0',
      position: 'relative',
      // bottom: '0px',
      zIndex: 1201,
    };
  },
}));
