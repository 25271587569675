/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';

import {
    Grid,
    Avatar,
} from "@mui/material";

import {
    Typography,
    Chip,
} from "../wrappers";

import CommonImages from "../../../commons/images";
import { useStyles } from "./subscriptionThumbnail.styles";
import { useSubscriptionsStore } from '../../../store/models/subscriptions';

const DEFAULT_LOGO = 'https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg';

function SubscriptionThumbnail(props) {
    const {  
        showManage,
        showSync,
        transparentBox,
        subscription,
    } = props;

  const classes = useStyles({ transparentBox });
  const navigate = useNavigate();
  const subscriptionsStore = useSubscriptionsStore();

  function renderLogo () {
    if (!subscription?.publisher?.logo) {
        return DEFAULT_LOGO;
    }
    return subscription?.publisher?.logo;
  }

  function renderName () {
    if (!subscription?.publisher?.name) {
        return 'Publisher';
    } 
    return subscription?.publisher?.name;
  }

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  function renderButton() {
    if(showManage) {
        return (
            <Grid className={classes.subscriptionButtonContainer}> 
                <Chip
                    label="Manage"
                    className={classes.manageChip}
                    onClick={()=> {
                        subscriptionsStore.expandSubscriptionCard(subscription.id);
                        navigate('/subscriptions');
                    }}
                />
            </Grid>
        )
    }

    if(showSync) {
        return (
            <Grid className={classes.syncButton}>
                <Chip
                    className={classes.calendarChip}
                    icon={
                        <img
                            alt='Sync Calendard' 
                            src={CommonImages.SYNC_BUTTON_ICON}
                            className={classes.calendarIcon}
                            />
                    }
                    label="Sync"
                    onClick={()=> {
                        openInNewTab(subscription.publisher.directLink);
                    }}
                />
            </Grid>
        );
    }
    return (<React.Fragment></React.Fragment>);
  }

  return (
        <Grid className={classes.base} >
        
            <Grid className={classes.container}>
                <Grid className={classes.subscriptionLogoContainer} >
                    <Avatar
                        alt="Subscription Logo"
                        src={renderLogo()}
                        className={classes.avatar}
                    />
                </Grid>
                <Grid className={classes.subscriptionNameContainer}>
                    <Typography variant="body2"
                        className={classes.nameText}
                    >
                       { renderName() }
                    </Typography>
                </Grid>
                { renderButton() }
            </Grid>
            
        </Grid>
  );
}

SubscriptionThumbnail.propTypes = {
    showManage: PropTypes.bool,
    showSync: PropTypes.bool,
    transparentBox: PropTypes.bool,
    subscription: PropTypes.object,
};

SubscriptionThumbnail.defaultProps = {
    showManage: true,
    showSync: true,
    transparentBox: false,
    subscription: null,
};

export default SubscriptionThumbnail;
