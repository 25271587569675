/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import DeleteFace from '../components/dialog/contents/deleteFace';
import SuccessFace from '../components/dialog/contents/successFace';
import CommonImages from '../../commons/images';

import useConfirmationDialog from '../components/dialog/useConfirmationDialog';

import {
    useSchedulesStore,
    useSubscriptionsStore,
} from '../../store/models/index';

const deleteSuccessMessage = {
    message: 'Unsubscribed',
    subMessage: 'You have successfully unsubscribed from the calendar.',
    showConfirmButton: false,
    cancelLabel: 'Close',
};
 
function useSubscriptionsDialogs () {

    const schedulesStore = useSchedulesStore();
    const subscriptionsStore = useSubscriptionsStore();

    const [success, setSuccess] = useState({
       ...deleteSuccessMessage,
    });
    const [subscription, setSubscription] = useState({});
    const [schedule, setSchedule] = useState({});

    // Unsubscribe a publisher - Are you sure?
    const {
        renderDialog: renderUnsubscribeConfirm,
        openDialog: openUnsubscribeConfirm,
    } = useConfirmationDialog({
        dialogContentRenderer: () => {
            return (<DeleteFace 
                message={'Are you sure?'}
                subMessage={`This will remove ${subscription?.publisher.name} from your calendar.`}
            />);
        },
        
        onConfirm: () => {
            openUnsubscribeConfirm({ open: false });
            
            // use delete success
            setSuccess({
                ...deleteSuccessMessage,
                subMessage: `You have successfully unsubscribed from ${subscription?.publisher.name}.`
            });

            subscriptionsStore.unsubscribe(subscription); 
        },
        
        onClose: () => {
            openUnsubscribeConfirm({ open: false });
        },
    });

    // Unsubscribe a publisher - Unsubscribed
    const {
        renderDialog: renderUnsubscribeSuccess,
        openDialog: openUnsubscribeSuccess,
    } = useConfirmationDialog({
        cancelLabel: success.cancelLabel,
        showConfirmButton: success.showConfirmButton,
        
        showRefreshMessage: true,
        dialogContentRenderer: () => {
            return (<SuccessFace 
                message={success.message}
                subMessage={success.subMessage}
            />);
        },
 
        onClose: () => {
            openUnsubscribeSuccess({ open: false });
            subscriptionsStore.removeItemFromUnsubscribeList();
        },
    });
 
    // Add a schedule
    const {
        renderDialog: renderAddSuccess,
        openDialog: openAddSuccess,
    } = useConfirmationDialog({
        confirmLabel: 'OK',
        showCancelButton: false,
        showWaitMessage: true,

        dialogContentRenderer: () => {
            return (<SuccessFace 
                message={'Schedule Added'}
                subMessage={'Events will now appear in your calendar.'}
                messageIcon={<img
                    width= '58px'
                    marginbottom= '10px'
                    alt='ecal-logo'
                    src={CommonImages.ECAL_E_LOGO}
                />}
            />);
        },

        onConfirm: () => {
            openAddSuccess({ open: false});
            schedulesStore.removeItemFromAddlist();
        },

    });

    // Remove a schedule - Are you sure?
    const {
        renderDialog: renderRemoveScheduleConfirm,
        openDialog: openRemoveScheduleConfirm,
    } = useConfirmationDialog({
        dialogContentRenderer: () => {
            return (<DeleteFace 
                message={'Are you sure?'}
                subMessage={`This will remove ${schedule?.scheduleName} from your calendar.`}
            />);
        },

        onConfirm: () => {
            openRemoveScheduleConfirm({ open: false });

            // pass the delete shcedule to deleteList in schedules store
            schedulesStore.deleteSchedule(subscription, schedule);
        },

        onClose: () => {
            openRemoveScheduleConfirm({ open: false });
            
            // pass the cancel shcedule to cancelList in schedules store
            schedulesStore.cancelScehdule(schedule)
        },
    });

    // Remove a schedule - Removed
    const {
        renderDialog: renderDeleteSuccess,
        openDialog: openDeleteSuccess,
    } = useConfirmationDialog({
        cancelLabel: 'Close',
        showConfirmButton: false,
        showWaitMessage: true,

        dialogContentRenderer: () => {
            return (<SuccessFace 
                message={'Removed'}
                subMessage={`You have successfully removed ${schedule?.scheduleName} from your calendar.`}
            />);
        },

        // onConfirm: () => {
        //     openDeleteSuccess({ open: false});
        //     schedulesStore.removeItemFromDeletelist();
        // },
 
        onClose: () => {
            openDeleteSuccess({ open: false });
            schedulesStore.removeItemFromDeletelist();
        },
    });
 
    useEffect(() => {
        openUnsubscribeConfirm({ open: false });
        openUnsubscribeSuccess({ open: false });
        openAddSuccess({ open: false });
        openRemoveScheduleConfirm({ open: false });
        openDeleteSuccess({ open: false });
    }, []);

    function renderDialogs () {
        return (<React.Fragment>
            { renderUnsubscribeConfirm() }
            { renderUnsubscribeSuccess() }
            { renderAddSuccess() }
            { renderRemoveScheduleConfirm() }
            { renderDeleteSuccess() }
        </React.Fragment>);
    }

    function openAddScheduleDialog () {
        openAddSuccess({ open: true });
    }

    function openRemoveScheduleDialog (subscription, schedule) {
        setSubscription(subscription);
        setSchedule(schedule);
        openRemoveScheduleConfirm({ open: true });
    }

    function openDeleteSuccessDialog (schedule) {
        setSchedule(schedule);
        openDeleteSuccess({ open: true });
    }

    function openUnsubscribeDialog (subscription) {
        setSubscription(subscription);
        openUnsubscribeConfirm({ open: true });
    }

    function openUnsubscribeSuccessDialog (subscription) {
        setSubscription(subscription);
        openUnsubscribeSuccess({ open: true });
    }

    return {
        renderDialogs,
        openAddScheduleDialog,
        openUnsubscribeDialog,
        openRemoveScheduleDialog,
        openDeleteSuccessDialog,
        openUnsubscribeSuccessDialog,
    };
}

export default useSubscriptionsDialogs;
