import {
    getTokenPayload,
} from '../../../../commons/utils/token';

import {
    setAuthSession,
    getAuthSession,
} from './api';
 

export const OAuthSessionStates = {
    NO_TOKEN_AVAILABLE: 'NO_TOKEN_AVAILABLE',
    FROM_CALENDAR_WITH_NO_TOKEN: 'FROM_CALENDAR_WITH_NO_TOKEN',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    OAUTH_SESSION_SUCCESS: 'SESSION_SUCCESS',
};

// Select session state based from params and token availability
function selectSessionAppState (props) {
    const {
        params,
        hasTokenIssue,
        authSession,
    } = props;


    if (!params.token && !authSession.token) {
        return OAuthSessionStates.NO_TOKEN_AVAILABLE;
    }

    // When query params has 'type' (google / outlook ) props and has Token Issue this will redirect to login later.
    if (isOauth(params) && hasTokenIssue) {
        return OAuthSessionStates.FROM_CALENDAR_WITH_NO_TOKEN;
    }

    if (hasTokenIssue) {
        return OAuthSessionStates.TOKEN_EXPIRED;
    }
  
    return  OAuthSessionStates.OAUTH_SESSION_SUCCESS;
}

async function isSessionOK () {
    const params = this.page.params;
    const authSession = getAuthSession();
    const token = params.token || authSession.token;     
    const hasTokenIssue = await this.tokenExpired(token);
    if (hasTokenIssue) {
        this.stateRedirect ({
            state: OAuthSessionStates.TOKEN_EXPIRED,
            authSession,
            params,
        });
        return false;
    }
    return true;
}

function isOauth (params) {
    return (params.type === 'google'|| params.type === 'outlook' || params.type );
}

function persistOauthInfo ({ session, params }) {
    setAuthSession({
        ...session,
        type: params.type,
        sId: params.sId,
        eId: params.eId,
        uId: params.uId,
    });

}

function persistSession (params) {
    // console.log('env.PerssitSession() -> ', params);
    const token = params.token;
    const payload = getTokenPayload(token);
    const newSession = {
        token,
        payload,
    };
    setAuthSession(newSession);
    return newSession;
}

function persistToken (props) {
    const {
        params,
    } = props;
    if (params.token) {
        return this.persistSession(params);
    }
    return null;
}


const actions = {
    selectSessionAppState,
    persistOauthInfo,
    isOauth,
    persistSession,
    persistToken,
    isSessionOK,
}
export default actions;
