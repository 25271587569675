import React from 'react';
import classNames from 'classnames';

import useStyles from './coloredDots.styles';

export function ColoredDot (props) {
    const {
        onClick,
        withRightMargin = true,
        color = 'orange',
        size = 'medium',
    } = props;
 
    const classes = useStyles({
        color,
        size,
    });

    const rightMargin = withRightMargin ? classes.dotMargin : null;
   
    return (
        <div
            className={
                classNames([classes.dot , rightMargin])
            }
            onClick={onClick}>
        </div>
    );
}
