
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';


import {
    FormControlLabel,
    Checkbox as MUICheckbox,
} from '@mui/material';

import {
    CheckCircle,
    RadioButtonUnchecked,
} from '@mui/icons-material';

import { useStyles } from './checkbox.styles';

import { Typography } from '../wrappers';

function Checkbox (props) {

    const {
        label,
        ...others
    } = props;
    
    const classes = useStyles();

    return (
        <FormControlLabel
            className={classes.checkGroup}

            control={<MUICheckbox
                className={classes.checkbox}
                checkedIcon={<CheckCircle />}
                icon={<RadioButtonUnchecked />}
                {...others}
            />}

            label={<Typography
                className={classes.checklabel}
            >
                {label}
            </Typography>}
        />
    );
}

Checkbox.propTypes = {
    label: PropTypes.string,
};

Checkbox.defaultProps = {
    label: 'Checkbox',
};

export default Checkbox;