/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Tooltip,
} from '@mui/material';

import {
  Info as InfoIcon,
} from '@mui/icons-material';

import { Typography } from '../wrappers';
import Button from '../button';

import useStyles from './card.styles';

const TOOLTIP_TEXT = {
    active: 'Your calendar is actively connected to these Publishers',
    inactive: 'Your calendar is no longer connected to these Publishers',
}

function Card (props) {
    const {
        id,
        children,
        icon,
        title,
        onClickTitle,
        marginTop,
        marginBottom,
        backgroundColor,
        minContentHeight,
        showHeaderButton,
        buttonHeaderTitle,
        onButtonHeaderClick,
        shouldRenderHeader,
        renderHeaderOnly,
        headerButtonDisabled,
    } = props;
   
    const classes = useStyles({
        marginTop,
        backgroundColor,
        minContentHeight,
        marginBottom,
        renderHeaderOnly,
        onClickTitle,
    });

    function renderIconHeader () {
        if (icon) {
            return (<Grid className={classes.icon}>
                { icon }
            </Grid>)
        }
        return (<React.Fragment></React.Fragment>);
    }

    function renderHeaderButton () {
        if (!showHeaderButton) {
            return <React.Fragment></React.Fragment>
        }

        return (<Button
            disabled={headerButtonDisabled}
            variant="contained"
            sizeVariant={'small'}
            // color={'#0C0644'}
            onClick={() => onButtonHeaderClick()}
        >
            { buttonHeaderTitle }
        </Button>)
    }

    function renderHeaderTooltipInfo () {
        return (
            <Tooltip
                title={`${title === 'Active' ? TOOLTIP_TEXT.active : TOOLTIP_TEXT.inactive}`}
                placement="left"
                classes={{
                    tooltip: classes.headerTooltipStyle,
                }}

                enterTouchDelay={0}
            >
                <InfoIcon color='primary' style={{ fontSize: 18 }}/>
            </Tooltip>
        );
    }

    function renderHeaderRightSection () {
        if (title === 'Active' || title === 'Inactive') {
            return renderHeaderTooltipInfo();
        }

        if (showHeaderButton) {
            return renderHeaderButton();
        }

        return <React.Fragment></React.Fragment>
    }

    function renderCardHeader () {
        if (!shouldRenderHeader) {
            return (<React.Fragment></React.Fragment>);
        }

        return (
            <Grid
                className={classes.header}
            >
                <Grid
                    className={classes.leftSection}
                    onClick={() => {
                        if (typeof onClickTitle === 'function') {
                            onClickTitle();
                        }
                    }}
                >
                    { renderIconHeader() }
                    <Typography
                        className={classes.title}
                    >
                        { title }
                    </Typography>

                </Grid>
 
                <Grid className={classes.rightSection}>
                    { renderHeaderRightSection() }
                </Grid>
            </Grid>
        );
    }

    function renderContent () {
        if (renderHeaderOnly) {
            return (<React.Fragment></React.Fragment>);
        }
        return (
            <Grid
                className={classes.contentBase}
            >
                <Grid
                    className={classes.content}
                >
                    { children }
                </Grid>
            </Grid>
        );
    }

    return (
       <Grid
            id={id}
            className={classes.base}
       >
        {renderCardHeader()}
        {renderContent()}
       </Grid>
    );
}

Card.propTypes = {
    open: PropTypes.bool,
    headerIcon: PropTypes.any,
    title: PropTypes.string,
    icon: PropTypes.any,
    onClickTitle: PropTypes.any,
    marginTop: PropTypes.any,
    marginBottom: PropTypes.any,
    minContentHeight: PropTypes.any,
    showHeaderButton: PropTypes.bool,
    buttonHeaderTitle: PropTypes.string,
    onButtonHeaderClick: PropTypes.func,
    shouldRenderHeader: PropTypes.bool,
    renderHeaderOnly: PropTypes.bool,
    headerButtonDisabled: PropTypes.bool,
};

Card.defaultProps = {
    open: false,
    headerIcon: null,
    title: 'Card',
    icon: null,
    onClickTitle: null,
    marginTop: 0,
    marginBottom: 0,
    minContentHeight: '',
    showHeaderButton: false,
    buttonHeaderTitle: 'Manage',
    onButtonHeaderClick: () => {},
    shouldRenderHeader: true,
    renderHeaderOnly: false,
    headerButtonDisabled: false,
};

export default Card;

 