import { makeStyles } from '@mui/styles';

const colors = {
    'orange': '#DF7B39',
    'yellow': '#EFBF41',
    'green': '#86B941',
    'cyan': '#54BBD3',
    'blue': '#49A5DC',
};

const sizes = {
    small: {
        width: 5,
        height: 5,
    },

    medium: {
        width: 8,
        height: 8,
    },
    
    large: {
        width: 8,
        height: 8,
    }
}
export default makeStyles(theme => ({
 

    dot: ({color, size }) => {
         
        function getSize () {
            if (sizes[size]) {
                return sizes[size];
            }
            return sizes.small;
        }
    
        function getColor () {
            if (colors[color]) {
                return colors[color];
            }
            return colors.orange;
        }

        return {
            ...getSize(),
            fontSize: '10px',
            backgroundColor: getColor(),
            borderRadius: '50%',
            transition: theme.transitions.create('background-color'),
            cursor: 'pointer',
        };
    },

    dotMargin: {
        marginRight: '20px',
    },
    
}));
