
import { makeStyles } from '@mui/styles';
import background from '../../commons/images/page.bg.png'

const styles = (theme) => ({

    container: {
      display: 'flex',
      height: '100vh',
      width: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
     
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'absolute',
      top: 0,
      left: 0,
      
    },

    outletParent: ({ showContent }) => {

      function bgImage () {
        if (showContent) {
          return`url(${background})`;
        }
        return `url(${background})`;
      }

      function getWidth () {
        if (showContent) {
          return 'calc(100% - 240px)';
        }
        return '100%';
      }

      return {
        display: 'flex',
        flexDirection: 'column !important',
        width: getWidth(),
  
        minHeight: '100vh',
  
        alignItems: 'center',
        justifyContent: 'center',
  
        backgroundImage: bgImage(),
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 640px',
  
        backgroundColor: '#E1E1E1',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
  
      };
    }, 

});

export default styles;
export const useStyles = makeStyles(styles);
