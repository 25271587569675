import {
    isExpired,
} from '../../../../commons/utils/token';

 
import {
    getAuthSession,
} from './api';

async function initDashboard () {
    console.log('EnvStore.initDashboard()');
    const params = this.page.params;
    let authSession = getAuthSession();
    
    console.log('EnvStore.initDashboard QUERY-PARAMS -> ', params);
    console.log('EnvStore.initDashboard() authSession: ', authSession);

    // get token from  the persisted Auth Settings or check the params...
    const token = params.token || authSession.token;     
    const hasTokenIssue = await this.tokenExpired(token);
    
    const state = this.selectSessionAppState({
        params, 
        hasTokenIssue,
        authSession,
    });
   
    document.appInitPhase = 'Token Processed ' + token;
    // console.log('initDashboard() AppState -> ', state);
    this.stateRedirect({
        state,
        params,
        authSession,
    });    
}

export async function tokenExpired (token) {
    if (!token) {
        return true;
    }
    return await isExpired(token);
}

export default initDashboard;
