import { v4 as uuidv4 } from 'uuid';
import packageInfo from '../../../../../package.json';

async function getRecommendations () {
    const version = packageInfo.version;

    const fetchData = async () => {
        try {
          const response = await fetch(`/defaultRecommendations.json?v=${version}`);
    
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
    
          const data = await response.json();
          this.recommendations = data.data;
          console.log('getDefaultRecommendations', this.recommendations);
          this.observed.recommendations = uuidv4();
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
    fetchData();
}

export default getRecommendations;
