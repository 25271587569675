import axios from 'axios';

export function upload(
    endpoint,
    formData,
    options = { token: '', traceId: '', headers: {}, action: '' }
) {
    const { token, headers, traceId } = options;

    async function execRequest(resolve, reject) {
        
        const reqHeaders = {
            'content-type': 'multipart/form-data',
            authorization: 'Bearer ' + token,
            'x-trace-id': traceId,
            ...headers,
        };

        axios
            .post(endpoint, formData, {
                headers: reqHeaders,
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                resolve(error);
            });
    }

    return new Promise(execRequest);
}

export default upload;
