
// import coverPhotos from './ecal-cover-photo.jpg';
/*
import googleLogo from './google.svg';
import outlookLogo from './outlook.svg';

import ecalLogo from './ecal-logo.svg';
import myEcalLogo from './myEcal.logo.png';

import MME_DARK from './myecal/MME-Colour1.png';
import MME_LIGHT from './myecal/MME-Colour2.png';
import MME_LONG_WORD from './myecal/MME-Manage-My-ECAL-word.png';
import MME_SHORT_WORD from './myecal/MME-My-ECAL-word.png';

import alertCircle from './alert_circle.svg';
import confirm from './confirm.svg';
import devices from './devices.svg';
import ecalCalendarDark from './ecal-calendar-dark.svg';
import ecalCalendarLight from './ecal-calendar-light.svg';
import ecalELogo from './ECAL-elogo.png';
import editProfile from './edit.svg';
import dataPreferenceDark from './gear-dark.svg';
import dataPreferenceLight from './gear-light.svg';
import myEcalLogoVector from './MyECAL-logo.svg';
import myEcalLogoVectorLight from './MyECAL-logo-light.svg';


import myProfile from './my-profile.svg';
import mySubscriptions from './my-subscriptions.svg';
import personalDetails from './personal-details.svg';
import personalDetailsLight from './personal-details-light.svg';
import successCheck from './success-check.svg';
import supportLight from './support-light.svg';
import strokeLine from './stroke-line.svg';


import sliderDark from './slider-dark.svg';
import sliderLight from './slider-light.svg';
import profileDark from './profile-filled-dark.svg';
import profileLight from './profile-filled-white.svg';
import dashboard from './dashboard.svg';
import ecalCalendarCircle  from './ecal-calendar-circle.svg';

import ecalSyncButtonIcon  from './MME-Sync-button-icon.svg';
*/

const CommonImages = {
    ECAL_PRELOADER: '/images/ecal-loading.svg',
    COVER_PHOTO: '/images/ecal-cover-photo.jpg',
    GOOGLE_LOGO: '/images/google.svg',
    OUTLOOK_LOGO: '/images/outlook.svg',
    ECAL_LOGO: '/images/ecal-logo.svg',
    MY_ECAL_LOGO: '/images/myEcal.logo.png',
    ALERT_CIRCLE:  '/images/alert_circle.svg',
    CONFIRM: '/images/confirm.svg',
    DEVICES: '/images/devices.svg',
    ECAL_CALENDAR_DARK: '/images/ecal-calendar-dark.svg',  // ecalCalendarDark,
    ECAL_CALENDAR_LIGHT: '/images/ecal-calendar-light.svg', // ecalCalendarLight,
    ECAL_CALENDAR_CIRCLE:  '/images/ecal-calendar-circle.svg', // ecalCalendarCircle,
    ECAL_E_LOGO: '/images/ECAL-elogo.png', // ecalELogo,
    EDIT_PROFILE: '/images/edit.svg', // editProfile,
    
    DATA_PREFERENCE_DARK: '/images/gear-dark.svg', // dataPreferenceDark,
    DATA_PREFERENCE_LIGHT: '/images/gear-light.svg', // dataPreferenceLight,
    MY_ECAL_LOGO_VECTOR: '/images/MyECAL-logo.svg', // myEcalLogoVector,
    MY_ECAL_LOGO_VECTOR_LIGHT: '/images/MyECAL-logo-light.svg', // myEcalLogoVectorLight,
    MY_PROFILE: '/images/my-profile.svg', // myProfile,
    MY_SUBSCRIPTIONS: '/images/my-subscriptions.svg', // mySubscriptions,
    PERSONAL_DETAILS: '/images/personal-details.svg', // personalDetails,
    PERSONAL_DETAILS_LIGHT: '/images/personal-details-light.svg', // personalDetailsLight,
    SUCCESS_CHECK: '/images/success-check.svg', // successCheck
    SUPPORT_LIGHT: '/images/support-light.svg', // supportLight, 
    STROKE_LINE: '/images/stroke-line.svg', // strokeLine,

    DASHBOARD: '/images/dashboard.svg', // dashboard,
    PROFILE_DARK: '/images/profile-filled-dark.svg', // profileDark,
    PROFILE_LIGHT: '/images/profile-filled-white.svg', // profileLight,
    SLIDER_DARK: '/images/slider-dark.svg', // sliderDark,
    SLIDER_LIGHT: '/images/slider-light.svg', // sliderLight,
    SYNC_BUTTON_ICON: '/images/MME-Sync-button-icon.svg', // ecalSyncButtonIcon,

    MME_DARK: '/images/myecal/MME-Colour1.png',
    MME_LIGHT: '/images/myecal/MME-Colour2.png',
    MME_LONG_WORD: '/images/myecal/MME-Manage-My-ECAL-word.png',
    MME_SHORT_WORD: '/images/myecal/MME-My-ECAL-word.png',
};

export default CommonImages;