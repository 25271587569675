import auth from './auth';
import getProfile from './getProfile';
import getDefaultProfile from './getDefaultProfile';
import updateDevices from './updateDevices';
export const actions = {
    auth,
    getProfile,
    getDefaultProfile,
    updateDevices,
};

export default actions;
