import { v4 as uuidv4 } from 'uuid';
// import isEmpty from 'lodash/isEmpty';
import {
    addScheduleAPI,
} from './api';


import { getStore } from '../../factory';

export async function addSchedule (subscription, schedule) {
    const session = this.env.session;
    const traceId = this.env.traceId;

    this.env.startLoadCounter({ target: 'appProgress' });
    
    this.loadingList[schedule.scheduleId] = true;
    this.observed.loadingList = uuidv4();

    const bodyObj = {
        'calendarIds': [schedule.scheduleId],
    }

    const res = await addScheduleAPI ({
        body: { ...bodyObj},
        store: this,
        token: session.token,
        traceId,
        subscriptionId: subscription.id,
    });

    this.env.stopLoadCounter();

    delete this.loadingList[schedule.scheduleId];
    this.observed.loadingList = uuidv4();

    if (res?.error) {
        return;
    };

    const addedObj = {
        schedule: schedule,
        subscription: subscription,
    }
   
    // this context is inside the ScheduleStore, schedule Store doesn't have a reference to subscription store.
    const subscriptionsStore = getStore('subscriptions');
     // if we have features or state management inside an action, move it a seprate action calls
     subscriptionsStore.deleteInAvalaibleSchedules({
        subscription,
        schedule,
    });
    
    subscriptionsStore.addInSubscribedSchedules({
        subscription,
        schedule,
    });
    
    this.addList.push(addedObj);
    this.observed.addList = uuidv4();
}

export function removeItemFromAddlist () {
    this.addList.pop();
    // console.log('action.removeItemFromAddlist. -> ', this.addList);
}
