/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
    Grid,
    TextField,
} from '@mui/material';

import { useStyles } from './personalDetails.styles';

import {
    AccountCircle as ProfileIcon,
} from '@mui/icons-material';

import Card from '../components/card';

import MainTitle from '../components/mainTitle';
import useProfile from '../../store/models/profile/useProfile'; 
import useConfirmationDialog from '../components/dialog/useConfirmationDialog';
import DeleteFace from '../components/dialog/contents/deleteFace';
import CommonImages from '../../commons/images';
 
import Button from '../components/button';
// import PrivacyFace from '../components/dialog/contents/privacyFace';

function Preferences () {
    const {
        profile,
        handleProfileInputChange,
    } = useProfile();

    const classes = useStyles();

    const {
        renderDialog,
        openDialog,
    } = useConfirmationDialog({
        dialogContentRenderer: () => {
            console.log('Render Dialog Content renderer...');
            return (<DeleteFace />);
        } 
    });
    
    useEffect(() => {
        openDialog({ open: false });
    }, []);

    useEffect(() => {
        console.log('Profile.useEffect().profile -> ', profile);
    }, [profile]);
   
    return (
        <React.Fragment>
            <Grid
                id="content"
                className={classes.container}
            >
                <MainTitle
                    label="Personal Details"
                    icon={
                        <img
                        src={CommonImages.PERSONAL_DETAILS_LIGHT}
                        alt={'Devices'}
                        /> 
                    }
                    marginRight={'20px'}
                />
  
                <Card
                    title={'Personal Details'}
                    icon={(<ProfileIcon />)}
                    shouldRenderHeader={false}
                >
                    <Grid className={classes.fields}>
                       
                        <Grid
                            className={classes.field}
                        >
                            <TextField
                                id="firstName"
                                label="Name"
                                onChange={handleProfileInputChange}
                                name="firstName"
                                value={profile.firstName}
                                variant="outlined"
                                className={classes.textInput}
                        
                                helperText=""
                            />
                        </Grid>

                        <Grid
                            className={classes.field}
                        >
                            <TextField
                                id="lastName"
                                label="Surname"
                                onChange={handleProfileInputChange}
                                name="lastName"
                                value={profile.lastName}
                                variant="outlined"
                                className={classes.textInput}
                              
                                helperText=""
                            />
                        </Grid>

                        <Grid
                            className={classes.field}
                        >
                            <TextField
                                id="email"
                                label="Email"
                                onChange={handleProfileInputChange}
                                name="lastName"
                                value={profile.email}
                                variant="outlined"
                                className={classes.textInput}
                              
                                helperText=""
                            />
                        </Grid>
        
                        <Grid className={classes.rowSpace}>
                            <Button
                                variant='contained'
                                sizeVariant='medium'
                                onClick={()=>{
                                    console.log('Saved');
                                }}
                                >
                                Save
                            </Button>
                        </Grid>
                      

                    </Grid>
                </Card>
             
                { renderDialog() }
            </Grid>           
        </React.Fragment>
    );
}

// Preferences.propTypes = {

// };

// Preferences.defaultProps = {

// };

export default observer(Preferences);
