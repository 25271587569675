import {
    deleteSchedule,
    removeItemFromDeletelist,
} from './deleteSchedule';
import {
    addSchedule,
    removeItemFromAddlist,
} from './addSchedule';
import {
    cancelScehdule,
    removeItemFromCancelList,
 } from './cancelSchedules';

export const actions = {
    addSchedule,
    removeItemFromAddlist,
    deleteSchedule,
    removeItemFromDeletelist,
    cancelScehdule,
    removeItemFromCancelList,
};

export default actions;
