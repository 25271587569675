import apis from './requests';

import { hasErrors } from './middlewares';
import {
    isSessionOK,
} from '../models/factory';

export async function post (endpoint, body, options, store ) {
    if (!isSessionOK()) {
        return { error: true }
    }

    const res = await apis.post(endpoint, body, options);
    if (hasErrors(res, store)) {
        console.log('apis.post() -> hasErrors() YES');
        return { error: true };
    }
    return res;
}

export async function get (endpoint, body, options, store ) {
    if (!isSessionOK()) {
        return { error: true }
    }
   
    const res = await apis.get(endpoint, options);
    console.log('');
    console.log('');
    console.log('');
    console.log('store.api.get()', endpoint);
    console.log('store.api.get() RECEIVE RESPONSE...');
    if (hasErrors(res, store)) {
        console.log('apis.get() -> hasErrors() YES');
        return { error: true };
    }
    return res;
}

export async function upload (endpoint, formdata, options, store ) {
    if (!isSessionOK()) {
        return { error: true }
    }

    const res = await apis.upload(endpoint, formdata, options);
    if (hasErrors(res, store)) {
        return { error: true };
    }
    return res;
}

export async function put (endpoint, body, options, store ) {
    if (!isSessionOK()) {
        return { error: true }
    }

    const res = await apis.put(endpoint, body, options);
    if (hasErrors(res, store)) {
        return { error: true };
    }
    return res;
}

export async function deleteAPI (endpoint, options, store ) {
    if (!isSessionOK()) {
        return { error: true }
    }

    const res = await apis.deleteAPI(endpoint, options);
    if (hasErrors(res, store)) {
        return { error: true };
    }
    return res;
}

export async function gqlGet (endpoint, query, options, store ) {
    if (!isSessionOK()) {
        return { error: true }
    }

    const res = await apis.gqlGet(endpoint, query, options);
    if (hasErrors(res, store)) {
        return { error: true };
    }
    return res;
}

export async function gqlPost (endpoint, query, options, store ) {
    if (!isSessionOK()) {
        return { error: true }
    }
    
    const res = await apis.gqlPost(endpoint, query, options);
    if (hasErrors(res, store)) {
        return { error: true };
    }
    return res;
}

const withMiddlewares = {
    post,
    get,
    upload,
    put,
    deleteAPI,
    gqlPost,
    gqlGet,
};


export default withMiddlewares;
