import main from './main';
 
export const domainEndpoints = {
    main,
};

export function getDomainEndpoints () {
    return domainEndpoints.main;
}

