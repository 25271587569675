import { v4 as uuidv4 } from 'uuid';
import defaultSubscriptions from './defaultSubscriptions.json';

async function getDefaultSubscriptions (useDefault = true) {

    if (!useDefault) {
        return [];
    }
    const {
        active,
        inactive,
    } = this.selectActiveInactive(defaultSubscriptions.data);
    
   // this.subscriptions = active;
    this.inactiveSubscriptions = inactive;
    this.observed.subscriptions = uuidv4();

    return active;
}

export default getDefaultSubscriptions;