import {
    getAuthSession,
} from './api';


export default function navigateToLogin () {
    // checks if there is a oauth type in the settings to append
    const params = getAuthSession();
    if (params.type) {
        // this.navigate(loginURLWithParams(params));
        const loginURL = loginURLWithParams(params);
        // console.log("will navigate to login with params ", loginURL);

        window.open(loginURL,'_self');
    } else {
        // this.navigate('/login');
        // console.log("will navigate to login WITHOUT PARAMS");
        window.open('/login', '_self');
    }
    this.initLogin();
}

function hasLoginParams (params) {
    return (params.type || params.sId || params.eId);
}

export function loginURLWithParams (params) {
    let url = '/login';

    if (hasLoginParams(params)) url = `${url}?`;
    url = appendWhenAvailable(url, params, 'type');
    url = appendWhenAvailable(url, params, 'sId');
    url = appendWhenAvailable(url, params, 'eId');
    url = appendWhenAvailable(url, params, 'uId');
    url = removeLastChar(url, '&');
    return url;
}

function appendWhenAvailable (url, params, name) {
    if (params[name]) {
        return `${url}${name}=${params[name]}&`;
    }
    return url;
}

function removeLastChar (url, charValue = '&') {
    const s = url.length - 1;
    const l = url.length;
    const lastChar = url.substring(s,l);
    if (lastChar === charValue) {
        return url.substring(0, l - 1);
    }return url;
}