import { v4 as uuidv4 } from 'uuid';

export function addInInactiveSubscriptions ({ subscription }) {
    const event = new Date();

    subscription.isActive = false;
    subscription.failReasons = [
        {
            reason: 'unsubscribe action requested',
            time: event.toISOString(),
        },
    ];

    this.inactiveSubscriptions.push(subscription);
    // console.log('newSubscriptions', this.inactiveSubscriptions);
    this.observed.subscriptions = uuidv4();
}