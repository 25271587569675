import { CarouselPrevArrow, CarouselNextArrow } from './carouselArrows';

const subscriptionSliderSettings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 3,
  // rows: 1,
  variableHeight: false,
  arrows: true,
  nextArrow: <CarouselNextArrow />,
  prevArrow: <CarouselPrevArrow />,
  mobileFirst: false,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

export default subscriptionSliderSettings;
