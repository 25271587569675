/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import RenderIfVisible from 'react-render-if-visible';

import {
    Grid,
    Switch,
    Tooltip,
    useMediaQuery,
} from '@mui/material';

import { 
    Add as AddIcon,
  } from '@mui/icons-material';

import {
    getTextWidth,
    // getContentWidth,
    getAttributeId,
} from './util';

import { Typography } from '../components/wrappers';
import Button from '../components/button';

import { useStyles } from './scheduleItem.styles';
import useScheduleItem from './useScheduleItem';

const MAX_SCHEDULE_LENGTH_IN_PIXEL = 190;

function ScheduleItem (props) {
    const {
        schedule,
        subscription,
        marginTop,
        toggleDisabled,
        showAddButton,
        // openAddScheduleDialog,
        openRemoveScheduleDialog,
        // openDeleteSuccessDialog,
    } = props;

    const classes = useStyles({
        marginTop,
    });

    const {
        loadingList,
        loadingMessage,
        checked,
        handleChange,
        handleClick,
    } = useScheduleItem({
        schedule,
        subscription,
        // openAddScheduleDialog,
        openRemoveScheduleDialog,
        // openDeleteSuccessDialog,
    });

    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    function renderScheduleNames () {
        // the unit of 190 here is Pixel.
        const isLongName = getTextWidth(schedule.scheduleName) > MAX_SCHEDULE_LENGTH_IN_PIXEL;

        return (
            <Tooltip 
                title={(isLongName && !isSmallScreen) ? schedule.scheduleName : null}
                placement="top"
                classes={{
                    tooltip: classes.scheduleTooltipStyle,
                }}
                arrow
                enterTouchDelay={0}
            > 
                <Grid className={classes.scheduleNameContainer}>
                    <Typography className={classes.name}>
                        {schedule.scheduleName}
                    </Typography>
                </Grid>
            </Tooltip> 
        );
    }

    function renderCategoryNames () {
        if (schedule?.categoryNames?.length > 0 && schedule.label !== 'Uncategorized') {
            return (<Tooltip
                title={schedule?.categoriesToTooltipLabel || ''}
                key={schedule.scheduleId}
                placement="top"
                classes={{
                    tooltip: classes.tooltipStyle,
                }}
                arrow
                enterTouchDelay={0}
            >
                <span>
                    {schedule.label}
                </span>
            </Tooltip>);
        }
    }

    function renderControl () {

        if(typeof loadingList !== 'undefined' && loadingList[schedule.scheduleId]) {
            return  (<Grid className={classes.loadingIcon}>
                {loadingMessage}
            </Grid>);
        }

        if (showAddButton) {
            return (<Grid
                className={classes.addButton}
                id={`add-button-${getAttributeId(schedule.scheduleName)}`}
            >
                <Button
                    disabled={false}
                    variant="contained"
                    sizeVariant={'small'}
                    onClick={handleClick}
                >
                     <AddIcon
                        classes={{
                            root:  classes.headerIcon,
                        }}
                    />
                    Add
                </Button>
            </Grid>);
        }

        return (<Grid id={`switch-button-${getAttributeId(schedule.scheduleName)}`}>
            <Switch
                disabled={toggleDisabled}
                checked={checked}
                onChange={handleChange}
                />
        </Grid>);
    }
 
    return (
        <React.Fragment>
            <RenderIfVisible id={`schedule-${getAttributeId(schedule.scheduleName)}`}>
                <Grid className={classes.base} >
                    <Grid>
                        { renderScheduleNames() }
                        <Grid>
                            <Typography className={classes.categories}>
                                { renderCategoryNames() }
                            </Typography>
                        </Grid>
                    </Grid>
                    { renderControl() } 
                </Grid>
            </RenderIfVisible>
        </React.Fragment>
    );
}

ScheduleItem.propTypes = {
    schedule: PropTypes.any,
    subscription: PropTypes.any,
    marginTop: PropTypes.any,
    toggled: PropTypes.bool,
    toggleDisabled: PropTypes.bool,
    showAddButton: PropTypes.bool,
    openRemoveScheduleDialog: PropTypes.func,
    // openAddScheduleDialog: PropTypes.func,
    // openDeleteSuccessDialog: PropTypes.func,
};

ScheduleItem.defaultProps = {
    schedule: {},
    subscription: {},
    marginTop: 0,
    toggled: true,
    toggleDisabled: true,
    showAddButton: false,
    openRemoveScheduleDialog: () => {},
    // openAddScheduleDialog: () => {},
    // openDeleteSuccessDialog: () => {},
};

export default observer(ScheduleItem);