/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
// import { useNavigate } from 'react-router-dom';

import {
    Grid,
} from '@mui/material';

import { useStyles } from './profile.styles';

import Card from '../components/card';
import CardField from '../components/cardField';
import MainTitle from '../components/mainTitle';
import useProfile from '../../store/models/profile/useProfile';
// import DeviceInfo from '../subscriptions/deviceInfo';
import useConfirmationDialog from '../components/dialog/useConfirmationDialog';
import DeleteFace from '../components/dialog/contents/deleteFace';
import CommonImages from '../../commons/images';
// import PrivacyFace from '../components/dialog/contents/privacyFace';

function Profile () { 

    const {
        profile,
        // devices,
    } = useProfile();

    const classes = useStyles();
    // const navigate = useNavigate();

    const {
        renderDialog,
        openDialog,
    } = useConfirmationDialog({
        dialogContentRenderer: () => {
            console.log('Render Dialog COntent renderer...');
            return (<DeleteFace />);
        } 
    });
    
    useEffect(() => {
        openDialog({ open: false });
    }, []);

    useEffect(() => {
        console.log('Profile.useEffect().profile -> ', profile);
    }, [profile]);
    
    /*
    function renderDevices () {
        console.log('renderDevices() ', devices);
        return devices.map((device, index) => {
            let marginBottom = '0px';
            if (index === devices.length - 1) {
                marginBottom = '16px';
            }

            return (<DeviceInfo
                key={`device-info-${index}`}
                showTitle={false}
                showUnsubscribe={true}
                expand={true}
                variant='small'
                marginTop={'16px'}
                marginBottom={marginBottom}
                deviceData={device}
            />);
        });
    }

    function renderDevicesCard () {
        if (devices.length <= 0) {
            return (<React.Fragment></React.Fragment>);
        }

        return ( <Card
            title={'Devices'}
            icon={(
                <img
                    src={CommonImages.DEVICES}
                    alt={'Devices'}
                />
            )}
            marginTop={'16px'}
        >
            <Grid
                className={classes.devices}
            >
            { renderDevices() }
            </Grid>
        </Card>);
    }
    */

    return (
        <React.Fragment>
            <Grid
                id="content"
                className={classes.container}
            >
                <MainTitle
                    label="My Profile"
                    icon={(<img alt='My Profile' src={CommonImages.PROFILE_LIGHT}/>)}
                    marginRight={'20px'}
                />
  
                <Card
                    title={'Personal Details'} 
                    icon={(
                        <img
                            src={CommonImages.PERSONAL_DETAILS}
                            alt={'Personal Details'}
                        />
                    )}
                    showHeaderButton={false}
                    // buttonHeaderTitle={'Edit'}
                    // onButtonHeaderClick={() => {
                    //     navigate('/profile/details')
                    // }}
                >
                    <Grid className={classes.fields}>
                        <CardField
                            label={'First Name'}
                            value={profile.firstName || '-'}
                        />

                        <CardField
                            label={'Last Name'}
                            value={profile.lastName || '-'}
                        />

                        <CardField
                            label={'Email'}
                            value={profile.email || '-'}
                        />

                    </Grid>
                   
                </Card>              

                {/* { renderDevicesCard() } */}

                {/* <Card
                    title={'Data Preferences'}
                    icon={(
                        <img
                            src={CommonImages.DATA_PREFERENCE_DARK}
                            alt={'Data Preferences'}
                        />
                    )}
                    marginTop={'16px'}
                    renderHeaderOnly={true}
                    showHeaderButton={true}
                    headerButtonDisabled={false}
                    onButtonHeaderClick={() => {
                        navigate('/profile/preferences')
                    }}
                >
                </Card> */}

                { renderDialog() }
            </Grid>           
        </React.Fragment>
    );
}

// Profile.propTypes = {
//     profile: PropTypes.any,
// };

// Profile.defaultProps = {
//     profile: {},
// };

export default observer(Profile);