import React from 'react';
 
import PropTypes from 'prop-types';

import {
    Grid,
} from '@mui/material';

import { Typography } from '../../wrappers';

import CommonImages from '../../../../commons/images'
import useStyles from './styles';

function SuccessFace (props)  {
    const classes = useStyles({});
    const {
        message,
        subMessage,
        messageIcon, 

    } = props;
    

    function renderContentIcon() {
        console.log('DeleteFace.renderIcon() ', message);
        if (messageIcon) {
          return messageIcon;
        }
        return (<img src={CommonImages.SUCCESS_CHECK} alt='delete'></img>);
    }

    return (
        <Grid className={classes.defaultContent}>
            { renderContentIcon() }
            <Typography className={classes.message}>
                {message}
            </Typography>
            <Typography className={classes.subMessage}>
                {subMessage}
            </Typography>
    </Grid>
    );
}

 
SuccessFace.propTypes = {
    message: PropTypes.string,
    subMessage: PropTypes.string,
    messageIcon: PropTypes.any,
};
  
SuccessFace.defaultProps = {
    message: 'Succesfully Deleted',
    subMessage: 'Events will be removed from your calendar shortly',
    messageIcon: null,
};
 

export default SuccessFace;