// styles
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    base: {
        display: 'flex',
        width: '100%',
        minHeight: '50px',
        justifyContent: 'center',
    },

    icon: {
        color: '#FFFFFF !important',
        marginRight: '12px',
        '& .MuiSvgIcon-root': {
            color: '#FFFFFF !important',
        },

    },

    label: {
        color: '#FFFFFF',
    },

    labelText: {
        color: '#FFFFFF !important',
    },

    spacer: ({marginRight}) => {
        return {
            marginRight: marginRight,
        };
    },
}));