/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { useErrorsStore } from '.';

function useErrors () {
    const errorsStore = useErrorsStore();
    const [error, setError] = useState({ ...errorsStore.error });

    useEffect(() => {
        console.log('useErrors.useEffect() error: ', errorsStore.error);
        if (errorsStore.error) {
            setError({
                ...errorsStore.error,
            });
        }
    }, [errorsStore.observed.error]);

    return {
        error,
    };
}

export default useErrors;