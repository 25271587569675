import { v4 as uuidv4 } from 'uuid';

import {
    getDotsByCount,
    startProgressUpdater,
    stopProgressUpdater,
} from '../../../../commons/utils/counter';


const messages = [
    'Loading',
    'Please wait',
    'Almost done',
];

export function startSubscriptionProgress () {
    startProgressUpdater({
        key: 'mmeSubscriptionsListProgress',
        store: this,
        messageInterval: 3, // 3 seconds message will be updated every 3 seconds
        messageUpdateCallback: updateSubscriptionProgressMessage, // A callback that will be called to update the message
        dotsUpdateCallback: updateSubscriptionProgressDots, // a callback that will be called to update the dots
    });
    this.loadProgress.subscriptions = true;
    this.observed.loadProgress = uuidv4();
}

export function stopSubscriptionProgress () {
    stopProgressUpdater('mmeSubscriptionsListProgress');
    this.loadProgress.subscriptions = false;
    this.observed.loadProgress = uuidv4();
}

/*
 * store is subscriptionStore
 */

function updateSubscriptionProgressMessage (store) {
    store.loadProgress.messageStep += 1;
    if ( store.loadProgress.messageStep >= 3) {
        store.loadProgress.messageStep = 0;
    }
    store.loadProgress.message = messages[store.loadProgress.messageStep];
    store.loadProgress.progressLabel = store.loadProgress.message + store.loadProgress.dots;
    store.observed.loadProgress = uuidv4();
}

function updateSubscriptionProgressDots (store) {
    store.loadProgress.dotsCount += 1;
    if ( store.loadProgress.dotsCount > 3) {
        store.loadProgress.dotsCount = 0;
    }
    store.loadProgress.dots = getDotsByCount(store.loadProgress.dotsCount);
    store.loadProgress.progressLabel = store.loadProgress.message + store.loadProgress.dots;
    store.observed.loadProgress = uuidv4();
}

 