import { v4 as uuidv4 } from 'uuid';

export function deleteInSubscribedSchedules ({ subscription, schedule }) {
    const newSubscriptions = this.subscriptions.map((item) => {
        if (item.id === subscription.id) {
            item.subscribedSchedules = item.subscribedSchedules.filter((schItem) => {
                if (schItem.scheduleId !== schedule.scheduleId) {
                    return true;
                }
                return false;
            });
        }
        return item;
    });
    this.subscriptions = newSubscriptions;
    this.observed.subscriptions = uuidv4();
}

