
import { makeStyles } from '@mui/styles';
const drawerWidth = 240

const styles = (theme) => ({

    container: {
      display: 'flex',

      height: '100vh',
      width: '100%',
     
      justifyContent: 'center',
      alignItems: 'center',
    },

    logoSpace: {
      display: 'flex',
      width: '100%',
      height: '120px',
      backgroundColor: '#FFFFFF',
      alignItems: 'center',
      justifyContent: 'flex-start',

      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
      },

    },

    logoContent: {
      display: 'flex',
      justifyContent: 'center',
      width: 'calc(100% - 40px)',
      alignItems: 'center',
      marginLeft: '-10px',

      '&:hover': {
        cursor: 'pointer',
      }
    },

    logo: () => {
      return {
        width: '30x',
        height: '30px',
        [theme.breakpoints.down('sm')]: {
          // marginRight: '5px',
          marginTop: '4px',
        },
      }
    },

    logoWord: () => {
      return {
        marginLeft: '8px',
        height: '20px',
        [theme.breakpoints.down('sm')]: {
          marginRight: '16px',
          marginTop: '4px',
        },
      }
    },

    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
      
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },

    drawerOpen: {
        '& .MuiCollapse-wrapperInner .MuiListItemText-root': {
            margin: '0 !important',
        },
        width: drawerWidth,
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#B9B9B9',
        },
        '&::-webkit-resizer': {
          backgroundColor: '#4A4A4A',
        },
        '&::-webkit-scrollbar': {
          width: '3px',
          height: '3px',
        },
        '&::-webkit-scrollbar-button': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
          height: '50px',
          borderRadius: '36px',
          backgroundColor: '#B9B9B9',
        },
        '&::-webkit-scrollbar-track-piece': {
          backgroundColor: 'white',
        },
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },

      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#B9B9B9',
        },
        '&::-webkit-resizer': {
          backgroundColor: '#4A4A4A',
        },
        '&::-webkit-scrollbar': {
          width: '3px',
          height: '3px',
        },
        '&::-webkit-scrollbar-button': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
          height: '20px',
          borderRadius: '36px',
          backgroundColor: '#B9B9B9',
        },
        '&::-webkit-scrollbar-track-piece': {
          backgroundColor: 'white',
        },
        overflowX: 'hidden',
        width: theme.spacing(7) + 40,
      
        [theme.breakpoints.down('sm')]: {
          width: drawerWidth,
        },
      },

      toolbar: {
        ...theme.mixins.toolbar,
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
      sidebarList: {
        marginTop: theme.spacing(1),
        height: '100vh',
        paddingBottom: '80px !important',
        // overflowY: 'scroll',
      },
      mobileBackButton: {
        marginTop: theme.spacing(0.5),
        marginLeft: theme.spacing(3),
        [theme.breakpoints.only('sm')]: {
          marginTop: theme.spacing(0.625),
        },
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
      },
      popover: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
      },
      fab: {
        width: 36,
        height: 34,
      },
      noBoxShadow: {
        boxShadow: 'none !important',
        marginRight: theme.spacing(1),
      },
      buttonLabel: {
        color: '#fff',
      },
      input: {
        '& .MuiInputBase-input': {
          color: '#fff',
        },
        '& .MuiInput-underline:before': {
          borderBottom: '1px solid rgba(255, 255, 255, .45)',
        },
      },
      chat: {
        width: 45,
        height: 45,
      },
      padding: {
        paddingBottom: theme.spacing(2),
      },
      chatTrigger: {
        bottom: 0,
        background: '#fafafa',
      },

      strokeLine: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px'
      }

});

export default styles;
export const useStyles = makeStyles(styles);
