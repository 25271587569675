/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import {
  Grid,
  Typography,
} from '@mui/material';

import { useStyles } from './loading.styles';
import { useEnvStore } from '../../store/models';
import CommonImages from '../../commons/images';
 
function Loading (props) {
  const classes = useStyles({});

  const env = useEnvStore();
  const [loadProgress, setLoadProgress] = useState ({
    init : false ,
    progressLabel: 'Loading...',
  });
  
  useEffect(() => {
    setLoadProgress(env.loadProgress);
  }, [env.observed.loadProgress]);

  function getPreloader () {
    const preloaderURL = `${window.location.origin}${CommonImages.ECAL_PRELOADER}`
    // const preloaderURL = 'https://sync.ecal.com/button/v1/media/images/ecal-loading.f49b4e6.svg';
    return preloaderURL;
  }

  function renderBrandCover () {
    return (
      <div className={classes.brandCoverSpace}>
        <iframe
          title="loading"
          className={classes.brandCoverImage} 
          src={getPreloader()}
        />
        <Grid className={classes.loadingTextSpace}>
          <Typography className={classes.loadingText}>
            { loadProgress.message }
          </Typography>
          <Typography className={classes.loadingDots}>
            { loadProgress.dots }
          </Typography>
        </Grid>
       
      </div>
    ); 
  }

  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <div className={classes.spacer}> </div>
        { renderBrandCover() }
      </Grid>
    </React.Fragment>
  );
}

export default observer(Loading);
