import { endpoints } from '../../../api/domains/endpoints';
import api from '../../../api';

export function getAvailableSchedulesAPI ({ subscriptionId, body, token, traceId, store}) {
    return  api.get(
        `${endpoints().subscriptions}/${subscriptionId}/availableSchedules`,
        body,
        {
            token,
            traceId,
        },
        store,
    );
}
 
export function getSubscriptionsAPI ({ body, token, traceId, store, page }) {
    const endpoint = `${endpoints().subscriptions}?page=${page.number}&limit=${page.limit}`
    return  api.get(
        endpoint,
        body,
        {
            token,
            traceId,
        },
        store,
    );
}

export function getActiveSubscriptionsAPI ({ body, token, traceId, store, page}) {
    const endpoint = `${endpoints().activeSubscriptions}?page=${page.number}&limit=${page.limit}`
    return  api.get(
       endpoint, 
        body,
        {
            token,
            traceId,
        },
        store,
    );
}

export function getInactiveSubscriptionsAPI ({ body, token, traceId, store, page}) {
    const endpoint = `${endpoints().inactiveSubscriptions}?page=${page.number}&limit=${page.limit}`
    return  api.get(
       endpoint, 
        body,
        {
            token,
            traceId,
        },
        store,
    );
}



export function unsubscribeAPI ({ subscriptionId, token, traceId, store}) {
    return  api.deleteAPI(
        `${endpoints().subscriptions}/${subscriptionId}`,
        {
            token,
            traceId,
        },
        store,
    );
}
