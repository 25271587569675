import { observable, makeObservable } from 'mobx';
import { useStores } from '../useStores';
import withActions from '../withActions';

import actions from './actions';

export default class EnvStore {
    version = '1.0';
    observed = {
        progress: false,
        loadMoreSchedulesMessage: null,
        loadMessage: null,
    };

    requestControllers = {};
    settings = {};
    history = null;

    progressOpen = false;
    progressMessage = '';

    loadCounter = 0;
    loadCounterTimeout = null;
    loadMoreSchedulesMessage = '';
    loadMessage = '';

    loadUnsubscribeMessage = '';
    loadSubscriptionsMessage = '';
    
    loadProgress = {
        init: true,
        messageStep: 0,
        message: 'Loading',
        dots: '.',
        dotsCount: 0,
        progressLabel: 'Loading',
    };

    constructor() {
        makeObservable(this, {
            observed: observable,
        });

        withActions(this, actions);
    }

    mountStores (stores) {
        window.envStore = this;
        this.initEnv();
        this.stores = {};
        for (let i in stores) {
            this.stores[i] = true;
            stores[i].env = this;
            this[i] = stores[i];
        }
    }

    unmountStores () {
        for (let i in this.stores) {
            if (this[i]) {
                delete this[i].env;
                delete this[i];
                delete this.stores[i];
            }
        }
    }
}

export function useEnvStore() {
    const { store } = useStores();
    return store.envStore;
}
