import {
    loadingImageList,
    preloadImages,
} from '../../../../commons/utils/imagePreloader';

import delay from '../../../../commons/utils/delay';

async function initApp () {
    this.startInitProgress();
    const location = window.location;
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    document.appInitPhase = 'INITIALIZING';
    this.page = {
        path: location.pathname,
        params: params,
    };
    this.settings = {
        ...params,
    };
    console.log('EnvStore.initApp() ', params);
    await this.getClientInfo(params);
    // only preload after
    preloadImages(loadingImageList());
    await delay(2000);
    if (this.page.path.indexOf('/login') === -1) {
        // initialize as dashboard
        document.appInitPhase = 'Initializing Dashboard';
        await this.initDashboard();
    } else {
        document.appInitPhase = 'Initializing Login';
         // initialize as login
         console.log('EnvStore.initApp() -> will init as login()...');
        this.initLogin();
    }
    this.stopInitProgress();
}
 
export default initApp;
