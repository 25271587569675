import { endpoints } from '../../../api/domains/endpoints';
import api from '../../../api';

export function addScheduleAPI ({ subscriptionId, body, token, traceId, store}) {
    return  api.post(
        `${endpoints().subscriptions}/${subscriptionId}/add`,
        body,
        {
            token,
            traceId,
        },
        store,
    );
}

export function removeScheduleAPI ({ subscriptionId, body, token, traceId, store}) {
    return  api.post(
        `${endpoints().subscriptions}/${subscriptionId}/remove`,
        body,
        {
            token,
            traceId,
        },
        store,
    );
}