import { OAuthSessionStates } from './sessionActions';
/*
import {
    getStore,
} from '../../factory';
*/

function stateRedirect (props) {
    const {
        state,
        authSession,
        params,
    } = props;

    // console.log('envStore.stateRedirect() STATE ', state);
    // const errorsStore = getStore('errors');
    if (state === OAuthSessionStates.NO_TOKEN_AVAILABLE) {
        this.persistOauthInfo({ session: authSession, params });
        this.navigateToLogin();
    } else if (state ===  OAuthSessionStates.FROM_CALENDAR_WITH_NO_TOKEN) {
        this.persistOauthInfo({ session: authSession, params });
        // if it is from calendar ( type is in params just navigate to login directly even when session expired..)
        this.navigateToLogin();
    } else if (state === OAuthSessionStates.TOKEN_EXPIRED) {
        this.persistToken({ params });
         // auto navigate to login when token expired.
        this.navigateToLogin();
        // comment this if we want to dispatch token error here
        // errorsStore.dispatchSessionError(); // uncomment to dispatch token dialog. 
    } else if (state === OAuthSessionStates.OAUTH_SESSION_SUCCESS) {
        this.finalizeSessionInit({
            authSession,
            params,
        });
    }
}

export default stateRedirect;
