import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export function deleteInAvalaibleSchedules ({ subscription, schedule }) {
    let schedules = this.availableSchedules[subscription.id]; 
    if (!schedules || isEmpty(schedules)) {
        schedules = [];
    }
    // filter to remove the target schedule from the list of available schedules
    const newSchedules = schedules.filter((item) => {
        return (item.scheduleId !== schedule.scheduleId);
    })
    this.availableSchedules[subscription.id] = newSchedules;
    this.observed.availableSchedules = uuidv4();
}
