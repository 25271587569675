import stateActions from './stateActions'
import initApp  from './initApp';
import initEnv  from './initEnv';
import getClientInfo from './getClientInfo';
import initLogin from './initLogin';
import initDashboard, { tokenExpired } from './initDashboard';
import logout from './logout';
import loaderActions from './actions-load-counter';
import navigateToLogin from './navigateToLogin';
import sessionActions from  './sessionActions';
import stateRedirect from './stateRedirect';
import finalizeSessionInit, { redirectToHome } from './finalizeSessionInit';
import {
    startInitProgress,
    stopInitProgress,
} from './progressUpdater';

const actions = {
    initEnv,
    initApp,
    getClientInfo,
    initLogin,
    initDashboard,
    logout,
    navigateToLogin,
    stateRedirect,
    finalizeSessionInit,
    redirectToHome,
    startInitProgress,
    stopInitProgress,
    tokenExpired,
    ...sessionActions,
    ...loaderActions,
    ...stateActions,
};

export default actions;
