/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import Dialog from '../components/dialog/Dialog';
import DeleteFace from '../components/dialog/contents/deleteFace';

import useErrors from '../../store/models/errors/useErrors';
import ErrorCodes from '../../store/models/errors/errorCodes';
import { useEnvStore } from '../../store/models';

function   useErrorsDialog () {

    const envStore = useEnvStore();
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [traceId, setTraceId] = useState('');
    const [code, setCode] = useState('');

    const {
        error,
    } = useErrors();

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!isEmpty(error) && error?.message) {
            // console.log('useErrorsDialog.useEffect.error', error)
            setTitle(error.title);
            setMessage(error.message);
            setTraceId(error.traceId);
            setCode(error.code);
            openDialog();
        }
    }, [error]);

    function openDialog () {
        setOpen(true);
    };

    function dialogContentRenderer () {
        return (<DeleteFace 
            message={title}
            subMessage={message}
        />);
    }
    

    function handleConfirm () {
        setOpen(false);
        if (code === ErrorCodes.TOKEN_EXPIRED ||
            code === ErrorCodes.ERR_BAD_REQUEST) {
            envStore.navigateToLogin();
        }
    }

    function renderErrorDialog () {

        if (!open) {
            return (<React.Fragment></React.Fragment>);
        }

        return (<Dialog
                open={open}
                confirmLabel={'OK'}
                showCancelButton={false}
                onConfirm={handleConfirm}
                shouldRenderDefaultContent={typeof dialogContentRenderer === 'function' ? false : true }
                dialogContentRenderer={dialogContentRenderer}
                traceId={traceId}
            />
        );
    }

    return {
        renderErrorDialog,
    };
}

export default useErrorsDialog;
