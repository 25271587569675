import {
    isExpired as tokenExpired, 
    decodeToken,
} from 'react-jwt';

export function expiredToken () {
    return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hcnkucm9zZS4wMDFAZ21haWwuY29tIiwiZXhwIjoxNjM3NTYwNDkxLCJmaXJzdE5hbWUiOiJzZXJ2aWNlIiwiaWQiOiJtYXJ5LnJvc2UuMDAxQGdtYWlsLmNvbSIsImxhc3ROYW1lIjoiYWNjb3VudCIsIm9yZ2FuaXNhdGlvbklkIjo2MjMyLCJvcmlnX2lhdCI6MTYzNzU1Njg5MSwicHVibGlzaGVySWQiOjE0MzIwLCJyb2xlIjoic3VwLWFkbWluIiwidXNlclR5cGUiOjJ9.44Ry9oHIIrREq4sRF6KIE_9RnpJEIuzFg035m2xPkyU';
}

export async function isExpired (token) {
    if (!token || token === undefined) {
        // console.log('token is undefined. It means that call does not need token...');
        return true;
    }

    return await tokenExpired(token);
}

export function getTokenPayload (token) {
    return decodeToken(token);
}
