import {
    getRedirectURL,
    urlEncodedParams,
} from "../../factory";
/* this is google */
function getClientID () {
    // change base on environment?
    const envClientId = {
        test: 'e522f12e-eab2-429e-981e-4603c79ead8f',
        stage: 'b7ab0493-750f-4758-9473-2a2a05762914',
        prod: '7664fbd2-7f40-4bef-a678-17593dc5df0c',
    }

    let id = envClientId.prod;
    if (window.env && envClientId[window.env]) {
        id = envClientId[window.env];
    }

    return id;
}

const OAUTH_URL = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?';
const SCOPE = 'https://graph.microsoft.com/calendars.readwrite';


// https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&redirect_uri=https://test-svc.ecal.com/v4/manage/oauth2callback/outlook&client_id=e522f12e-eab2-429e-981e-4603c79ead8f&scope=https://graph.microsoft.com/calendars.readwrite&state={"browser": "chrome","ip": "139.218.10.59","fingerprint": "2318293kjbdfskjgigo283rofudsb","os": "Linux","device": "desktop"}


/// {"browser": "chrome","ip": "139.218.10.59","fingerprint": "2318293kjbdfskjgigo283rofudsb","os": "Linux","device": "desktop"}
function generateParams (clientInfo) {
    return {
        response_type: 'code',
        redirect_uri: `https://${getRedirectURL()}/v4/manage/oauth2callback/outlook`,
        client_id: getClientID(),
        scope: SCOPE,
        access_type: 'offline',
        approval_prompt: 'auto',
        include_granted_scopes: 'true',
        state: {
            device: clientInfo.deviceInfo,
            sId: clientInfo.sId,
            eId: clientInfo.eId,
            uId: clientInfo.uId
        },
    };
}

function outlookOAuthAPI (clientInfo) {
    const p = generateParams(clientInfo);
    let url = `${OAUTH_URL}${urlEncodedParams(p)}`;
    window.open(url, '_self');
}

export default outlookOAuthAPI;
