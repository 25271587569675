import { v4 as uuidv4 } from 'uuid';

export function cancelScehdule (schedule) {
    // console.log('cancelSchedules.addScheduleToCancelList.schedule -> ', schedule);

    this.cancelList[schedule.scheduleId] = true;
    this.observed.cancelList = uuidv4();
}

export function removeItemFromCancelList (schedule) {
    // console.log('cancelSchedules.removeItemFromCancelList.schedule -> ');
    
    delete this.cancelList[schedule.scheduleId];
    this.observed.cancelList = uuidv4();
}
