import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';
import defaultSubscriptions from './defaultSubscriptions.json';
import {
    getSubscriptionsAPI
} from './api';

async function getSubscriptions () {
    const session = this.env.session;   
    const traceId = this.env.traceId;
    
    this.startSubscriptionProgress();

    if (session.payload) {
        this.profile = session.payload;
        this.observed.profile = uuidv4();
    }

    const res = await getSubscriptionsAPI({
        store: this,
        token: session.token,
        traceId,
        page: this.page,
    });

    this.stopSubscriptionProgress();
    
    if (res.error) {
        return;
    };

    if (res?.data?.data && res.data?.data?.results) {
        
        const {
            active,
            inactive,
            devices,
        } = this.selectActiveInactive(res?.data?.data?.results);
        
        this.subscriptions = [
            ...this.subscriptions,
            ...active,
        ];

        this.inactiveSubscriptions = [
            ...this.inactiveSubscriptions,
            ...inactive,
        ];
      
        if ( isEmpty(this.subscriptions) &&  this.env.settings.mode === 'test') {
            const defaultResults = this.selectActiveInactive(defaultSubscriptions.data);
            this.subscriptions = defaultResults.active;
        };

        const loaded = this?.pageInfo?.totalLoaded || 0;
        const totalLoaded = parseInt(loaded) + res.data?.data?.results.length;

        this.pageInfo = {
            currentPage: res.data?.data?.currentPage,
            totalPages: res.data?.data?.totalPages,
            totalRecords: res.data?.data?.totalRecords,
            totalLoaded: totalLoaded,
        }

        this.observed.subscriptions = uuidv4();
        this.env.profileStore.updateDevices(devices);
    }

    
   
}

export default getSubscriptions;
