const  URLS = {
    OAUTH: '/oauth',
    GET_PROFILE: '/v4/manage/user/profile',
    GET_SUBSCRIPTIONS: '/v4/manage/user/subscriptions',
    GET_ACTIVE_SUBSCRIPTIONS: 'v4/manage/user/subscriptions/active',
    GET_INACTIVE_SUBSCRIPTIONS: 'v4/manage/user/subscriptions/inactive',
    GET_SCHEDULES: '/',

    DELETE_SUBSCRIPTIONS: '/',
}

export default URLS;