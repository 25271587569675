/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Button,
} from '@mui/material';

import { useStyles } from './styles';
import { Typography } from '../wrappers';
import isEmpty from 'lodash/isEmpty';

function MoreBar (props) {
    const {
        pageInfo,
        label,
        isLoadingProgress,
        loadingMessage,
        onClick,
    } = props;

    const classes = useStyles({ });

    function renderButton () {
        if (isEmpty(pageInfo)) {
            return;
        }

        if (pageInfo?.currentPage === pageInfo?.totalPages) {
            return (<React.Fragment></React.Fragment>);
        }
        
        if (isLoadingProgress) {
            return (
                <Typography className={classes.loading}>
                    { loadingMessage }
                </Typography>);
        }
        
        return (<Button 
                variant={'contained'}
                sizeVariant={'medium'}
                className={classes.button}
                onClick={() => onClick()}
            >
                {label}
            </Button>);
    }

    function renderText () {  

        if (isEmpty(pageInfo)) {
            return;
        }

        if (pageInfo.totalRecords > 10) {
            return (<Typography className={classes.moreBarText}>
                Subscriptions found: {pageInfo.totalRecords} ({pageInfo.totalLoaded} loaded)
            </Typography>);
        }
        
        return (<Typography className={classes.moreBarText}>
            Subscriptions found: {pageInfo.totalRecords}
        </Typography>);
    }

    return (<Grid className={classes.root}>
        <Grid  className={classes.moreBarLeft}>
            { renderText() }
        </Grid>

        <Grid className={classes.moreBarRight}>
            { renderButton() }
        </Grid>
    </Grid>);
}

MoreBar.propTypes = {
    visible: PropTypes.bool,
    isLoadingProgress: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.string,
    loadingMessage: PropTypes.string,
};

MoreBar.defaultProps = {
    visible: true,
    isLoadingProgress: false,
    onClick: () => {},
    label: 'Link',
    loadingMessage:  'loading...',
};

export default MoreBar;