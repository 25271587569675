export function getEnv () {
    return window.env;
}

export function urlEncodedParams (p) {
    const state = JSON.stringify(p.state);
    let pstr = '';
    for (let key in p) {
        if (key === 'state') {
            continue;
        }
        if (pstr !== '') {
            pstr = `${pstr}&`;
        }
        const value = p[key];
        pstr = `${pstr}${key}=${value}`;
    }
    
    pstr = `${pstr}&state=${state}`;
    return encodeURI(pstr);
}

export function getRedirectURL () {
    const redirects = {
        local: 'test-svc.ecal.com',
        test: 'test-svc.ecal.com',
        stage: 'stage-svc.ecal.com',
        prod: 'svc.ecal.com',
    };

    const env = getEnv();
    if (env && redirects[env]) {
        return redirects[env];
    }
    
    return redirects.prod;
}

export function getActiveToken () {
    const env = window.envStore;
    return env.authStore.token;
}

export function getActiveAPIKey () {
    const env = window.envStore;
    return env.authStore.apiKey;
}

export function getHeaderOption () {
    const env = window.envStore;
    return {
        token: env.authStore.token,
        traceId: env.traceId,
    };
}

export function getStore (key) {
    const env = window.envStore;
    const map = {
        errors: env.errorsStore,
        auth: env.authStore,
        subscriptions: env.subscriptionsStore,
        schedules: env.schedulesStore,
        profile: env.profileStore,
        language: env.languageStore,
    };
    
    if (map[key]) {
        return map[key];
    }
    return env;
}

export function extendExpiration () {
    // const auth = getStore('auth');
    // auth.extendExpiration();
}

export async function isSessionOK () {
    const env = window.envStore;
    return  await env.isSessionOK();
} 