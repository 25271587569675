import React, { useState } from 'react';
 
import PropTypes from 'prop-types';

import {
    Grid,
    TextField,
} from '@mui/material';

import { Typography } from '../../wrappers';

import CommonImages from '../../../../commons/images'
import useStyles from './styles';

function DeleteConfirmFace (props)  {
    const classes = useStyles({});
    const {
        message,
        subMessage,
        messageIcon, 

    } = props;
    
    const [confirmText, setConfirmText] = useState('');

    function handleInputChange (e) {
        setConfirmText(e.target.value);
    }

    function renderContentIcon() {
        console.log('DeleteFace.renderIcon() ', message);
        if (messageIcon) {
          return messageIcon;
        }
        return (<img src={CommonImages.ALERT_CIRCLE} alt='delete'></img>);
    }

    return (
        <Grid className={classes.defaultContent}>
            { renderContentIcon() }
            
            <Typography className={classes.messageSmall}>
                {message}
            </Typography>

            <Typography className={classes.subMessageSmall}>
                {subMessage}
            </Typography>
 
            <TextField
                id="confirm"
                label=""
                onChange={handleInputChange}
                name="confirmText"
                value={confirmText}
                variant="outlined"
                className={classes.textInput}
                
                helperText=""
            />
    </Grid>
    );
}

 
DeleteConfirmFace.propTypes = {
    message: PropTypes.string,
    subMessage: PropTypes.string,
    messageIcon: PropTypes.any,
};
  
DeleteConfirmFace.defaultProps = {
    message: 'Please confirm you want to delete all the subscriptions?',
    subMessage: `Please type 'DELETE' to confirm. `,
    messageIcon: null,
};
 

export default DeleteConfirmFace;