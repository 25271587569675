import { v4 as uuidv4 } from 'uuid';
import {
    unsubscribeAPI,
} from './api';

// expanding and closing of subscription
export function expandSubscriptions (subscriptionId) {
    this.subscriptionsExpanded[subscriptionId] = true;
    this.observed.subscriptionsExpanded = uuidv4();
}

export function closeSubscriptions (subscriptionId) {
    this.subscriptionsExpanded[subscriptionId] = false;
    this.observed.subscriptionsExpanded = uuidv4();
}

export async function unsubscribe (subscription) {
    const session = this.env.session;
    const traceId = this.env.traceId;
    console.log('unsubscribe.action.subscription -> ', subscription);

    this.unsubscribeProgress[subscription.id] = true;
    this.observed.unsubscribeProgress = uuidv4();

    this.unsubscribeList.push(subscription);
    this.observed.unsubscribeList = uuidv4();
    this.env.startLoadCounter({ target: 'loadingUnsubscribe' });

    // await new Promise(resolve => setTimeout(resolve, 2000));

    const res = await unsubscribeAPI ({
        store: this,
        token: session.token,
        traceId,
        subscriptionId: subscription.id,
    });

    this.env.stopLoadCounter();

    delete this.unsubscribeProgress[subscription.id];
    this.observed.unsubscribeProgress = uuidv4();

    if (res?.error) {
        return;
    };

    // Update subscriptions state to move unsubscribed item
    this.deleteInActiveSubscriptions({ subscription });
    this.addInInactiveSubscriptions({ subscription });

    this.observed.unsubscribe = uuidv4();
}

export function removeItemFromUnsubscribeList () {
    this.unsubscribeList.pop();
    console.log('action.removeItemFromUnsubscribeList. -> ', this.unsubscribeList);
}
