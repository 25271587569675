/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
    Grid,
} from '@mui/material';

import { useStyles } from './preferences.styles';

import {
    AccountCircle as ProfileIcon,
} from '@mui/icons-material';

import Card from '../components/card';

import MainTitle from '../components/mainTitle';
import useProfile from '../../store/models/profile/useProfile';
import useDataPreferenceDialogs from './useDataPreferenceDialogs';
 

import CommonImages from '../../commons/images';
import { Typography } from '../components/wrappers';
import Button from '../components/button';
import Checkbox from '../components/checkbox';
import Link from '../components/sidebar/link';
// import PrivacyFace from '../components/dialog/contents/privacyFace';

const PAGE_MESSAGE = `ECAL places a high priority on user privacy, security and safety. We recognise that sometimes you may want a way to request or remove content about you that has been collected via ECAL's 'Sync to Calendar' service. Please read our Privacy Policy to understand how your data is managed.`;
const INSTRUCTION_1 = `Please choose the following that applies:`;
const OPT_1 = 'Yes, I would like to receive a copy of my personal data held by ECAL.';
const OPT_2 = 'Yes, I would like ECAL to permanently delete my personal data';

function Preferences (props) {
    
    const {
        profile,
    } = useProfile();

    const classes = useStyles();

    const [haveCopy, setHaveCopy] = useState(true);

    const {
        renderPreferenceDialogs,
        openPreferenceDialog,
        openDelete,
    } = useDataPreferenceDialogs();

    useEffect(() => {
     
    }, []);

    useEffect(() => {
        console.log('Profile.useEffect().profile -> ', profile);
    }, [profile]);
    
   
    return (
        <React.Fragment>
            <Grid
                id="content"
                className={classes.container}
            >
                <MainTitle
                    label="Data Preferences"
                    icon={
                        <img
                        src={CommonImages.DATA_PREFERENCE_LIGHT}
                        alt={'Devices'}
                        /> 
                    }
                    marginRight={'20px'}
                />
  
                <Card
                    title={'Personal Details'}
                    icon={(<ProfileIcon />)}
                    shouldRenderHeader={false}
                >
                    <Grid className={classes.fields}>
                        <Typography
                            className={classes.message}
                        >
                            { PAGE_MESSAGE }
                        </Typography>

                        <Typography
                            className={classes.instruction}
                        >
                            { INSTRUCTION_1 }
                        </Typography>

                        <Checkbox
                            label={OPT_1}
                            checked={haveCopy}
                            onChange={() => {
                                setHaveCopy(!haveCopy);
                            }}
                        />

                        <Checkbox
                            label={OPT_2}
                            checked={!haveCopy}
                            onChange={() => {
                                setHaveCopy(!haveCopy);
                            }}
                        />


                        <Grid className={classes.rowSpace}>
                            <Button
                                variant='text'
                                sizeVariant='freeflow'
                                textDecoration={'underline'}
                                >
                                View Privacy Policy
                            </Button>
                        </Grid>

                        <Grid className={classes.rowSpace}>
                            <Button
                                variant='contained'
                                sizeVariant='medium'
                                onClick={() => {
                                    if (haveCopy) {
                                        openPreferenceDialog();
                                    } else {
                                        openDelete();
                                    }
                                    
                                }}
                                >
                                Submit
                            </Button>
                        </Grid>
                      

                    </Grid>
                </Card>

                <Grid className={classes.supportLink}>
                    <Link
                      label={'Support'}
                      icon={
                        <img
                          src={CommonImages.SUPPORT_LIGHT}
                          alt='Support'
                        />
                      }
                      link={''}
                      onClickLink={() => {
                        console.log('Support');
                      }}
                    />
                </Grid>

                { renderPreferenceDialogs() }
            </Grid>           
        </React.Fragment>
    );
}

Preferences.propTypes = {
    text: PropTypes.string,
};

Preferences.defaultProps = {
    text: 'Preferences',
};

export default observer(Preferences);
