// import logo from './logo.svg';
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { observer } from 'mobx-react';
import { Routes, Route } from 'react-router-dom';
 

import Login from '../pages/login';

import useEnvironment from '../store/models/env/useEnvironment';
import Dashboard from '../pages/dashboard';
import Profile from '../pages/profile';
import Subscriptions from '../pages/subscriptions';
import Home from '../pages/home';
import DataPreferences from '../pages/preferences';
import PersonalDetails from '../pages/personalDetails';
import useProfile from '../store/models/profile/useProfile';


function AppRoutes () {
    
    useEnvironment();
    useProfile();
 
    return (
    <React.Fragment>
        <Routes>

            <Route
                path="/"
                element={<Dashboard />}
            >

                <Route
                    path="/"
                    element={<Home />}
                />

                <Route
                    path="/home"
                    element={<Home />}
                />

                <Route
                    path="/profile"
                    element={<Profile />}
                />

                <Route
                    path="/subscriptions"
                    element={<Subscriptions />}
                />
                
                <Route
                    path="/profile/preferences"
                    element={<DataPreferences />}
                />

                <Route
                    path="/profile/details"
                    element={<PersonalDetails />}
                />

            </Route>

            <Route
                path="/login"
                element={<Login />}
            />
  
        </Routes>
    </React.Fragment>
    );
}

export default observer(AppRoutes);
