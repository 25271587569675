
import { makeStyles } from '@mui/styles';

const styles = (theme) => ({

  root: () => {
    return {
        display: 'flex',
        width: '100%',
        height: '56px',
        alignItems: 'center',
        justifyContent: 'space-between',
      };
  },

  moreBarLeft: {
    display: 'flex',
    flexDirection: 'row !important',
    justifyContent: 'flex-start',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },

  moreBarRight: {
    display: 'flex',
    flexDirection: 'row !important',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '30%',
    maxWidth: '90px',
  },

  moreBarText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },

  button: {
    color : '#ffffff !important',
    backgroundColor: 'rgb(83, 109, 255) !important',
    textTransform: 'none !important',
    fontWeight: '325 !important',
    lineHeight: '20px !important',    
    fontSize: '12px !important',
    borderRadius: '65px !important',
    padding: '3px 10px !important',
    textAlign: 'center',
  },

  loading: {
    fontSize: '12px !important',
    width: '88px',
    display: 'flex',
    marginRight: '-10px !important',
    justifyContent: 'flex-start',
  },

});

export default styles;
export const useStyles = makeStyles(styles);
