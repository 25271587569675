import { observable, makeObservable } from 'mobx';
import { useStores } from '../useStores';
import withActions from '../withActions';
import actions from './actions';

export default class ProfileStore {
    version = '1.0';

    devices = [];
    
    observed = {
    };
    
    constructor() {
        makeObservable(this, {
            observed: observable,
        });

        withActions(this, actions);
    }

}

export function useProfileStore () {
    const { store } = useStores();
    return store.profileStore;
}
