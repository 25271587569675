// styles
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    base: {
        display: 'flex',
        width: '100%',
        minHeight: '52px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    leftEnd: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: '8px',
    },

    rightEnd: {
        display: 'flex',
        marginRight: '8px',
    },

    icon: ({ icon }) =>{
        const style = {
            display: 'flex',
            color: '#000000DE !important',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '12px',
            '& .MuiSvgIcon-root': {
                color: '#000000DE !important',
            },
        };

        if (!icon) {
            style.display = 'none';
        }
        return style;
    },

    label: {
        color: '#000000DE',
    },

    labelText: {
        color: '#000000DE !important',
    },

    spacer: ({marginRight}) => {
        return {
            marginRight: marginRight,
        };
    },
}));
