const overrides = {
    spacing: 4,
    palette: {
      type: 'light',
      
      primary: {
        main: '#0c0644',
      },
      secondary: {
        main: '#f50057',
      },
      info: {
        main: '#00a7e0',
      },
      warning: {
        main: '#ee7624',
      },
      success: {
        main: '#75bd43',
      },
      
      lightGrey:{
        main: '#F6F6F6',
      },
      background: {
        default: "transparent",
      },
    },
    typography: {
      fontFamily: [
        '"Gotham Rounded"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      h2: {
       fontSize: '20px',
       lineHeight: '100%',
      },
      button: {
        fontSize: '12px',
        fontWeight: '500',
      },
    },
  
  };
  
  export default overrides;
