import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({

    base: ({ transparentBox }) => {

        const style = {
            display: 'flex',
            width: '152px',
            height: '186px',
            justifyContent: 'center',
            backgroundColor: transparentBox? 'transparent !important' : '#FFFFFF',
            border: '1px solid #EEEEEE',
            borderRadius: '5px', 
            [theme.breakpoints.down('sm')]: {
                width: '132px',
            },
        };

        return style;
    },

    container: {
        display: 'flex',
        flexDirection: 'column !important',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },

    subscriptionLogoContainer: {
        display: 'flex',
        width: '69px', 
        height: '69px',
        alignItems: 'center',
        justifyContent: 'center',
    },

    avatar: ({ transparentBox }) => {

        function getBG () {
            if (transparentBox) {
                return '#FFFFFF';
            }
            return '#F7F7F7';
        }
        return {
            '&.MuiAvatar-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100% !important',
                height: '100% !important',
    
                borderRadius: '33px',
                overflow: 'hidden',
                backgroundColor: `${getBG()} !important`,
    
                '& img': {
                    width: '80% !important',
                    height: '80% !important',
                },
            }
        };
    },
    

    subscriptionNameContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: '10px',
        marginBottom: '16px',
    },

    nameText: {
        fontWeight: '450 !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%', 
        height: '30px', 
        textAlign: 'center',
        valign: 'middle',
        lineHeight: '18px',
        color: '#000000', 

        '& .MuiTypography-root': {
          
        },
    },

    subscriptionButtonContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',

        '& .MuiChip-root' : {
            backgroundColor: 'transparent',
        },

        '& .MuiButtonBase-root:hover': {
            backgroundColor: 'transparent',
        },

        '& .MuiButtonBase-root:active': {
            boxShadow: 'none',
        },
    },

    manageChip: {
        '& .MuiChip-label' : {
            padding: '0 !important',
           
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            width: 74,
            height: 25,
            backgroundColor: '#0C0644',
            color: '#FFFFFF',
            borderRadius: '65px',

            cursor: 'pointer',

            '&:hover': {
                backgroundColor: '#0C0644AA',
            },
        },
    },

    syncButton: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',

        '& .MuiChip-root' : {
            backgroundColor: '#536DFE',

            // border: '1px solid #EEEEEE',
            color: '#FFFFFF',

            height: 25,
            cursor: 'pointer',

            '&:hover': {
                backgroundColor: '#536DFEAA',
            },
        },
    },


    calendarChip: {
        border: '1px solid #EEEEEE',
        backgroundColor: '#536DFE',
        color: '#FFFFFF',
       //width: 70,
        height: 25,

    },

    calendarIcon: {
        width: 20,
        height: 20,
        fontSize: '14px !important',
        fill: '#FFFFFF',
        color: '#FFFFFF !important',

        '& .MuiChip-icon' : {
            fontSize: '12px',
            width: 12,
            height: 12,
            fill: '#FFFFFF',
            color: '#FFFFFF',
            marginLeft: 3,
        }
    }
}));
