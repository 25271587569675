
import { shouldDispatchGenericError } from './genericErrorDispatch';

export function hasErrors (res, store) {
    console.log('api.middlewares.hasErrors()', res);
    
    if (shouldDispatchGenericError(res, store)) {
        console.log('api.middlewares.hasErrors() -> YES...');
        return true;
    }

    return false;
}

const middlewares = {
    hasErrors,
};

export default middlewares;

