import { v4 as uuidv4 } from 'uuid';
import {
    getProfileAPI
} from './api';

async function getProfile (session) {
    console.log('profileStore.getProfile() : ', session);
    // const session = this.env.session;

    if (session.payload) {
        this.profile = session.payload;
        this.observed.profile = uuidv4();
    }
   

    const traceId = this.env.traceId;
    const res = await getProfileAPI({
        store: this,
        token: session.token,
        traceId,
    });

    console.log('ProfieStore.getProfile() -> ', res);
    if (res?.data?.data) {
        this.profile = res?.data?.data;
        console.log('ProfieStore.getProfile() PROFILE AS RESPONSE-> ', this.profile);
        this.observed.profile = uuidv4();
    }
 
}

export default getProfile;