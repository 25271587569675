import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({

    base: ({ marginTop, backgroundColor, minContentHeight, renderHeaderOnly, marginBottom = '0px' }) => {

        const style = {
            display: 'flex',
            flexDirection: 'column !important',
            justifyContent: 'flex-start',
            alignItems: 'center',
            textDecoration: 'none',
            borderRadius: '12px',
            maxWidth: '559px',
            width: '100%',
            minHeight: '150px',
            overflow: 'hidden',
            backgroundColor: backgroundColor || '#FFFFFF',
            marginTop,
            marginBottom,
        };

        if (minContentHeight) {
            const cardHeight = `calc(50px + ${minContentHeight})`;
            style.height = cardHeight;
        }

        if (renderHeaderOnly) {
            style.height = '50px';
            style.minHeight = '50px';
        }
        return style;
    },

    header:  ({ backgroundColor }) => {
        let borderBottomValue;
        if(backgroundColor) {
            borderBottomValue = '0px';
        }

        return {
            display: 'flex',
            width: '100%',
            height: '50px',
            minHeight: '50px',
            borderBottom: borderBottomValue || '1px solid #F1F1F1',
            alignItems: 'center',
            justifyContent: 'space-between'
        };
    },

    leftSection: ({ onClickTitle }) => {
        
        let cursorType = 'default';
        if ( typeof onClickTitle === 'function') {
            cursorType = 'pointer';
        }
        
        return {
            display: 'flex',
            width: '70%',
            alignItems: 'center',

            '&:hover': {
                cursor: cursorType,
              }
        };
    },

    rightSection: {
        display: 'flex',
        width: '30%',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: '24px',
    },

    contentBase: ({ minContentHeight }) => {
        const style = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: '100%',
            overflow: 'auto',
        };

        if (minContentHeight) {
            style.minHeight = minContentHeight;
            style.height = minContentHeight;
        }

        return style;
    },

    content: ({ minContentHeight }) => {
        const style = {
            display: 'flex',
            justifyContent: 'center',
            width: 'calc(100% - 16px)',
            overflowX: 'hidden',
            overflowY: 'auto',
        };

        if (minContentHeight) {
            style.minHeight = minContentHeight;
            style.height = minContentHeight;
        }

        return style;
    },
 
    icon: {
        marginLeft: '23px',
        alignItems: 'center',
        display: 'flex',
    },

    title: {
        marginLeft: '11px !important',
        fontSize: '16px !important',
        fontWeight: '450 !important',
        lineHeight: '20px !important',
    },

    headerTooltipStyle: () => {
        const style = {
            fontSize: '12px !important',
            maxWidth: '500px !important',
            
            [theme.breakpoints.down('sm')]: {
              fontSize: '11px !important',
            },
        }

        return style;
    },

    infoIcon: {
        color: 'red',
    }

}));
