import isEmpty from 'lodash/isEmpty';
import dateUtil from '../../commons/utils/date';

// process category names functions
function updateCategoryNames (item) {
    const newItem = { ...item };

    if (isEmpty(item.categoryNames)) {
        return newItem;
    }

    if (item.categoryNames.length <= 3 && item.categoryNames.length > 0) {
        newItem.label = `${item.categoryNames.join(', ')}`;
    }

    if (item.categoryNames.length > 3) {
        const categoriesToDisplay = [];
        const items1 = item.categoryNames.slice(0, 3);
        categoriesToDisplay.push(...items1);

        const categoriesToTooltip = [];
        const items2 = item.categoryNames.slice(3, item.categoryNames.length);
        categoriesToTooltip.push(...items2);

        newItem.categoriesToDisplayLabel = categoriesToDisplay.join(', ');
        newItem.categoriesToTooltipLabel = categoriesToTooltip.join(', ');
        newItem.label = `${newItem.categoriesToDisplayLabel} (+${categoriesToTooltip.length})`;
    }

    return newItem;
}

export function updateSchedulesData (subscriptionSchedules) {
    const newSchedules = [];
    for(let j = 0; j < subscriptionSchedules?.length; j++) {
        const schedule = updateCategoryNames(subscriptionSchedules[j]);
        newSchedules.push(schedule);
    }
    return newSchedules;
}

// Flatten device table data
export function getFlattenedDeviceInfo (newSubscription) {
    if (isEmpty(newSubscription) && typeof newSubscription === 'undefined') {
        return;
    }

    let data = {};

    if (newSubscription?.applications && newSubscription?.applications[0]) {
        data = {
            ...newSubscription.applications[0],
        };
    }

    data = {
        ...data,
        createdDate: dateUtil.toDateString(newSubscription.createdDate),
        ...newSubscription.location,
        isActive: newSubscription.isActive,
        subscriptionId: newSubscription.id,
        timeFromNow: dateUtil.timeFromNow(newSubscription.lastSynced),
        reasons: newSubscription.failReasons || '',
    };

    return data;
}

export function getTextWidth(text) {
    // re-use canvas object for better performance
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    const metrics = context.measureText(text);
    // console.log('getTextWidth().metrics.width', metrics.width);
    return metrics.width;
};

export function getContentWidth (elementID) {
    // eslint-disable-next-line no-undef
   const e = document.getElementById(elementID);
   if (e === undefined || e === null) {
       return;
   }

   return e.clientWidth;
};

export function updateSubscriptionsData (subscription) {
    // console.log('util.updateSubscriptionsData', subscription);
    const deviceData = getFlattenedDeviceInfo(subscription);
    const newSubscribedSchedules = updateSchedulesData(subscription?.subscribedSchedules);
    const newAvailableSchedules = updateSchedulesData(subscription?.availableSchedules);
    // console.log('util.updateSubscriptionsData.newSubscribedSchedules', newSubscribedSchedules);
    // console.log('util.updateSubscriptionsData.newAvailableSchedules', newAvailableSchedules);
    const newSubscription = {
        ...subscription,
        availableSchedules: newAvailableSchedules,
        subscribedSchedules: newSubscribedSchedules, 
        device: deviceData,
    };
    return newSubscription;
}

export function removeItemFromList (schedule, scheduleList) {
    let array = scheduleList;

    if(isEmpty(schedule)) {
        return array;
    }

    const valueToRemove = schedule.scheduleId;
    array = array.filter(item => item.scheduleId !== valueToRemove);
    // console.log('util.removeItemFromList.array', array);
    return array;
}

export function addItemToList (schedule, scheduleList) {
    let array = scheduleList;

    if(isEmpty(schedule)) {
        return array;
    }

    const valueToAdd = schedule;
    array.push(valueToAdd);
    // console.log('util.addItemToList.array', array);
    return array;
}

export function getAttributeId (label) {
    if(isEmpty(label)) {
        return;
    }

    return label.replace(/\s+/g, '-').toLowerCase();
}