import { v4 as uuid } from 'uuid';
import ErrorCodes from '../errorCodes';

export function dispatchSessionError () {
    console.log('dispatchSessionError()');
    this.error = {
        code: ErrorCodes.TOKEN_EXPIRED,
        title: 'Token has expired.',
        message: 'Authorization token has expired. Please login again.',
    };
    this.observed.error = uuid();
}

export default dispatchSessionError;
