const moment = require('moment');

export const TimeSpan = {
    ONE_HOUR: 1000 * 60 * 60,
    // ONE_HOUR: 10000,
    // ONE_HOUR: 1000 * 60,
    HALF_HOUR: 1000 * 60 * 30,
    TWO_HOURS: 1000 * 60 * 120,
    OND_DAY: 1000 * 60 * 60 * 24,
    ONE_WEEK: 1000 * 60 * 60 * 24 * 7,
    ONE_MONTH: 1000 * 60 * 60 * 24  * 30,
    
}

export function dateString () {
    const date = new Date();
    return toDateString(date);
}

export function toDateString (date) {
    if (!date) {
        return '';
    }
    return moment(date).format('yyyy-MM-DD');
}

export function dateWithTime () {
    return moment().format('YY-MM-DD HH:mm:ss');
}

export function toDateWithTime (date) {
    if (!date) {
        return '';
    }
    return moment().format('YY-MM-DD HH:mm:ss');
}

export function timeFromNow (date) {
    if (!date) {
        return '';
    }
    return moment(date).fromNow(); 
}

const dateUtil =  {
    dateString,
    toDateString,
    dateWithTime,
    toDateWithTime,
    timeFromNow,
};

export default dateUtil;
