import UaParser from 'ua-parser-js';
import axios from "axios";

async function getDeviceInfo () {
    const ua = new UaParser().getResult();

    let res;
    try {
        res = await axios.get('https://api.ipify.org?format=json');
    } catch (e) {
        this.log('error', e);
    };

    const deviceInfo = {
        browser: ua.browser.name,
        fingerprint: window.traceId,
        ip: res.data.ip,
        os: ua.os.name,
        osVersion: ua.os.version,
        type: ua.device.type || 'desktop',
        userAgent: window.navigator.userAgent,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    
    console.log('getClientInfo() : ',  deviceInfo);
    return deviceInfo;
}

export default getDeviceInfo;