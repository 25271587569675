import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    IconButton,
} from '@mui/material';

import {
    ExpandMore as MoreIcon,
    ExpandLess as LessIcon,
} from '@mui/icons-material';

import { getAttributeId } from './util';

import { Typography } from '../components/wrappers';
import { useStyles } from './subscriptionTitle.styles';

function SubscriptionTitle (props) {
    const {
        icon,
        label,
        marginRight,
        expand: _expand,
        onExpand,
    } = props;

    const classes = useStyles({ marginRight, icon });

    const [expand, setExpand] = useState(_expand);

    useEffect(() => {
        setExpand(_expand);
    }, [_expand]);

    function renderIcon () {
        if (icon) {
            return (<div className={classes.icon}>
                {icon}
            </div>);
        }
        return (<React.Fragment></React.Fragment>);
    }

    function renderExapndIcon () {
        if (expand) {
            return (<LessIcon />);
        }
        return <MoreIcon />
    }
    function renderOpenCloseButton () {
        return (<IconButton
            onClick={() => {
                onExpand(!expand);
            }}
        >
            { renderExapndIcon() }
        </IconButton>);
    }

    return (
        <Grid
            className={classes.base}
        >
            <Grid 
                className={classes.leftEnd}
            >
                 { renderIcon() }
                <Grid className={classes.label}>
                    <Typography
                        color='primary'
                        className={classes.labelText}
                    >
                            {label}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                className={classes.rightEnd}
                id={`arrow-button-${getAttributeId(label)}`}
            >
                { renderOpenCloseButton() }
            </Grid>
            
        </Grid>
    );  
}

SubscriptionTitle.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.any,
    marginRight: PropTypes.any,
    expand: PropTypes.bool,
    onExpand: PropTypes.func,
};

SubscriptionTitle.defaultProps = {
    label: 'Profile',
    icon: null,
    marginRight: '24px',
    expand: false,
    onExpand: () => {},
};

export default SubscriptionTitle;
