/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';

import { useEnvStore } from '../env';
import { useSubscriptionsStore } from '../subscriptions';
import { useProfileStore } from '.';

function useProfile (p) {

    const location = useLocation();
    const env = useEnvStore();
    const profileStore = useProfileStore();
    const subscriptionsStore = useSubscriptionsStore();
    const [profile, setProfile] = useState(profileStore.getDefaultProfile(true));
    const [devices, setDevices] = useState(profileStore.devices);

    const [type, setType] = useState('');
   
    useEffect(() => {
        console.log('useProfile.useEffect() initialized', env.observed.initialized);
        if (env.observed.initialized && !env.initializedOnce) {
            env.initializedOnce = true;
            initData();
        }
    }, [env.observed.initialized]);

    useEffect(() => {

        if (profileStore.profile) {
            setProfile(profileStore.profile);
        }
    }, [profileStore.observed.profile]);

    useEffect(() => {
        if (profileStore.devices) {
            setDevices(profileStore.devices);
        }
    }, [profileStore.observed.devices]);

    useEffect(() => {
        if (env.type) {
            setType(env.type);
        } 
    }, [env.observed.type]);

    async function initData () {
        // Make these calls run in parallel
        Promise.allSettled([
            profileStore.getProfile(env.session),
            subscriptionsStore.getSubscriptions(),
            subscriptionsStore.getRecommendations(),
          ]).then((results) => {
            // console.log(results);
          });
          
        // await profileStore.getProfile(env.session);
        // await subscriptionsStore.getSubscriptions();
        // await subscriptionsStore.getRecommendations();
    }

    function googleOauth () {
        profileStore.auth('google');
    }

    function outlookOauth () {
        profileStore.auth('outlook');
    }

    function handleProfileInputChange (obj) {
        // console.log('Data: ', obj);
        setProfile({
            ...profile,
            [obj.target.name]: obj.target.value,
        });
    }

    useEffect(() => {
        if(!location.pathname.includes('subscriptions') && !isEmpty(subscriptionsStore.subscriptionsExpanded)) {
            console.log('useEffect.navigate.location.pathname', location);
            subscriptionsStore.resetSubscriptionsExpanded();
        }
    }, [location]);

    return {
        googleOauth,
        outlookOauth,
        profile,
        devices,
        type,
        handleProfileInputChange,
    };
}

export default useProfile;