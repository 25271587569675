import googleOAuthAPI from "./googleOAuthAPI";
import outlookOAuthAPI from "./outlookOAuthAPI";

async function auth (type = 'google') {
    const env = this.env;

    if (type === 'google') {
        await googleOAuthAPI(env.clientInfo);
        return;
    } else if (type === 'outlook') {
        await outlookOAuthAPI(env.clientInfo);
        return;
    }
    await googleOAuthAPI(env.clientInfo);
}

export default auth;
