/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";

import useConfirmationDialog from '../components/dialog/useConfirmationDialog';
import DeleteFace from '../components/dialog/contents/deleteFace';
import DeleteConfirmFace from '../components/dialog/contents/deleteConfirmFace';
import SuccessFace from '../components/dialog/contents/successFace';
import PersonalDataConfirm from '../components/dialog/contents/personalDataConfirmFace';


const deleteSuccessMessage = {
    message: 'Successfully Deleted',
    subMessage: 'Events will be removed from your calendar shortly.',
    showConfirmButton: false,
    cancelLabel: 'Close',
};

const requestSuccessMessage = {
    message: 'Request Submitted',
    subMessage: 'Please allow between 24 - 36 hours for your personal data to be sent to your registered email address.',
    showConfirmButton: false,
    cancelLabel: 'Close',
};

function useDataPreferenceDialogs () {

    const [success, setSuccess] = useState({
       ...requestSuccessMessage,
    });

    const {
        renderDialog: renderSuccess,
        openDialog: openSuccess,
    } = useConfirmationDialog({
        cancelLabel: success.cancelLabel,
        showConfirmButton: success.showConfirmButton,
        dialogContentRenderer: () => {
            return (<SuccessFace 
                message={success.message}
                subMessage={success.subMessage}
            />);
        },
 
        onClose: () => {
            openSuccess({ open: false });
        },
    });


    const {
        renderDialog: renderDeleteConfirm,
        openDialog: openDeleteConfirm,
    } = useConfirmationDialog({
        dialogContentRenderer: () => {
            return (<DeleteConfirmFace />);
        },
        confirmLabel: 'Confirm',

        onConfirm: () => {
            openDeleteConfirm({ open: false });

            // use delete success
            setSuccess({...deleteSuccessMessage });
            openSuccess({ open: true });
        },

        onClose: () => {
            openDeleteConfirm({ open: false });
        },
    });


    const {
        renderDialog: renderDeleteInitDialog,
        openDialog: openDeleteInitDialog,
    } = useConfirmationDialog({
        dialogContentRenderer: () => {
            return (<DeleteFace />);
        },

        onConfirm: () => {
            openDeleteInitDialog({ open: false});
            openDeleteConfirm({ open: true });
        },

        onClose: () => {
            openDeleteInitDialog({ open: false});
        },
    });

    const {
        renderDialog: renderPersonalDataConfirm,
        openDialog: openDataConfirm,
    } = useConfirmationDialog({
        dialogContentRenderer: () => {
            return (<PersonalDataConfirm />);
        },

        onConfirm: () => {
            openDataConfirm({ open: false});
            setSuccess({...requestSuccessMessage });
            openSuccess({ open: true });
        },

        onClose: () => {
            openDataConfirm({ open: false});
        },
    });
 
    useEffect(() => {
        openDeleteInitDialog({ open: false });
        openDeleteConfirm({ open: false });
        openSuccess({ open: false });
    }, []);

    function renderPreferenceDialogs () {
        return (<React.Fragment>
            { renderDeleteInitDialog() }
            { renderDeleteConfirm() }
            { renderSuccess() }
            { renderPersonalDataConfirm()}
        </React.Fragment>);
    }

    function openPreferenceDialog () {
        openDataConfirm({ open: true });
    }

    function openDelete () {
        openDeleteInitDialog({ open: true });
    }

    return {
        renderPreferenceDialogs,
        openPreferenceDialog,
        openDelete,
    };
}

export default useDataPreferenceDialogs;
