import { observable, makeObservable } from 'mobx';
import { useStores } from '../useStores';

import actions from './actions';
export default class ErrorsStore {
    version = '1.0';
    observed = {
        progress: false,
        error: {},
    };
    
    error = {};

    constructor() {
        makeObservable(this, {
            observed: observable,
        });
    }

    dispatch = actions.dispatch;
    dispatchSessionError = actions.dispatchSessionError;
}

export function useErrorsStore () {
    const { store } = useStores();
    return store.errorsStore;
}
