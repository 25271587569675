/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import useStyles from './link.styles';

function Link (props) {
    const {
        link,
        label,
        icon,
        isSidebarOpened,
        onClickLink,
    } = props;

    const classes = useStyles();

    return (
        <ListItem
            className={classes.link}
            classes={{
            root: classnames(classes.linkRoot,),
            }}
            disableRipple
        >
        <a
          className={classes.externalLink} href={link}
          onClick={(e) => {
            if (typeof onClickLink === 'function') {
                e.stopPropagation();
                e.preventDefault();
                onClickLink();
            }
          }}
        >
            <ListItemIcon
            className={classnames(classes.linkIcon)}
            >
                { icon }
            </ListItemIcon>
            <ListItemText
            classes={{
                primary: classnames(classes.linkText, {
                [classes.linkTextHidden]: !isSidebarOpened,
                }),
            }}
            primary={label}
            />
        </a>
      </ListItem>
    )
}

Link.propTypes = {
    link: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.any,
    isSidebarOpened : PropTypes.bool,
    onClickLink: PropTypes.func,
};

Link.defaultProps = {
    link: '#',
    label: 'Link',
    icon: null,
    isSidebarOpened: true,
    onClickLink: null,
};

export default Link;