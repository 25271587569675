/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
    useSubscriptionsStore,
    useEnvStore,
} from '../index';

/*
 * Note: Hook to relay store.state hooks
 * it used store data as state to update UI states.
 */
function useSchedules (props) {
    const {
        openUnsubscribeSuccessDialog,
    } = props;

    const subscriptionsStore = useSubscriptionsStore();
    const envStore = useEnvStore();

    const [loadMoreSchedulesMessage, setLoadMoreSchedulesMessage] = useState('Loading...');
    const [loadUnsubscribeMessage, setLoadUnsubscribeMessage] = useState('Loading...');
    const [availableSchedules, setAvailableSchedules] = useState({});
    const [availableSchedulesExpanded, setAvailableSchedulesExpanded] = useState({});
    const [subscriptionsExpanded, setSubscriptionsExpanded] = useState({});
    const [availableScheduleProgress, setAvailableScheduleProgress] = useState({});
    const [unsubscribeProgress, setUnsubscribeProgress] = useState({});
    const [availableSchedulesLoaded, setAvailableSchedulesLoaded] = useState({});

    function fetchAvailableSchedules (id) {
        // this will debounce calls made to the api, and will nullify the earlier calls
        clearTimeout(window.fetchAvailableSchedulesTO);
        function doFetch () {
            // Ensure that once fetched, will not be fetched anymore.
            if (!subscriptionsStore.fetchedSchedules[id]) {
                subscriptionsStore.fetchedSchedules[id] = true;
                subscriptionsStore.getAvailableSchedules(id);
            }
        }
        window.fetchAvailableSchedulesTO = setTimeout(doFetch, 500);
    }

    useEffect(() => {
        // is loading card stored which available schedule is being loaded 
        setAvailableScheduleProgress(subscriptionsStore.isLoadingCard);
        /* TODO Delete Commented Lines After Two Months: Feb 2024
        const isLoadingMoreCard = subscriptionsStore.isLoadingCard;
        if (isLoadingMoreCard[subscription.id]) {
            setIsLoadingCard(true);
        } else {
             setIsLoadingCard(false);
        }
        */
    }, [subscriptionsStore.observed.isLoadingCard]);

    useEffect(() => {
        setUnsubscribeProgress(subscriptionsStore.unsubscribeProgress);
        /* TODO Delete Commented Lines After Two Months: Feb 2024
        if (typeof subscriptionsStore.isLoadingUnsubscribe !== 'undefined') {
            const unsubscribedPublisherList = subscriptionsStore.unsubscribeList;
            const unsubscribedPublisher = unsubscribedPublisherList[unsubscribedPublisherList.length - 1];
            if (unsubscribedPublisher?.id === subscription?.id) {
                setIsLoadingUnsubscribe(subscriptionsStore.isLoadingUnsubscribe);
            }
        }
        */
    }, [subscriptionsStore.observed.unsubscribeProgress]);

    useEffect(() => {
        setLoadMoreSchedulesMessage(envStore.loadMoreSchedulesMessage);
    }, [envStore.observed.loadMoreSchedulesMessage]);

    useEffect(() => {
        setAvailableSchedulesLoaded(subscriptionsStore.availableSchedulesLoaded);
        setAvailableSchedules(subscriptionsStore.availableSchedules);
    }, [subscriptionsStore.observed.availableSchedules]);
    
    useEffect(() => {
        setAvailableSchedulesExpanded(subscriptionsStore.availableSchedulesExpanded);
    }, [subscriptionsStore.observed.availableSchedulesExpanded]);

    useEffect(() => {
        setSubscriptionsExpanded(subscriptionsStore.subscriptionsExpanded);
    }, [subscriptionsStore.observed.subscriptionsExpanded]);

    useEffect(() => {
        setLoadUnsubscribeMessage(envStore.loadUnsubscribeMessage);
    }, [envStore.observed.loadUnsubscribeMessage]);

    useEffect(() => {
        if (typeof openUnsubscribeSuccessDialog === 'function' && !isEmpty(subscriptionsStore.unsubscribeList)) {
            const unsubscribedPublisherList = subscriptionsStore.unsubscribeList;
            const unsubscribedPublisher = unsubscribedPublisherList[unsubscribedPublisherList.length - 1];
            openUnsubscribeSuccessDialog(unsubscribedPublisher);
        }
    }, [subscriptionsStore.observed.unsubscribe]);

    function expandCollapseAvailableSchedule (subscriptionId) {
        if (availableSchedulesExpanded[subscriptionId]) {
            subscriptionsStore.closeAvailableSchedules(subscriptionId);
        } else {
            subscriptionsStore.expandAvailableSchedules(subscriptionId);
        }
    }

    function expandCollapseSubscription (subscriptionId) {
        if (subscriptionsExpanded[subscriptionId]) {
            subscriptionsStore.closeSubscriptions(subscriptionId);
        } else {
            subscriptionsStore.expandSubscriptions(subscriptionId);
        }
    }

    return {
        // isLoadingCard,
        loadMoreSchedulesMessage,

        loadUnsubscribeMessage,

        fetchAvailableSchedules,
        availableSchedules,
        availableSchedulesExpanded,
        expandCollapseAvailableSchedule,
        subscriptionsExpanded,
        expandCollapseSubscription,
        availableScheduleProgress,
        unsubscribeProgress,
        availableSchedulesLoaded,
    };
}

export default useSchedules;