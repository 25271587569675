import './App.css';
import { observer, Provider } from 'mobx-react';
import { configure } from 'mobx';
import Pages from './routes';
import store from '../store';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import { LayoutProvider } from '../context/LayoutContext';

configure({
  enforceActions: 'never',
});

function App() {
  return (
       <Provider store={store}>
        <LayoutProvider>
          <CssBaseline />
          <BrowserRouter>
            <Pages />
          </BrowserRouter>
        </LayoutProvider>
      </Provider>
  );
}

export default observer(App);