import { v4 as uuid } from 'uuid';
import { isEmpty } from "lodash";

import { getApiErrorByCode } from './ApiErrors';

export async function dispatch (error) {
    console.log('\n\n\n errorsStore.dispatch() -> ', error);
    if (!isEmpty(error)) {
        this.error = getApiErrorByCode(error);
        this.observed.error = uuid();
    }
}

export default dispatch;
