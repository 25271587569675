
import { makeStyles } from '@mui/styles';

const styles = (theme) => ({

  base: ({ marginTop }) => {

    function getMinHeight () {
      return '48px';
    }

    return {
        display: 'flex',
        flexDirection: 'row !important',
        justifyContent: 'space-between',
        alignItems: 'center',
        textDecoration: 'none',
      
        width: '100%',
        minHeight: getMinHeight(),
        overflow: 'hidden',
    
        backgroundColor: 'transparent',
        marginTop,
 
      [theme.breakpoints.down('sm')]: {
        minWidth: 'calc(100% - 24px)'
      },
    };
  },
  
  addButton: {
    marginRight: '8px',
  },

  headerIcon: {
    fontSize: '14px !important',
  },

  scheduleNameContainer: {
    maxWidth: '375px',
  },

  name: {
      marginLeft: '12px !important',
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',

      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'wrap',
        fontSize: '12px',
      },
  },

  scheduleTooltipStyle: () => {
    const style = {
        fontSize: '12px !important',
        maxWidth: '500px !important',
        marginLeft: '56px !important',
    }

    return style;
  },

  categories: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '12px !important',
    marginLeft: '11px !important',
    color: '#9a9a9a !important',

    [theme.breakpoints.down('sm')]: {
      fontSize: '11px !important',
    },
  },

  tooltipStyle: {
    fontSize: '12px !important',
    
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px !important',
    },
  },

  loadingIcon: () => {
    const style = {
      fontSize: '12px !important',
      width: '83px',
      display: 'flex',
      justifyContent: 'flex-start',
    }

   return style;
  },

});

export default styles;
export const useStyles = makeStyles(styles);
