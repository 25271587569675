import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';

export function addInSubscribedSchedules ({ subscription, schedule }) {
    const newSubscriptions = this.subscriptions.map((item) => {
        if (isEmpty(item.subscribedSchedules)){
            item.subscribedSchedules = [];
        }

        if (item.id === subscription.id) {
            item.subscribedSchedules = [
                ...item.subscribedSchedules,
                schedule,
            ];
        }
        return item;
    });
    this.subscriptions = newSubscriptions;
    this.observed.subscriptions = uuidv4();
}
