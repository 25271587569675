/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEnvStore } from '.';


function useEnvironment () {
    const env = useEnvStore();
    const navigate = useNavigate();
 
    useEffect(() => {
        document.appInitPhase = 'WILL INITIALIZE APP';
        env.navigate = navigate;
        env.initApp();
    }, []);

    return {

    };
}

export default useEnvironment;