/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
} from '@mui/material';

import { useStyles } from './subscriptionItem.styles';

import {
    Button,
    Typography,
} from '../components/wrappers';

function UnsubscribedButton (props) {
    const {
        showUnsubscribe,
        expand,
        marginTop,
        onUnsubcribe,
        subscription,

        isLoadingUnsubscribe,
        loadUnsubscribeMessage,
    } = props;

    const classes = useStyles({ expand, marginTop });

    if (!showUnsubscribe || !expand) {
        return (<React.Fragment> </React.Fragment>);
    }

    return (<Grid
        className={classes.unbsubcribeButtonSpace}
    >
        {!isLoadingUnsubscribe ?
            <Button 
                variant="link"
                className={classes.unsubscribeButton}
                onClick={() => {
                    // console.log('onUnsubcribe button clicked', typeof onUnsubcribe, subscription);
                    if (typeof onUnsubcribe === 'function') {
                        onUnsubcribe(subscription);
                    }
                }}
            >
                Unsubscribe
            </Button>
        :
            <Typography className={classes.loadUnsubscribeMessage}>
                { loadUnsubscribeMessage }
            </Typography>
        }
    </Grid>);
}

UnsubscribedButton.propTypes = {
    marginTop: PropTypes.any,
    showUnsubscribe: PropTypes.bool,
    expand: PropTypes.bool,
    onUnsubscribe: PropTypes.func,
    subscrption: PropTypes.any,
};

UnsubscribedButton.defaultProps = {
    marginTop: 0,
    showUnsubscribe: false,
    expand: false,
    onUnsubcribe: () => {},
    subscrption: {},
};

export default UnsubscribedButton;