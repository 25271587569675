import { makeStyles } from '@mui/styles/';

export default makeStyles(theme => ({
 
  appBar: (props) => {
    return {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: '#ffffff00 !important',
      position: 'absolute',
      top: '0px',
      height: '0px',
      minHeight: '50px !important',
      width: '100vw',
      // zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    };
  },

  barBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% - 40px)',
    height: '50px',
    marginLeft: '-13px',
  },

  logo: () => {
    return {
      width: '30x',
      height: '30px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '12px',
        marginTop: '4px',
      },

      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    }
  },

  logoWord: () => {
    return {
      // marginLeft: '8px',
      height: '20px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '24px',
        marginTop: '4px',
      },

      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    }
  },

  ecalLogo: {
    marginLeft: '-16px',
    height: '20px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  headerMenuButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },

  headerMenuButtonSandwich: {
    marginLeft: 8,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    padding: theme.spacing(0.5),
  },

  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },

  headerIcon: {
    fontSize: 28,
    color: 'rgba(255, 255, 255) !important',
  },

  headerIconCollapse: {
    color: 'white',
  },

}));
