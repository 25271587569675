/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import isEmpty from 'lodash/isEmpty'
import PropTypes from "prop-types";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

import { ColoredDot } from "../dots/coloredDots";
import { useStyles } from "./subscriptionSlider.styles";
import SubscriptionThumbnail from "../subscriptionThumbnail";
import defaultSliderSettings from "./settings";
import useShowDots from "./useShowDots";

function SubscriptionSlider(props) {
  const {
    showManage,
    showSync,
    transparentBox,
    subscriptions,
  } = props;

  const classes = useStyles();
  const [sliderRef, setSliderRef] = useState(null);

  // const [sliderSettings,] = useState({...defaultSliderSettings});

  const { showDots } = useShowDots({ subscriptions });

  useEffect(() => {
    if (sliderRef && typeof sliderRef.slickGoto === 'function') {
      sliderRef.slickGoto(0);
    }
  }, [subscriptions]);
 
  function next() {
    if (sliderRef) {
        sliderRef.slickNext();
    }
  }

  function back() {
      if (sliderRef) {
          sliderRef.slickPrev();
      }
  }

  function renderSlider () {
    if (isEmpty(subscriptions) || subscriptions.length <= 0) {
      return <React.Fragment></React.Fragment>
    }
    return ( <Slider
      {...defaultSliderSettings}
      ref={(ref) => { setSliderRef(ref)}}
    >
      { subscriptions.map((item, index) => {
        return(
          <SubscriptionThumbnail
            key={index}
            showManage={showManage}
            showSync={showSync}
            transparentBox={transparentBox}
            subscription={item}
          />
        );
      })}
    </Slider>);
  }

  function renderDots () {
    if (!showDots) {
      return (<div className={classes.dots}></div>);
    }

    return (
      <div
        id={`slider-dots`}
        className={classes.dots}
      >
         <ColoredDot
              onClick={() => {
                  back();
              }}
              color="orange"
          />

          <ColoredDot
              onClick={() => {
                  back();
              }}
              color="yellow"
          />

          <ColoredDot color="green" />

          <ColoredDot
              onClick={() => {
                  next();
              }}
              color="cyan"
          />

          <ColoredDot
              onClick={() => {
                  next();
              }}
              color="blue"
              withRightMargin={false}
          />
      </div>
    );
  }

  return (
    <div
      className={classes.carouselContainer}
    >
      <div className={classes.sliderSpace}>
        { renderSlider() }
        { renderDots() }
      </div>
    </div>
  );
}

SubscriptionSlider.propTypes = {
  showManage: PropTypes.bool,
  showSync: PropTypes.bool,
  transparentBox: PropTypes.bool,
  subscriptionInfo: PropTypes.object,
  subscriptions:  PropTypes.array,
  showDots: PropTypes.any,
};

SubscriptionSlider.defaultProps = {
  showManage: true,
  showSync: true,
  transparentBox: true,
  subscriptionInfo: null,
  subscriptions: [],
  showDots: true,
};

export default SubscriptionSlider;
