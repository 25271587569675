function initEnv () {
    const subdomain = window.location.hostname.split('.')[0];
    const regularDomain = [
        '192.168.1.2',
        'localhost',
        'localhost:3040',
    ];
    if (!regularDomain.includes(subdomain) && isNaN(Number(subdomain))) {
        this.subdomain = subdomain;
    }
}

export default initEnv;
