import { observable, makeObservable } from 'mobx';
import { useStores } from '../useStores';
import withActions from '../withActions';
import actions from './actions';

export default class SubscriptionsStore {
    version = '1.0';

    subscriptions = [];
    inactiveSubscriptions = [];

    unsubscribeList = [];

    fetchedSchedules = {};
    isLoadingCard = {};
    unsubscribeProgress = {};

    availableSchedules = {};
    availableSchedulesLoaded = {};
    availableSchedulesExpanded = {};

    subscriptionsExpanded = {};

    page = {
        number: 1,
        limit: 10,
    };

    /*
     * Progress Message States
     */ 
    loadProgress = {
        subscriptions: true,
        messageStep: 0,
        message: 'Loading',
        dots: '.',
        dotsCount: 0,
        progressLabel: 'Loading',

    };

    observed = {
    };
    
    constructor() {
        makeObservable(this, {
            observed: observable,
        });

        withActions(this, actions);
    }

}

export function useSubscriptionsStore () {
    const { store } = useStores();
    return store.subscriptionsStore;
}
