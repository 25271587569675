
import { makeStyles } from '@mui/styles';

const styles = (theme) => ({

    container: {
      marginTop: '80px',
      minHeight: '100vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'column !important',
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflow: 'hidden',

      [theme.breakpoints.down('sm')]: {
        width: 'calc(100%  - 16px)',
      },

    },

    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#FFFFFF',
      width: '100%',
      height: '40px',
    },

    sliderLogo: {
      height: '24px',
    },

    fields: {
      display: 'flex',
      marginTop: '2px !important',
      marginBottom: '25px',
      width: 'calc(100% - 46px)',
      justifyContent: 'center',
    },

    recommendations: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column !important',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',

      marginTop: '48px',
      marginBottom: '48px',
    },

    subscriptionsText: {
      display: 'flex',
      flexDirection: 'column !important',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      height: '80%',
    },

    loadingText: {
      // fontSize: '12px !important',
      width: '120px',
      display: 'flex',
      marginRight: '-10px !important',
      justifyContent: 'flex-start',
    },

});

export default styles;
export const useStyles = makeStyles(styles);
