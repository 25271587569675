import { observable, makeObservable } from 'mobx';
import { useStores } from '../useStores';
import withActions from '../withActions';
import actions from './actions';

export default class SchedulesStore {
    version = '1.0';

    isLoadingCard = false; // check if available schedule card is loading
    loadingList = {}; // adding and deleting schedule list
    cancelList = {}; // schedule canceled list
    addList = [];
    deleteList = [];

    observed = {
    };
    
    constructor() {
        makeObservable(this, {
            observed: observable,
        });

        withActions(this, actions);
    }

}

export function useSchedulesStore () {
    const { store } = useStores();
    return store.schedulesStore;
}
