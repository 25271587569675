import { v4 as uuidv4 } from 'uuid';
import { getAuthSession } from './api';
async function initLogin () {
    // this.oauth = this.page.params.oauth;
    // this.observed.oauth = uuidv4();
    const session = getAuthSession();
    this.type = this.page.params.type || session.type;
    this.observed.type = uuidv4();
}

export default initLogin;
