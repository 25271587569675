// this is a good example of how createContext , useContext, useReducer works which can be used in the future to create framework less store framework.
import React from 'react';

const LayoutStateContext = React.createContext();
const LayoutDispatchContext = React.createContext();

function layoutReducer (state, action) {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR':
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    
    case 'OPEN_SIDEBAR':
      return {
        ...state,
        isSidebarOpened: true,
      };
    
    case 'CLOSE_SIDEBAR': 
      return {
        ...state,
        isSidebarOpened: false,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LayoutProvider ({ children }) {
  const [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: true,
  });

  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

function useLayoutState () {
  const context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider');
  }
  return context;
}

function useLayoutDispatch () {
  const dispatch = React.useContext(LayoutDispatchContext);
  if (dispatch === undefined) {
    throw new Error('useLayoutDispatch must be used within a LayoutProvider');
  }
  // console.log('useLayoutDispatch COntext is ', dispatch);
  return dispatch;
}

const toggleSidebar = dispatch => {
  dispatch({
    type: 'TOGGLE_SIDEBAR',
  });
};

const openSidebar = dispatch => {
  dispatch({
    type: 'OPEN_SIDEBAR',
  })
}

const closeSidebar = dispatch => {
  dispatch({
    type: 'CLOSE_SIDEBAR',
  })
}

export {
  LayoutProvider,
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  LayoutStateContext,
  openSidebar,
  closeSidebar,
};
