import { createTheme } from '@mui/material/styles';
import defaultTheme from './default';
import overrides from './overrides';

function getDefaultTheme () {

  return createTheme({
    ...defaultTheme,
    ...overrides,
  
    overrides: {
      MuiTypography: {
        h1: {
         fontSize: '20px',
         lineHeight: '100%',
         fontWeight: '500',
        },
        h2: {
         fontSize: '18px',
         lineHeight: '100%',
         fontWeight: '500',
        },
        h3: {
         fontSize: '14px',
         lineHeight: '100%',
         fontWeight: '300',
        },
        h4: {
         fontSize: '12px',
         lineHeight: '100%',
         fontWeight: '500',
        },
        h5: {
         fontSize: '12px',
         lineHeight: '100%',
         fontWeight: '400',
        },
        body1: {
         fontSize: '14px',
         lineHeight: '21px',
         fontWeight: '300',
        },
        body2: {
         fontSize: '12px',
         lineHeight: '21px',
         fontWeight: '300',
        },
        button: {
          fontSize: '12px',
          fontWeight: '300',
        },
      },
      body: {
        backgroundColor: 'transparent',
      },

      MuiInputLabel: {
        shrink: {
          backgroundColor: '#FFFFFF',
          padding: '2px',
          borderRadius: '2px',
        },
      },

      MuiInputBaseInput: {
        fontSize: '300',
      },

      MUIDataTableFilter: {
        root: {
          padding: '16px',
        },

        checkboxFormControl: {
          marginRight: '12px',
        },

        checkboxFormControlLabel: {
          marginLeft: '0px',
        },

      },
    },
  });
}

const theme = {
    default: getDefaultTheme(),
};

export default theme;
