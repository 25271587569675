import isEmpty from 'lodash/isEmpty'

export const APIErrors = {
    Error404: {
        error: 'Error404',
        code: 'PAGE_NOT_FOUND',
        title: 'Internal server error.',
        message: 'Sorry, something is wrong with the network. But don\'t worry, our staff are doing their best, cheers!',
    },

    Error500: {
        error: 'Error500',
        code: 'INTERNAL_SERVER_ERROR',
        title: 'Internal server error.',
        message: 'Sorry, something is wrong with the server. But don\'t worry, our staff are doing their best cheers!',
    },

    InvalidParameterException: {
        title: 'Invalid Input Paramters',
        message: 'Please check your input and try again.',
    },

    NotAuthorizedException: {
        title: 'Oops!',
        message: 'It seems you mistyped your email or password.',
    },

    InternalServerError: {
        title: 'Oops!',
        message: 'Sorry, something is wrong with the server. But don\'t worry, our staff are doing their best cheers!'
    },

    ERR_NETWORK: {
        title: 'Oops!',
        message: 'Sorry, something is wrong with the network. But don\'t worry, our staff are doing their best, cheers!'
    },

    ERR_BAD_REQUEST: {
        title: 'Oops!',
        message: 'Sorry, something is wrong with the network. But don\'t worry, our staff are doing their best, cheers!'
    },

    INVALID_INPUT_PARAMETER: {
        title: 'Oops!',
        message: 'Invalid input parameter.'
    },

    RESOURCE_NOT_FOUND: {
        title: 'Oops!',
        message: 'Given subscription not found for user.'
    },

    INVALID_ACCESS_TOKEN: {
        title: 'Token has expired.',
        message: 'Authorization token has expired. Please login again.',
    },

    INTERNAL_SERVER_ERROR: {
        title: 'Internal server error.',
        message: 'Sorry, something is wrong with the server. But don\'t worry, our staff are doing their best cheers!',
    },

};

export function getApiErrorByCode (error) {
    if (isEmpty(APIErrors[error.error])) {
        //  use the default error for everything unless it is handled.
        return APIErrors.InternalServerError;
    }

    APIErrors[error.error].message = capitalizeFirstLetter(error.message);
    APIErrors[error.error].code= error.code;
    APIErrors[error.error].traceId= error.traceId;

    if(error.code === 'ERR_BAD_REQUEST' && error.error === 'INVALID_ACCESS_TOKEN') {
        APIErrors[error.error].title = 'Token is invaid';
    }

    return APIErrors[error.error];
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
