const STORE_NAME_SPACE = 'ecal-managemyecal-v2';

export function getLocalSettings () {
    const storeNameSpace = STORE_NAME_SPACE;
    const now = new Date().getTime();
    const infoJson = localStorage.getItem(storeNameSpace);
    const info = {};
    if (!infoJson) {
        info.updated = now;
        info.user = {};
        info.page = {};
        localStorage.setItem(storeNameSpace, JSON.stringify(info));
        return info;
    }
    return JSON.parse(infoJson);
}

export function setLocalSettings (info) {
    const storeNameSpace = STORE_NAME_SPACE;
    info.updated = new Date().getTime();
    localStorage.setItem(storeNameSpace, JSON.stringify(info));
}

export function setSessionSettings (settings) {
    const storeNameSpace = STORE_NAME_SPACE;
    settings.updated = new Date().getTime();
    sessionStorage.setItem(storeNameSpace, JSON.stringify(settings));
}

export function getSessionSettings () {
    const storeNameSpace = STORE_NAME_SPACE;
    const now = new Date().getTime();
    const settingsJson = sessionStorage.getItem(storeNameSpace);
    const settings = {};
    if (!settingsJson) {
        settings.updated = now;
        sessionStorage.setItem(storeNameSpace, JSON.stringify(settings));
        return settings;
    }
    return JSON.parse(settingsJson);
}

export function clearLocalSettings () {
    const storeNameSpace = STORE_NAME_SPACE;
    const settings = {
        updated: new Date().getTime(),
    };
    sessionStorage.setItem(storeNameSpace, JSON.stringify(settings));
}

export function setAuthSession (settings) {
    const storeNameSpace = `${STORE_NAME_SPACE}_AUTH`;
    const date_modified = new Date().getTime();
    const session = getAuthSession();
    let authSettings = {
        ...session,
        ...settings,
        date_modified,
    };
    localStorage.setItem(storeNameSpace, JSON.stringify(authSettings));
}

export function getAuthSession () {
    const storeNameSpace = `${STORE_NAME_SPACE}_AUTH`;
    const settingsJson = localStorage.getItem(storeNameSpace);
    const settings = {};
    if (!settingsJson) {
        const now = new Date().getTime();
        settings.date_modified = now;
        localStorage.setItem(storeNameSpace, JSON.stringify(settings));
        return settings;
    }
    return JSON.parse(settingsJson);
}

export function clearAuthSession () {
    // console.log('clearAuthSession Current Session ? ');
    const storeNameSpace = `${STORE_NAME_SPACE}_AUTH`;
    localStorage.setItem(storeNameSpace, JSON.stringify({}));
}