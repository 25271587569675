import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/styles';

import './index.css';
import App from './app/App';

import {
  ThemeProvider as ThemeChangeProvider,
  ThemeStateContext,
} from './themes/ThemeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

document.appInitPhase = 'STARTING UP APPLICATION';

root.render(
  <ThemeChangeProvider>
    <ThemeStateContext.Consumer>
      {theme => (
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      )}
    </ThemeStateContext.Consumer>
  </ThemeChangeProvider>
);