
import { makeStyles } from '@mui/styles';

import CommonImages from '../../commons/images';

const styles = (theme) => ({

    container: {
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      width: '100%',
     
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
    },

    spacer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
    },

    brandCoverSpace: {
      // backgroundColor: theme.palette.primary.main,
      backgroundColor: '#12012C',
      //width: 'calc(100% - 239px)',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    brandCoverImage: {
      display: 'block',
      border: 'none',
      height: '100px',
      background: 'transparent',
      overflow: 'hidden',
    },

    loadingTextSpace: {
      display: 'flex',
      width: '110px',
      flexDirection: 'row !important',
      marginTop: '16px !important',
      marginLeft: '4px !important',
    },

    loadingText: {
      display: 'flex',
      color: "#FFFFFF",
      fontSize: '16px !important',
      marginRight: '1px !important',
    },

    loadingDots: {
      display: 'flex',
      fontSize: '16px !important',
      color: '#FFFFFF',
      height: '24px',
    },

    formContainer: {
      width: '239px',
      backgroundColor: '#FFFFFF',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('md')]: {
        width: '50%',
        overflow: 'visible',
      },
    },

    form: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '84px',
    },

    brandTitle: {
      fontSize: '24px !important',
      fontWeight: 'bold !important',
      color: '#250036',
    },

    brandLogoContainer: () => {
      
      return {
        display: 'flex',
        marginLeft: '-10px !important',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '40px',
      };
    },
 
    logo: () => {
      return {
        width: '32x',
        height: '32px',
         
      }
    },
  
    logoWord: () => {
      return {
        marginLeft: '8px',
        height: '20px',
         
      }
    },

    brandLogoImage: () => {
      function logo () {
        return CommonImages.MY_ECAL_LOGO;
      }

      return {
        backgroundSize: '150px',
        width: '150px',
        height: '40px',
        backgroundImage: `url(${logo()})`,
      };
    },

    progress: {
      marginLeft: theme.spacing(4),
    },

     

    loginFooter: {
      position: 'fixed',
      bottom: 0,
    }

});

export default styles;
export const useStyles = makeStyles(styles);
