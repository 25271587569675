import {
    clearAuthSession,
    getAuthSession,
} from './api';

import { loginURLWithParams } from './navigateToLogin';


function logout () {
    let loginURL = '/login';
    const params = getAuthSession();
    if (params.type) {
        loginURL = loginURLWithParams(params);
    }
    clearAuthSession();
    window.open(loginURL,'_self');
    // this.navigate('/login');
}

export default logout;