import { v4 as uuidv4 } from 'uuid';
// import isEmpty from 'lodash/isEmpty';
// import defaultAvailableSchedules from './defaultAvailableSchedules.json';
import {
    getAvailableSchedulesAPI,
} from './api';
import { isEmpty } from 'lodash';

// expanding and closing of availabe schedule should be independent to the data structure...
export function expandAvailableSchedules (subscriptionId) {
    this.availableSchedulesExpanded[subscriptionId] = true;
    this.observed.availableSchedulesExpanded = uuidv4();
}

export function closeAvailableSchedules (subscriptionId) {
    this.availableSchedulesExpanded[subscriptionId] = false;
    this.observed.availableSchedulesExpanded = uuidv4();
}

async function getAvailableSchedules (subscriptionId) {
    const session = this.env.session;
    const traceId = this.env.traceId;

    this.isLoadingCard[subscriptionId] = true;
    this.observed.isLoadingCard = uuidv4();

    this.env.startLoadCounter({ target: 'loadMoreSchedules' });

    const res = await getAvailableSchedulesAPI({
        store: this,
        token: session.token,
        traceId,
        subscriptionId: subscriptionId,
    });

    if (res.error) {
        return;
    };

    delete this.isLoadingCard[subscriptionId];
    this.observed.isLoadingCard = uuidv4();
    this.env.stopLoadCounter();
    this.availableSchedulesLoaded[subscriptionId] = true;
    // why check available schedules if empty or not, there are cases when this is true,
    // when someone delete schedule before loading available schedules
    if (res?.data?.data) {
        if (isEmpty(this.availableSchedules[subscriptionId])) {
            this.availableSchedules[subscriptionId] = res?.data?.data;
        } else {
            this.availableSchedules[subscriptionId] = [
                ...this.availableSchedules[subscriptionId],
                ...res?.data?.data,
            ];
        }
        this.observed.availableSchedules = uuidv4();
    } else {
        if (isEmpty(this.availableSchedules[subscriptionId])) {
            this.availableSchedules[subscriptionId] = [];
        } else {
            this.availableSchedules[subscriptionId] = [
                ...this.availableSchedules[subscriptionId],
            ];
        }
        this.observed.availableSchedules = uuidv4();
    }
 
}

export default getAvailableSchedules;

