/* eslint-disable multiline-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Dialog,
    Box,
    Grid,
} from '@mui/material';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

import { Typography } from '../wrappers';
import Button from '../button';
import useStyles from './dialog.styles';
import CommonImages from '../../../commons/images'

function DialogBox (props) {
  const {
    shouldRenderDefaultContent,
    open,

    message,
    subMessage,
    messageIcon,
    traceId,

    showConfirmButton,
    showCancelButton,
    confirmLabel,
    cancelLabel,
    onConfirm,
    onCancel, 

    maxWidth,
    dialogContentRenderer,

    showWaitMessage,
    showRefreshMessage,
  } = props;

  const classes = useStyles({ maxWidth });

  function renderContentIcon() {
    if (messageIcon) {
      return messageIcon;
    }
    return (<img src={CommonImages.SUCCESS_CHECK} alt='success'></img>);
  }

  function renderContent () {
    console.log('');
    console.log('');
    console.log('');
    console.log('Dialog.renderContent -> ', shouldRenderDefaultContent)
    if (shouldRenderDefaultContent) {
      return (<Grid className={classes.defaultContent}>
        <Grid className={classes.contentIconBox}>
          { renderContentIcon() }
        </Grid>
        <Typography className={classes.message}>
          {message}
        </Typography>
        <Typography className={classes.subMessage}>
          {subMessage}
        </Typography>
      </Grid>)
    }

    console.log('Dialog.renderContent() will render Content specific');
    return dialogContentRenderer();
  }

  function renderConfirmButton () {
    if (showConfirmButton) {
      return (<Button
        variant={'contained'}
        sizeVariant={'medium'}
        className={classes.confirmButton}
        onClick={() => {
          if (typeof onConfirm === 'function') {
            onConfirm();
          }
        }}
      >
        {confirmLabel}
      </Button>)  
    }

    return (<React.Fragment></React.Fragment>);
  }

  function renderCancelButton () {
    if (showCancelButton) {
      return (<Button
        variant={'text'}
        sizeVariant={'medium'}
        className={classes.cancelButton}
        onClick={() => {
          if (typeof onConfirm === 'function') {
            onCancel();
          }
        }}
      >
        {cancelLabel}
      </Button>)  
    }
    return (<React.Fragment></React.Fragment>);
  }

  function renderBottomMessage () {

    if (showWaitMessage) {
      return (<Grid className={classes.smallMessage}>
          <AccessTimeOutlinedIcon style={{ fontSize: 20, marginTop: '-3px' }}/>
          <span>
            &nbsp;Please allow a moment for the magic to happen.
          </span>
      </Grid>)
    }

    if (showRefreshMessage) {
      return (<Grid className={classes.smallMessage}>
          <img className={classes.syncImageMessage} src={CommonImages.ECAL_CALENDAR_CIRCLE} alt='success' ></img>
          <span>
            &nbsp;Please refresh your calendar to reveal the change.
          </span>
      </Grid>)
    }

    if (traceId) {
      return (<Typography className={classes.traceId}>
        {`Ref.ID: ${traceId}`}
      </Typography>)
    }
    
    return (<React.Fragment></React.Fragment>);
  }

  return (
    <Dialog
      classes={{
        paper: classes.dialogBase,
      }}
      open={open}
      // onClose={closeModal}
      scroll={'body'}
      aria-labelledby="scroll-dialog-title"
      maxWidth={maxWidth}
    >
      <Box
        className={classNames([classes.dialogContainer])}>
          { renderContent() }
          <Grid className={classes.buttons}>
            { renderConfirmButton() }
            { renderCancelButton() }
          </Grid>
          { renderBottomMessage() }
      </Box>
    </Dialog>
  );
}

DialogBox.propTypes = {
  shouldRenderDefaultContent: PropTypes.bool,
  open: PropTypes.bool,

  message: PropTypes.string,
  subMessage: PropTypes.string,
  messageIcon: PropTypes.any,
  traceId: PropTypes.string,

  showConfirmButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  
  maxWidth: PropTypes.any,
  dialogContentRenderer: PropTypes.func,
  showWaitMessage: PropTypes.bool,
  showRefreshMessage: PropTypes.bool,
};

DialogBox.defaultProps = {
  shouldRenderDefaultContent: true,
  open: false,
  
  message: 'Are you sure?',
  subMessage: 'All publisher subscription will be deleted.',
  traceId: '',
  messageIcon: null,

  showConfirmButton: true,
  showCancelButton: true,
  confirmLabel: 'Yes',
  cancelLabel: 'Cancel',
  onConfirm: () => {},
  onCancel: () => {},
  
  maxWidth: '337px',
  dialogContentRenderer: () => {
    return(<React.Fragment></React.Fragment>);
  },
  showWaitMessage: false,
  showRefreshMessage: false,
};

export default DialogBox;
