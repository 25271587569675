import React from 'react';
 
import PropTypes from 'prop-types';

import {
    Grid,
} from '@mui/material';

import { Typography } from '../../wrappers';

import CommonImages from '../../../../commons/images'
import useStyles from './styles';

function PersonalDataConfirmFace (props)  {
    const classes = useStyles({});
    const {
        message,
        subMessage,
        messageIcon, 

    } = props;
    

    function renderContentIcon() {
        console.log('DeleteFace.renderIcon() ', message);
        if (messageIcon) {
          return messageIcon;
        }
        return (<img src={CommonImages.CONFIRM} alt='confirm'></img>);
    }

    return (
        <Grid className={classes.defaultContent}>
            { renderContentIcon() }
            <Typography className={classes.message}>
                {message}
            </Typography>
            <Typography className={classes.subMessage}>
                {subMessage}
            </Typography>
    </Grid>
    );
}

 
PersonalDataConfirmFace.propTypes = {
    message: PropTypes.string,
    subMessage: PropTypes.string,
    messageIcon: PropTypes.any,
};
  
PersonalDataConfirmFace.defaultProps = {
    message: 'Are you sure?',
    subMessage: 'Please confirm you would like to receive a copy of your personal data.',
    messageIcon: null,
};
 

export default PersonalDataConfirmFace;