import get from './get';
import post from './post';
import upload from './upload';
import put from './put';
import gqlGet from './gqlGet';
import gqlPost from './gqlPost';
import deleteAPI from './delete';

export const apis = {
    get,
    post,
    upload,
    put,
    gqlGet,
    gqlPost,
    deleteAPI,
};

export default apis;
