import {
    urlEncodedParams,
    getRedirectURL,
} from "../../factory";
/* this is google */
function getClientID () {
    // change base on environment?
    const envClientId = {
        test: '323724636018-fkl9qkdh8a9sb1s67424tbbhp77gbkkl',
        stage: '323724636018-fkl9qkdh8a9sb1s67424tbbhp77gbkkl',
        prod: '921894458693-mbfubssabhc5lnbvinkp6s1hurb2peai',
    }

    let id = envClientId.prod;
    if (window.env && envClientId[window.env]) {
        id = envClientId[window.env];
    }
    return `${id}.apps.googleusercontent.com`;
}

const OAUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth?';
const SCOPE = 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar';

function generateParams (clientInfo) {
    return {
        response_type: 'code',
        redirect_uri: `https://${getRedirectURL()}/v4/manage/oauth2callback/google`,
        client_id: getClientID(),
        scope: SCOPE,
        access_type: 'offline',
        approval_prompt: 'auto',
        include_granted_scopes: 'true',
        state: {
            device: clientInfo.deviceInfo,
            sId: clientInfo.sId,
            eId: clientInfo.eId,
            uId: clientInfo.uId
        },
    };
}

function googleOAuthAPI (clientInfo) {
    const p = generateParams(clientInfo);
    let url = `${OAUTH_URL}${urlEncodedParams(p)}`;
    window.open(url, '_self');
}

export default googleOAuthAPI;

/*
 https://accounts.google.com/o/oauth2/v2/auth?response_type=code&redirect_uri=https://local-svc.ecal.com/v4/manage/oauth2callback/google&client_id=.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/calendar&access_type=offline&approval_prompt=auto&include_granted_scopes=true&%7B%22browser%22:%22Chrome%22,%22ip%22:%22112.211.14.139%22,%22fingerprint%22:%2221e93918-457c-4886-a294-e6628a04a0f3%22,%22os%22:%22OS%20X%22,%22device%22:%22Macintosh%22%7D
*/


/*

https://accounts.google.com/o/oauth2/v2/auth?response_type=code&redirect_uri=https://test-svc.ecal.com/v4/manage/oauth2callback/google&client_id=323724636018-fkl9qkdh8a9sb1s67424tbbhp77gbkkl.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar&access_type=online&approval_prompt=auto&include_granted_scopes=true&state={"browser": "chrome","ip": "139.218.10.59","fingerprint": "2318293kjbdfskjgigo283rofudsb","os": "Linux","device": "desktop"}

*/