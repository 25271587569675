import { endpoints } from '../../../api/domains/endpoints';
import api from '../../../api';

export function oauthAPI ({ body, token, traceId, store}) {
    return  api.get(
        endpoints().oauth,
        body,
        {
            token,
            traceId,
        },
        store,
    );
}

export function getProfileAPI ({ body, token, traceId, store}) {
    return  api.get(
        endpoints().profile,
        body,
        {
            token,
            traceId,
        },
        store,
    );
}
