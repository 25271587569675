import { makeStyles } from '@mui/styles';

const styles = (theme) => ({

  carouselContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '559px',
    // height: '250px',
    minHeight: '250px',
    justifyContent: 'center',
    alignItems: 'center',
  },

  sliderSpace: {
    display: 'block',
    width: 'calc(100%)',

    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      width: 'calc(100% - 20px)',
    },

    '& div.slick-slider': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& div.slick-slide': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& div.slick-track': {
      display: 'flex',
      justifyContent: 'space-between',
    },


    '& div.slick-list': {
      width: '100%',
      /*
      paddingTop: '1px',
      maxHeight: '210px',
      */
    },
    '& div.slick-current': {
      //  marginLeft: '8px',
    },

    '& div[data-ecal-id="prevArrow"]': {
      // marginRight: '5px',
    }
  
  }, 

  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    maxHeight: '205px',
  },

  arrowButton: {
      fontSize: '12px',
      width: '22px',
      height: '22px',
      color: '#363b3f',
      backgroundColor: '#CECECE',
  },

  dots: {
    display: 'flex',
    flexDirection: 'row',
    width: '130',
    height: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '12px',
  },

});

export default styles;
export const useStyles = makeStyles(styles);
