import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    base: {
        display: 'flex',
        width: '100%',
        minHeight: '50px',
        justifyContent: 'center',
        marginBottom: '45px',
    },

    profileInfoPicture: {
        width: '121px',
        height: '121px',
        margin: 'auto',
        marginBottom: '14px',
    },

    editIcon: {
        width: '46px',
        height: '46px',
        color: 'rgb(0,0,0,0)',
        borderRadius: '50%',
        backgroundColor: '#E1E1E1 !important', 
        border: '3px solid #FFFFFF !important',
        // boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
    },

    avatarPicture: {
        width: '121px !important', 
        height: '121px !important', 
    },

    avatarNameInitial: {
        width: '121px !important', 
        height: '121px !important', 
        backgroundColor: '#536DFE !important', 
        fontSize: '44px !important', 
    },

}));
